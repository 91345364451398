import Address from 'domain/location/Address';
import { GQL_SHIPPERS } from 'graphql/filter-queries/GQL_COMPANIES';
import {
  getExecutionLocationsGQL,
  getDistinctShippersGQL,
  getExecutionLocationByIdGQL,
} from './GQL_EXECUTION_LOCATIONS';

const mapExecutionLocations = data =>
  data.map(location => ({
    ...location,
    addressName: new Address({
      street: location.street,
      city: location.city,
      country: location.country,
      zipCode: location.zipCode,
      state: location.state,
    }).mapAddressLong(),
    shipperName: location?.company?.name ?? '-',
    actionsData: {
      id: location.id,
    },
  }));

const mapExecutionLocationDetails = data =>
  data.map(location => ({
    ...location,
    type: location.locationType,
    address: {
      address: location?.street,
      city: location?.city,
      latitude: location?.geo?.coordinates[1],
      longitude: location?.geo?.coordinates[0],
      state: location?.state,
      zipCode: location?.zip,
    },
  }));

export const getExecutionLocations = (getGQLClient, tableState) => {
  const variables = {
    sort: tableState.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    where: {
      shippers: tableState.filters.shipperName?.length ? tableState.filters.shipperName : undefined,
    },
  };
  const { where, search, sort, ...rest } = variables;

  const GQL = getExecutionLocationsGQL({
    where,
    search,
    ...rest,
  });
  return getGQLClient().then(client =>
    client.request(GQL, { ...where, ...rest }).then(res => ({
      limit: rest.limit,
      offset: rest.offset,
      total: res.executionLocationAggregate.aggregate.count,
      data: mapExecutionLocations(res.executionLocation),
    })),
  );
};

export const getShippers = getGQLClient =>
  getGQLClient().then(client =>
    client.request(GQL_SHIPPERS).then(response => response.company.map(e => ({ label: e.name, value: e.id }))),
  );

export const getDistinctShippers = getGQLClient =>
  getGQLClient().then(client =>
    client
      .request(getDistinctShippersGQL())
      .then(response => response.executionLocation.map(({ company }) => ({ label: company.name, value: company.id }))),
  );

export const getExecutionLocationById = (getGQLClient, id) => {
  const GQL = getExecutionLocationByIdGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => mapExecutionLocationDetails(res.executionLocation)),
  );
};
