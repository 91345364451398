import axios from 'axios';

import { utility } from '@leaf/components';

export default class AdaptCompanyData {
  constructor(response) {
    Object.assign(this, {
      runDate: utility.date.backendStringToNormalizedUtcDate(response.runDate),
      windowStartDate: utility.date.backendStringToNormalizedUtcDate(response.windowStartDate),
      windowEndDate: utility.date.backendStringToNormalizedUtcDate(response.windowEndDate),
      hasAdapt: response.hasAdapt,
    });
  }

  static read = id => axios.get(`analytics/adapt/companies/${id}`).then(response => new AdaptCompanyData(response));

  static hasAdapt = id => axios.get(`analytics/adapt/companies/${id}/status`);

  static write = (id, data) => axios.post(`analytics/adapt/companies/${id}`, data);
}
