import React from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  DialogActions,
  Autocomplete,
  CircularProgress,
  Box,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';

import { useLoading } from '@leaf/components';

import { ROLES } from 'constants/roles';

const CustomDialogOptions = styled(DialogActions)`
  justify-content: ${({ edit }) => (edit ? 'space-between' : 'flex-end')};
`;

const isValid = (user, isAdmin) => {
  const v = user.first_name && user.last_name && user.email && user.role;
  if (isAdmin) {
    return v;
  }
  return v && user.company_id;
};

const defaultUser = {
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  company_id: null,
  phone_number: '',
};

export default ({ title, onClose, open, companies, selectedUser }) => {
  const [user, setUser] = React.useState(selectedUser || defaultUser);

  const { enqueueSnackbar } = useSnackbar();

  const isAdmin = user.role === 'LE_ADMIN';
  const canSubmit = isValid(user, isAdmin);

  const handleClose = () => {
    onClose();
    setUser(defaultUser);
  };

  const onSuccess = message => () => {
    handleClose();
    enqueueSnackbar(message, { variant: 'success' });
  };
  const onFailure = message => () => {
    enqueueSnackbar(message, { variant: 'failure' });
  };

  const buildMessage = messageType => [
    `User successfully ${messageType}. Please refresh the page.`,
    `User could not be ${messageType}.`,
  ];

  const [doRequest, isLoading] = useLoading(async () => {
    const payload = {
      ...user,
      company_id: isAdmin ? null : user.company_id,
    };
    delete payload.company;

    const messageType = selectedUser ? 'updated' : 'created';
    const [successMessage, failureMessage] = buildMessage(messageType);
    return selectedUser
      ? axios.put(`users/${selectedUser.id}`, payload).then(onSuccess(successMessage)).catch(onFailure(failureMessage))
      : axios.post('users', payload).then(onSuccess(successMessage)).catch(onFailure(failureMessage));
  });

  const onDelete = () => {
    const [successMessage, failureMessage] = buildMessage('deleted');
    return axios.delete(`users/${selectedUser.id}`).then(onSuccess(successMessage)).catch(onFailure(failureMessage));
  };

  const onInvite = () =>
    axios
      .put(`users/${selectedUser.id}/invite`)
      .then(onSuccess('Email successfully sent.'))
      .catch(onFailure('Email could not be sent.'));

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCompanyId = (_, company) =>
    setUser({
      ...user,
      company,
      company_id: company?.value,
    });

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="first_name"
                name="first_name"
                label="First name"
                value={user.first_name}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="last_name"
                name="last_name"
                label="Last name"
                value={user.last_name}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="email"
                name="email"
                label="Email address"
                value={user.email}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="phone_number"
                name="phone_number"
                label="Phone"
                value={user.phone_number}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>

              <Select id="role" name="role" label="Role" onChange={handleChange} value={user.role} required>
                {ROLES.map(role => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              name="company"
              options={companies}
              value={user.company}
              getOptionLabel={company => company.label}
              onChange={handleChangeCompanyId}
              renderInput={params => <TextField {...params} label="Company" />}
              disabled={isAdmin}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <CustomDialogOptions edit={!!selectedUser}>
        {selectedUser && (
          <Button color="error" variant="contained" onClick={onDelete}>
            Delete
          </Button>
        )}
        <Box>
          <Button sx={{ marginRight: '8px' }} color="secondary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>

          {selectedUser && (
            <Button sx={{ marginRight: '8px' }} color="warning" variant="contained" onClick={onInvite}>
              Invite
            </Button>
          )}

          <Button color="primary" variant="contained" onClick={doRequest} disabled={!canSubmit || isLoading} autoFocus>
            {isLoading ? <CircularProgress size={18} /> : 'Save'}
          </Button>
        </Box>
      </CustomDialogOptions>
    </Dialog>
  );
};
