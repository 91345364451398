/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useState } from 'react';
import {
  DialogContent as DialogContentMUI,
  Button,
  Dialog,
  DialogActions,
  InputLabel,
  IconButton,
  Typography,
  DialogTitle,
} from '@mui/material';
import LocationSearchInput from 'components/LocationSearchInput';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { MultiRowInfo } from '@leaf/components';
import { updatePartnerLocation } from './domain/executionPartnerLocationModel';

const DialogContent = styled(DialogContentMUI)`
  min-height: 550px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .MuiIconButton-root {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;

export default memo(({ location, setLocation, onUpdateCb, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedLocation, setSelectedLocation] = useState(null);

  const onClose = () => setLocation(null);

  const onSaveHandler = () => {
    if (selectedLocation) {
      updatePartnerLocation({
        partnerLocationId: location.partnerLocationId,
        mappedLocationId: selectedLocation.id,
      })
        .then(() => {
          enqueueSnackbar('Location updated', { variant: 'success' });
          onClose();
          onUpdateCb?.();
        })
        .catch(enqueueSnackbar);
    }
  };

  const { executionLocation } = location;

  return (
    <Dialog open={!!location} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Header>
          <Typography variant="caption">Execution Partner Location</Typography>
          <IconButton onClick={onClose} size="large">
            <Close />
          </IconButton>
        </Header>
        <MultiRowInfo primaryContent={[executionLocation[0]]} secondaryContent={[executionLocation[1]]} />
      </DialogTitle>
      <DialogContent dividers>
        <InputLabel>Not all results are shown. Type in search input to get the result you need.</InputLabel>
        <br />
        <LocationSearchInput
          {...rest}
          shipperId={location?.shipperId}
          onChangeCb={value => {
            setSelectedLocation(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={!selectedLocation} onClick={onSaveHandler}>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
});
