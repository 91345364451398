import React from 'react';
import { FieldArray, getIn, FormikProvider } from 'formik';
import { TextField, IconButton } from '@mui/material';
import { AddCircleOutline, HighlightOff } from '@mui/icons-material';
import styled from '@emotion/styled';

const Flex = styled.div`
  display: flex;
`;

const CenteredDiv = styled.div`
  width: 100%;
  text-align: center;
`;

export default ({ formik, name, label }) => (
  <FormikProvider value={formik}>
    <FieldArray name={name}>
      {/* FieldArray helpers: The following methods: push, remove are made available only via render props. */}
      {({ push, remove }) => (
        <div>
          {formik?.values[name] &&
            formik?.values[name]?.map((field, index) => {
              const fieldName = `${name}.${index}`;
              const touchedFieldName = getIn(formik.touched, fieldName);
              const errorFieldName = getIn(formik.errors, fieldName);
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={index}>
                  <TextField
                    label={label}
                    name={fieldName}
                    value={field}
                    helperText={touchedFieldName && errorFieldName ? errorFieldName : ''}
                    error={Boolean(touchedFieldName && errorFieldName)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                  <IconButton onClick={() => remove(index)} size="large">
                    <HighlightOff />
                  </IconButton>
                </Flex>
              );
            })}
          <CenteredDiv>
            <IconButton onClick={() => push('')} size="large">
              <AddCircleOutline />
            </IconButton>
          </CenteredDiv>
        </div>
      )}
    </FieldArray>
  </FormikProvider>
);
