import axios from 'axios';
import Address from 'domain/location/Address';
import { GQL_SHIPPERS } from 'graphql/filter-queries/GQL_COMPANIES';
import { getLocationsGql } from 'graphql/filter-queries/GQL_LOCATIONS';
import { getExecutionPartnerLocationsGQL } from './GQL_EXECUTION_PARTNER_LOCATIONS';

const EXECUTION_LANES_URL = process.env.REACT_APP_LANES_EXECUTION_API;

const mapLocations = locations =>
  locations.map(l => {
    const leafLocation = Address.mapAddress(l.location?.city, l.location?.state, l.location?.zip);
    const executionLocation = Address.mapAddress(l.city, l.state, l.zip);
    const executionLocationDetails = [
      executionLocation,
      l.businessName ? `${l.businessName} - ${l.address}` : l.address,
    ];
    return {
      executionPartnerName: l.executionPartner.name,
      shipper: l.shipper.name,
      executionLocation: executionLocationDetails,
      mappedLocationId: l.mappedLocationId ?? '-',
      location: {
        primaryLeafLocationData: leafLocation,
        secondaryLeafLocationData: l.location?.businessName
          ? `${l.location?.businessName} - ${l.location?.street}`
          : l.location?.street,
        shipperId: l.shipper.id,
        executionLocation: executionLocationDetails,
        partnerLocationId: l.id,
        mappedLocationId: l.mappedLocationId,
      },
    };
  });

const executionPartnerLocationMapper = locations =>
  locations.map(l => ({
    street: l.street,
    addressShort: Address.mapAddress(l.city, l.state, l.zip),
    businessName: l.businessName ?? '-',
    id: l.id,
  }));

export const getLocations = (getGQLClient, variables) =>
  getGQLClient().then(client => {
    const { query, limit, shipperId } = variables;
    return client
      .request(getLocationsGql({ query, shipperId }), {
        limit,
      })
      .then(res => ({
        data: executionPartnerLocationMapper(res.executionLocation),
        total: res.executionLocationAggregate.aggregate.count,
      }));
  });

export const getShippers = getGQLClient =>
  getGQLClient().then(client =>
    client.request(GQL_SHIPPERS).then(res => res.company.map(e => ({ label: e.name, value: e.id }))),
  );

export const getExecutionPartnerLocations = (getGQLClient, tableState, forDownload = false) => {
  const variables = {
    sort: tableState.sort,
    ...(!forDownload && { limit: tableState.rowsPerPage }),
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      shippers: tableState.filters.shipper?.length ? tableState.filters.shipper : undefined,
      is_mapped_location: tableState.filters.isMappedLocation?.length
        ? tableState.filters.isMappedLocation[0]
        : undefined,
    },
  };
  const { where, search, sort, ...rest } = variables;

  const GQL = getExecutionPartnerLocationsGQL({
    where,
    search,
    ...rest,
  });
  return getGQLClient().then(client =>
    client.request(GQL, { ...where, ...rest }).then(res => ({
      limit: rest.limit,
      offset: rest.offset,
      total: res.executionPartnerLocationAggregate.aggregate.count,
      data: mapLocations(res.executionPartnerLocation),
    })),
  );
};

export const updatePartnerLocation = ({ partnerLocationId, mappedLocationId }) =>
  axios.put(
    `execution-partner-locations/${partnerLocationId}`,
    { locationId: mappedLocationId },
    { baseURL: EXECUTION_LANES_URL },
  );
