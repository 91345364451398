import { gql } from 'graphql-request';

const WHERE = {
  COMPANY_TYPE_VAR: gql`$company_type: [String!]`,
  COMPANY_TYPE_CLAUSE: gql`company_type: { _in: $company_type }`,
  ACCOUNT_TEAM_MEMBERS_VAR: gql`$account_team_members: [bigint!]`,
  ACCOUNT_TEAM_MEMBERS_CLAUSE: gql`account_team_members: { user_id: { _in: $account_team_members } }`,
  CURRENT_USER_ACCOUNT_MEMBER_VAR: gql`$current_user_account_member: [bigint!]`,
  CURRENT_USER_ACCOUNT_MEMBER_CLAUSE: gql`account_team_members: { user_id: { _in: $current_user_account_member } }`,
};

const isUUID = uuid => uuid?.length === 36 && [...uuid.matchAll(/–|-/g)].length === 4;

const whereClause = ({ where, search }) => {
  let whereGQL = gql``;
  const whereVarsKeys = Object.keys(where).filter(k => Array.isArray(where[k]) && where[k].length);

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: {
          ${whereVarsKeys.map(key =>
            where[key]
              ? gql`
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                `
              : '',
          )}

          ${
            search !== undefined && search !== ''
              ? gql`
            _or: [
              ${isUUID(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
              {name: { _ilike: "%${search}%" }}
              {company_type: { _ilike: "%${search}%" }}
              {account_team_members: {user: {first_name: { _ilike: "%${search}%" }}}}
              {account_team_members: {user: {last_name: { _ilike: "%${search}%" }}}}
            ]`
              : ''
          }
        }
      }
    `;
  }
  return whereGQL;
};

const whereVar = ({ where }) => gql`
  ${where.company_type ? WHERE.COMPANY_TYPE_VAR : ``}
  ${where.account_team_members ? WHERE.ACCOUNT_TEAM_MEMBERS_VAR : ``}
  ${where.current_user_account_member ? WHERE.CURRENT_USER_ACCOUNT_MEMBER_VAR : ``}
`;

export const getCompaniesGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getCompanies($limit: Int, $orderBy: [company_order_by], $offset: Int, ${whereVar(variables)}) {
      company(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        id
        name
        companyType: company_type
        created: created_at
        accountTeamMembers: account_team_members {
          user {
            firstName: first_name
            lastName: last_name
            id
          }
        }
      }
      company_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};
