import React from 'react';
import { MenuItem, Select } from '@mui/material';

export default React.memo(({ options, formik, name, label, ...rest }) => (
  <Select
    id={name}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    name={name}
    value={formik.values[name]}
    fullWidth
    {...rest}
  >
    {options.map(option => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
));
