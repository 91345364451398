import AdaptLane from 'domain/adapt/AdaptLane';
import AdaptBoardNetworkMoves from 'domain/adapt/AdaptBoardNetworkMoves';
import AdaptBoard from 'domain/adapt/AdaptBoard';
import AdaptNetworkMove from 'domain/adapt/AdaptNetworkMove';
import Shipment from 'domain/shipment/Shipment';

export default {
  tables: {
    // Client-side state
    // ...
    // Server-side state
    adaptNetworkMoves: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {
        'moveType': 'true',
        'id': 'false',
        'view.lanes': 'true',
        'l0Miles': 'false',
        'l0AnnualLoads': 'false',
        'l0WeeklyBase': 'false',
        'l0MeanRpm': 'false',
        'l0OutlierRpm': 'false',
        'l0Low': 'false',
        'deadheadL0L1': 'false',
        'l1Miles': 'false',
        'l1AnnualLoads': 'false',
        'l1WeeklyBase': 'false',
        'l1MeanRpm': 'false',
        'l1OutlierRpm': 'false',
        'l1Low': 'false',
        'deadheadL1L2': 'false',
        'l2Miles': 'false',
        'l2AnnualLoads': 'false',
        'l2WeeklyBase': 'false',
        'l2MeanRpm': 'false',
        'l2OutlierRpm': 'false',
        'l2Low': 'false',
        'deadheadFinal': 'false',
        'view.benefit': 'excluded',
        'equipmentClass': 'true',
        'networkType': 'false',
        'startDate': 'false',
        'runningDate': 'false',
        'totalMiles': 'true',
        'days': 'false',
        'supplyTargetDayRateLsp': 'false',
        'startsupplyTargetLinehaulLsp': 'false',
        'leafDailyLinehaulLsp': 'false',
        'shipperTriggerLinehaulLsp': 'false',
        'margin': 'true',
        'minTrips': 'true',
        'feasibility': 'false',
        'notes': 'false',
        'isLow': 'false',
        'balanceType': 'true',
        'complexity': 'true',
        'status': 'false',
        'view.lowLegsCount': 'excluded',
      },
      sort: { name: 'benefit', field: 'benefit', order: 'desc' },
      filters: { totalMiles: [0, 2600], low_leg: ['0'] },
      onDownloadHandler: AdaptNetworkMove.download,
    },
    adaptLanes: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      sort: { name: 'origin', order: 'asc' },
      filters: { hasExplorer: [true] },
      display: {
        'id': 'false',
        'shipperName': 'excluded',
        'shipperId': 'false',
        'shipperGuid': 'false',
        'view.leafMiles': 'true',
        'view.companyMiles': 'false',
        'view.equipmentClasses': 'true',
        'view.totalSpend': 'true',
        'view.totalShipments': 'true',
        'batchDate': 'false',
        'batchDateLatest': 'false',
        'view.period': 'true',
        'view.leafTransitTime': 'false',
        'hasExplorer': 'excluded',
        'origin': 'excluded',
        'destination': 'excluded',
        'view.shipperName': 'false',
      },
      onDownloadHandler: AdaptLane.download,
    },
    partnerLocationsExecutionOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: { isMappedLocation: [true] },
    },
    executionLocationsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
    },
    shipmentsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      quickFilters: {},
      sort: { name: 'pickupAppointment', field: 'origin_appointment_time', order: 'desc' },
      onDownloadHandler: Shipment.download,
    },
    contractShipmentsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
    },
    contractTripsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
    },
    shipperContractsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      quickFilters: {},
    },
    shipperContractMatchesOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      quickFilters: {},
    },
    contractMatchingOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      quickFilters: {},
    },
    companyDetailsLocationsOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      persistentFilterChips: ['shipperId'],
    },
    adaptBoard: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {
        annualLoads: 'true',
        annualSpend: 'true',
        destination: 'false',
        equipmentClass: 'true',
        laneId: 'false',
        laneType: 'false',
        origin: 'false',
        shipperGuid: 'false',
        shipperId: 'false',
        shipperName: 'false',
        status: 'false',
      },
      filters: {},
      onDownloadHandler: AdaptBoard.download,
    },
    companiesOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
    },
    fuelPricesOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      sort: { name: 'validFrom', field: 'valid_from', order: 'desc' },
    },
    usersOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {},
      filters: {},
      sort: { name: 'first_name', order: 'asc' },
    },
    adaptBoardNetworkMoves: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      display: {
        'moveType': 'true',
        'networkMoveId': 'false',
        'shipperName': 'false',
        'view.lanes': 'true',
        'notes': 'false',
        'status': 'true',
        'annualLoads': 'false',
        'equipmentClass': 'true',
        'loadsImpacted': 'true',
        'costReduction': 'true',
        'CO2Reduction': 'true',
        'networkType': 'false',
        'phase': 'false',
        'startDate': 'false',
      },
      filters: {},
      onDownloadHandler: AdaptBoardNetworkMoves.download,
    },
    opsPlansOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      filters: {},
    },
    opsPlanContracts: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      filters: {},
    },
    fleetDiscoveryOverview: {
      page: 0,
      rowsPerPage: 50,
      search: '',
      filters: {},
    },
  },
  lanePhases: {
    lanePhasesByShipper: [],
  },
  companies: [],
};
