import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Page, useTableCustomFilters, ServerSideTable, PageContentLayout, PageContainer, Beta } from '@leaf/components';

import { StateContext } from 'state/StateProvider';
import { SET_PARTNER_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE } from 'state/stateReducer';
import initialState from 'state/initialState';

import useGQL from 'hooks/useGQL';
import UpdatePartnerLocationModal from './UpdatePartnerLocationModal';
import { getExecutionPartnerLocations, getShippers } from './domain/executionPartnerLocationModel';
import { getColumns, options } from './table-config';

export default () => {
  const {
    state: {
      tables: { partnerLocationsExecutionOverview: tableState },
    },
    dispatch,
  } = React.useContext(StateContext);

  const [partnerLocationToUpdate, setPartnerLocationToUpdate] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const getGQLClient = useGQL();

  useEffect(
    () => () => {
      dispatch({
        type: SET_PARTNER_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE,
        payload: initialState.tables.partnerLocationsExecutionOverview,
      });
    },
    [],
  );

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'shipper',
      filterOptionsGetter: () => getShippers(getGQLClient),
    },
    {
      inputType: 'checkbox',
      columnName: 'isMappedLocation',
    },
  ]);

  const handleLocationUpdateDialogOpen = updateDialogData => setPartnerLocationToUpdate(updateDialogData);

  const handleChange = setTableState =>
    dispatch({ type: SET_PARTNER_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <Page title="Partner Locations Execution" ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={options}
            columns={getColumns({
              handleLocationUpdateDialogOpen,
              filterOptions,
            })}
            fn={() => getExecutionPartnerLocations(getGQLClient, tableState)}
            tableState={tableState}
            setTableState={handleChange}
            errorHandler={enqueueSnackbar}
          />
        </PageContentLayout>
      </PageContainer>
      {partnerLocationToUpdate && (
        <UpdatePartnerLocationModal
          location={partnerLocationToUpdate}
          setLocation={setPartnerLocationToUpdate}
          onUpdateCb={() =>
            dispatch({ type: SET_PARTNER_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE, payload: { ...tableState } })
          }
          getOptionLabel={option => option.address ?? option.addressShort}
        />
      )}
    </Page>
  );
};
