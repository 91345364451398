/* eslint-disable no-param-reassign */
import axios from 'axios';
import { transformError } from 'error';

export default getAccessTokenSilently => {
  axios.defaults.baseURL = process.env.REACT_APP_API;

  axios.interceptors.request.use(
    async config => {
      if (getAccessTokenSilently) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(transformError(error)),
  );

  axios.interceptors.response.use(
    response => response.data,
    error => Promise.reject(transformError(error)),
  );
};
