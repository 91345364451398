/* eslint-disable no-underscore-dangle */
import React from 'react';

import { DetailsOverview, PageContainer, SpacedContainer, USER_TYPE_DISPLAY_NAMES } from '@leaf/components';
import { Grid } from '@mui/material';
import AdaptCompanyData from 'domain/company/AdaptCompanyData';
import {
  getCompanyGeneralDetails,
  getCompanyContactDetails,
  getCompanyTypeSpecificDetails,
} from './companyDetailsData';

export default ({ data }) => {
  const [companyData, setCompanyData] = React.useState({
    runDate: null,
    windowStartDate: null,
    windowEndDate: null,
    hasAdapt: null,
  });

  React.useEffect(() => {
    if (data.id) {
      AdaptCompanyData.read(data.id).then(setCompanyData);
    }
  }, [data.id]);

  return (
    <PageContainer>
      <SpacedContainer container spacing={2} autoWidth>
        <Grid item xs={6}>
          <DetailsOverview
            title={`${USER_TYPE_DISPLAY_NAMES[data.type]} details`}
            data={getCompanyGeneralDetails(data)}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid item xs={12}>
            <DetailsOverview title="Contact" data={getCompanyContactDetails(data)} />
          </Grid>

          <Grid item xs={12}>
            <DetailsOverview
              title={`${USER_TYPE_DISPLAY_NAMES[data.type]}-specific fields`}
              data={getCompanyTypeSpecificDetails({ ...data, ...companyData })}
            />
          </Grid>
        </Grid>
      </SpacedContainer>
    </PageContainer>
  );
};
