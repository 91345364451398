import React from 'react';
import { Switch } from '@mui/material';

export default React.memo(({ formik, name, label, ...rest }) => (
  <div>
    <Switch
      name={name}
      label={label}
      checked={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      {...rest}
    />
    <span>{label}</span>
  </div>
));
