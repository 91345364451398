import { gql } from 'graphql-request';

// NOTE: field names need to be snake case for correct mapping to be achieved
const WHERE = {
  BUYER_IDS_VAR: gql`$buyer_ids: [bigint!]`,
  BUYER_IDS_CLAUSE: gql`buy_contract: { buyer_id: { _in: $buyer_ids } }`,
  SELLER_IDS_VAR: gql`$seller_ids: [bigint!]`,
  SELLER_IDS_CLAUSE: gql`sell_contract: { seller_id: { _in: $seller_ids } }`,
  RANGE_START_DATE_VAR: gql`$range_start_date: date!`,
  RANGE_START_DATE_CLAUSE: gql`_or: [{end_date: { _gte: $range_start_date }}, {_and: [{end_date: { _is_null: true }}, {contract_match_volumes: {end_date: { _gte: $range_start_date }}}]}]`,
  RANGE_END_DATE_VAR: gql`$range_end_date: date!`,
  RANGE_END_DATE_CLAUSE: gql`_or: [{start_date: { _lte: $range_end_date }}, {_and: [{start_date: { _is_null: true }}, {contract_match_volumes: {start_date: { _lte: $range_end_date }}}]}]`,
  RANGE_ASSIGNED_WEEKLY_VOLUME_MIN_VAR: gql`$range_assigned_weekly_volume_min: Int!`,
  RANGE_ASSIGNED_WEEKLY_VOLUME_MIN_CLAUSE: gql`assigned_weekly_volume: { _gte: $range_assigned_weekly_volume_min }`,
  RANGE_ASSIGNED_WEEKLY_VOLUME_MAX_VAR: gql`$range_assigned_weekly_volume_max: Int!`,
  RANGE_ASSIGNED_WEEKLY_VOLUME_MAX_CLAUSE: gql`assigned_weekly_volume: { _lte: $range_assigned_weekly_volume_max }`,
};

const isUUID = uuid => uuid?.length === 36 && [...uuid.matchAll(/–|-/g)].length === 4;

const whereClause = ({ where, varsToRemap, gqlQuickFiltersMeta, search }) => {
  let whereGQL = gql``;
  const whereVarsKeys = Object.keys(where).filter(
    k => (Array.isArray(where[k]) && where[k].length) || typeof where[k] === 'string' || typeof where[k] === 'number',
  );

  let nestedQueries = [];
  if (gqlQuickFiltersMeta) {
    nestedQueries =
      Object.keys(varsToRemap).filter(
        k => Array.isArray(varsToRemap[k]) && varsToRemap[k].length && gqlQuickFiltersMeta[k].isNestedQuery,
      ) ?? [];
  }

  const varsToRemapKeys = Object.keys(varsToRemap)
    .filter(k => Array.isArray(varsToRemap[k]) && varsToRemap[k].length)
    .some(k => varsToRemap[k]?.length > 0)
    ? Object.keys(varsToRemap).filter(k => !nestedQueries.includes(k))
    : [];

  if (whereVarsKeys.length || varsToRemapKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
      where: {
        _and: [
          ${whereVarsKeys.map(key =>
            where[key] != null
              ? gql`{
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
              }`
              : '',
          )}
          
          ${
            search !== undefined && search !== ''
              ? gql`{
            _or: [
              ${isUUID(search) ? gql`{ buy_contract: { id: { _eq: "${search}" } } }` : ''}
              ${isUUID(search) ? gql`{ sell_contract: { id: { _eq: "${search}" } } }` : ''}
              {buy_contract: {
                buyer: { name: { _ilike: "%${search}%" } }}}
              {sell_contract: {
                seller: { name: { _ilike: "%${search}%" } }}}
              {buy_contract:
                {contract_routes: {
                  is_primary: { _eq: true },
                    route: {
                      legs: {
                        _or: [
                          {lane: {origin_name: { _ilike: "%${search}%" }}}
                          {lane: {destination_name: { _ilike: "%${search}%" }}}
                        ]
                      }
                    }
                  }
                }
              }
            ]}`
              : ''
          }
          
          ${
            varsToRemapKeys.length > 0
              ? gql`{
                _and: [
                  ${varsToRemapKeys.map(key =>
                    varsToRemap[key]
                      ? gql`{
                            _or: [
                                ${gqlQuickFiltersMeta[key].metaGenerator(varsToRemap[key]).map(
                                  operations => gql`{
                                      _and: [
                                        ${operations.map(
                                          operation =>
                                            operation.query ??
                                            gql`
                                              {${operation.field}: { ${operation.name}: ${
                                              operation.type === 'string' ? `"${operation.value}"` : operation.value
                                            }}
                                            }`,
                                        )}]
                                      }`,
                                )}
                              ]
                            }`
                      : '',
                  )}        
                  ]}`
              : ''
          }

          ${
            nestedQueries.length > 0
              ? gql`{
                _and: [${nestedQueries.map(key =>
                  varsToRemap[key]
                    ? gql`
                        ${gqlQuickFiltersMeta[key].metaGenerator(varsToRemap[key])}
                      `
                    : '',
                )}]
              }`
              : ''
          }
        ]
      }
  `;
  }
  return whereGQL;
};

const whereVar = ({ where }) => gql`
  ${where.buyer_ids ? WHERE.BUYER_IDS_VAR : ``}
  ${where.seller_ids ? WHERE.SELLER_IDS_VAR : ``}
  ${where.range_start_date ? WHERE.RANGE_START_DATE_VAR : ``}
  ${where.range_end_date ? WHERE.RANGE_END_DATE_VAR : ``}
  ${where.range_assigned_weekly_volume_min != null ? WHERE.RANGE_ASSIGNED_WEEKLY_VOLUME_MIN_VAR : ``}
  ${where.range_assigned_weekly_volume_max != null ? WHERE.RANGE_ASSIGNED_WEEKLY_VOLUME_MAX_VAR : ``}
`;

export const getContractMatchesGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getContractMatches(
      $limit: Int
      $orderBy: [contract_match_order_by]
      $offset: Int
      ${whereVar(variables)}
    ) {
      contract_match(
        limit: $limit
        order_by: $orderBy
        offset: $offset
        ${whereGQL}
      ) {
        buy_contract {
          buyer {
            id
            name
          }
          contract_routes(where: {is_primary: {_eq: true}}) {
            route {
              legs(order_by: {sequence: asc}) {
                lane {
                  origin: origin_name
                  destination: destination_name
                }
              }
            }
          }
          id
        }
        sell_contract {
          seller {
            id
            name
          }
          id
          contract_type
          is_fleet
        }
        contract_match_volumes {
          start_date
          end_date
        }
        is_enabled
        end_date
        notes
        start_date
        assigned_weekly_volume
      }
      contract_match_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};
