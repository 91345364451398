/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, Typography, DialogContentText } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useStateMachine } from 'little-state-machine';
import useGQL from 'hooks/useGQL';
import RoutesOverview from 'contracts/shared/RoutesOverview';
import { useSnackbar } from 'notistack';

import { SectionHeader } from '../partials/viewHelpers';
import { CreateRoute } from './CreateRoute';
import CustomDialog from '../../shared/CustomDialog';
import { searchLaneV2, detachRouteFromContract, attachRouteToContract } from '../domain/contractModel';
import { setDefaultLaneOptions, updateRouteCount } from '../domain/stateMachine';

import { getContractRoutes } from '../../details/domain/contractDetailsModel';

export function SelectRoutes() {
  const location = useLocation();
  const params = useParams();
  const getGQLClient = useGQL();
  const { getState, actions } = useStateMachine({ setDefaultLaneOptions, updateRouteCount });
  const [openAddRoute, setOpenAddRoute] = useState(false);
  const [openDialogToConfirmDelete, setOpenDialogToConfirmDelete] = useState(false);
  const firstRender = useRef(false);
  const routeId = useRef(null);
  const [editingRoute, setEditingRoute] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [needToAttachRouteToDuplicateContract, setNeedToAttachRouteToDuplicateContract] = useState(
    location.search.toLowerCase().includes('duplicate'),
  );

  const [newRoutesAvailable, setNewRoutesAvailable] = useState(false);
  const { newContract: contract, ROUTE_COUNT } = getState();

  const deleteRoute = () => {
    detachRouteFromContract(contract.id, routeId.current, {})
      .then(() => {
        routeId.current = null;
        actions.updateRouteCount(ROUTE_COUNT - 1);
        enqueueSnackbar(`Successfuly detached Route(s) from contract!`, { variant: 'success' });
        setNewRoutesAvailable(!newRoutesAvailable);
      })
      .catch(error => {
        routeId.current = null;
        enqueueSnackbar(`Detaching routes from contract failded. ${error}`);
      });
  };

  const handleCreateRoute = (_, newRoutes) => {
    setOpenAddRoute(!openAddRoute);
    setNewRoutesAvailable(newRoutes);
    routeId.current = null;
    setEditingRoute(null);
  };

  const mayExecuteDelete = event => {
    setOpenDialogToConfirmDelete(!openDialogToConfirmDelete);
    const confirm = event.target.getAttribute('data-accept');
    if (confirm) {
      deleteRoute();
    }
  };

  useEffect(() => {
    if (getGQLClient && !firstRender.current) {
      const variables = contract.buyerId
        ? { where: { _and: [{ shipper: { id: { _eq: contract.buyerId } } }] } }
        : { where: {} };
      searchLaneV2(getGQLClient, variables).then(res => {
        actions.setDefaultLaneOptions(res.lane);
      });
      firstRender.current = true;
    }
  }, [getGQLClient, actions, contract.buyerId]);

  const controlRoutesActions = (action, route) => {
    if (action && route) {
      routeId.current = route.id;
      if (action === 'delete') {
        setOpenDialogToConfirmDelete(true);
      }

      if (action === 'edit') {
        setEditingRoute(route);
      }

      if (action === 'setPrimaryRoute') {
        attachRouteToContract(contract.id, route.id, { isPrimary: true })
          .then(() => {
            enqueueSnackbar(`Successfuly updated primary route `, { variant: 'success' });
            setNewRoutesAvailable(true);
          })
          .catch(error => {
            enqueueSnackbar(`Setting primary route failded. ${error}`);
          });
      }
    }
  };

  useEffect(() => {
    if (routeId.current && editingRoute) {
      setOpenAddRoute(true);
    }
  }, [editingRoute, routeId]);

  useEffect(() => {
    if (needToAttachRouteToDuplicateContract) {
      //  Fetch all routes from original contract
      const duplicateContractId = params.id;
      getContractRoutes(getGQLClient, duplicateContractId)
        .then(({ data }) => {
          // Attach all contracts to newly duplicated contract
          data[0].routes.forEach(route => {
            attachRouteToContract(contract.id, route.id, { isPrimary: route.isPrimary }).catch(error => {
              enqueueSnackbar(`Failed to attach route to duplicate contract . ${error}`);
            });
          });
          enqueueSnackbar(`Successfuly attach routes to duplicate contract `, { variant: 'success' });
          setNeedToAttachRouteToDuplicateContract(false);
          setNewRoutesAvailable(true);
        })
        .catch(error => {
          enqueueSnackbar(`Failed to fetch routes for duplicate contract . ${error}`);
        });
    }
  }, [contract.id, enqueueSnackbar, getGQLClient, needToAttachRouteToDuplicateContract, params]);

  if (needToAttachRouteToDuplicateContract) {
    return null;
  }

  return (
    <Box>
      <SectionHeader>
        <Button onClick={handleCreateRoute} startIcon={<AddCircleIcon />}>
          Add route
        </Button>
      </SectionHeader>

      {newRoutesAvailable ? (
        <>
          <RoutesOverview contractId={contract.id} newRoutes editMode actions={controlRoutesActions} />
        </>
      ) : (
        <RoutesOverview contractId={contract.id} editMode actions={controlRoutesActions} />
      )}

      <CustomDialog
        maxWidth="xl"
        fullWidth
        open={openAddRoute}
        title="Select Route"
        handleClose={handleCreateRoute}
        defaultActions={false}
      >
        <CreateRoute dismiss={handleCreateRoute} routeId={routeId.current} editingRoute={editingRoute} />
      </CustomDialog>

      <CustomDialog open={openDialogToConfirmDelete} title="Delete Route" handleClose={mayExecuteDelete}>
        <DialogContentText sx={{ p: '0 16px' }}>
          <Typography sx={{ mb: 2 }}>Are you sure you want to delete this route?</Typography>
          <Typography sx={{ mb: 2 }}>You will not be able to recover it and your data will be lost.</Typography>
        </DialogContentText>
      </CustomDialog>
    </Box>
  );
}
