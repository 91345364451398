import { getCurrentUserMetadata } from 'authentication';

// TODO: LEAF-989 LEAF-4469
export const isUserAllowedToAccessAdaptBoard = email => {
  if (process.env.REACT_APP_ENV !== 'PROD') {
    return true;
  }
  if (!email) {
    return false;
  }
  return Boolean(
    [
      'ross.otto@leaflogistics.com',
      'stefan.friederichs@leaflogistics.com',
      'andrea.pope@leaflogistics.com',
      'stevan.buzejic@htecgroup.com',
      'luka.kajtes@htecgroup.com',
      'andreas.kull@leaflogistics.com',
      'jovan.tukic@htecgroup.com',
      'corey.brincks@leaflogistics.com',
      'josh.lewis@leaflogistics.com',
      'amanda.roe@leaflogistics.com',
      'josh.schmoldt@leaflogistics.com',
    ].find(item => item === email),
  );
};

// TODO: LEAF-989 LEAF-4469
export const isUserAllowedToEditDashboard = token => {
  if (process.env.REACT_APP_ENV !== 'PROD') {
    return true;
  }
  if (!token) {
    return false;
  }
  const { uid } = getCurrentUserMetadata(token);
  return [1, 7762, 1000, 53314, 51414, 54960, 49683].includes(Number(uid));
};
