import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import axios from 'setup/axios';
import sentry from 'setup/sentry';

import App from 'App';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_PRO_LICENSE_KEY || '');

sentry();

const AppWithToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (getAccessTokenSilently) {
      axios(getAccessTokenSilently);
    }
  }, [getAccessTokenSilently]);

  return <App />;
};

// TODO: Change this to a real type
const onRedirectCallback = (appState?: any) => {
  window.history.replaceState(appState, document.title, `${window.location.origin}/continue`);
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    redirectUri={`${window.location.origin}/continue`}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
  >
    <AppWithToken />
  </Auth0Provider>,
  document.getElementById('root'),
);
