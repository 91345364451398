/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useSteps } from '@leaf/components/';
import { GeneralForm } from './GeneralForm';
import { TimeframeVolumeForm } from './TimeframeVolumeForm';
import { Pricing } from './Pricing';

const steps = [
  { label: 'General', component: GeneralForm },
  { label: 'Timeframe and Volume', component: TimeframeVolumeForm },
  { label: 'Pricing', component: Pricing },
];

export function ContractSteps({ callNextTab, abortContract }) {
  const { next, back, currentStepIndex, StepView, isLastStep } = useSteps(steps);
  const awareOfLastStep = isLastStep ? callNextTab : next;

  return (
    <Box sx={{ p: 2 }}>
      <Stepper activeStep={currentStepIndex}>
        {steps.map(({ label }) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <StepView next={awareOfLastStep} back={back} abortContract={abortContract} />
    </Box>
  );
}
