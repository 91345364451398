import React, { useEffect, useState } from 'react';
import useGQL from 'hooks/useGQL';
import { useLoading, OverviewTable, utility, theme } from '@leaf/components';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContractVolume, getContractVolumes, volumeMapper } from './domain/volumeAvailabilityModel';

const SEPARATOR = '||';

const Wrapper = styled.div`
  .MuiPaper-root > .MuiToolbar-root {
    background: #eee;
  }
  .MuiAccordionSummary-root {
    min-height: 64px;
    background-color: #eee;
    padding-left: ${({ theme }) => theme.spacing(3)};
  }
  .MuiAccordion-root {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const Header = styled.div`
  white-space: nowrap;
`;

const scrollToColumn = () => {
  const currentDate: any = new Date();
  const startDate: any = new Date(currentDate.getFullYear(), 0, 1);
  var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

  const weekNumber = Math.ceil(days / 7);
  const elements = document.querySelectorAll('th[scope="col"] > div');
  for (const div of elements as any) {
    if (div.textContent.includes(`Week ${weekNumber}`)) {
      div.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      div.parentElement.style.backgroundColor = theme.palette.divider;
      setTimeout(() => {
        div.parentElement.style.backgroundColor = 'white';
      }, 500);
      break;
    }
  }
};

interface VolumeAvailabilityProps {
  contractId: string;
}

export default ({ contractId }: VolumeAvailabilityProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const getGQLClient = useGQL();
  const [volumes, setVolumes] = useState<ContractVolume[]>([]);

  const [getVolumes, areVolumesLoading] = useLoading(
    async (id: string) => getContractVolumes(getGQLClient, id).then(setVolumes).catch(enqueueSnackbar),
    true,
  );

  useEffect(() => {
    if (contractId) {
      getVolumes(contractId);
    }
  }, [contractId]);

  const columns = [
    {
      name: 'type',
      label: 'Week',
    },

    ...volumes.map((volume, i) => {
      return {
        name: `week-${i}`,
        label: `Week ${i + 1}${SEPARATOR}${utility.date.backendStringToViewString(volume.weekStartDate)}`,
        options: {
          sort: false,
          ...utility.table.rightAlignColumnProps(),
          customHeadLabelRender: ({ label }: any) => {
            const [row1, row2] = label.split(SEPARATOR);
            return (
              <Header>
                <Typography variant="caption">{row1}</Typography>
                <Typography variant="body1">{row2}</Typography>
              </Header>
            );
          },
        },
      };
    }),
  ];

  return (
    <Wrapper>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Volume Availability</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box m={2}>
            <Button onClick={scrollToColumn} variant="outlined">
              SCROLL TO CURRENT WEEK
            </Button>
          </Box>
          <OverviewTable
            options={{ search: false, download: false, filter: false, viewColumns: false }}
            components={{ TableFooter: () => null }}
            columns={columns}
            data={volumeMapper(volumes)}
            isLoading={areVolumesLoading}
          />
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
};
