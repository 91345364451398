import React from 'react';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';

const numberOfColumns = 12;
const getXsValue = componentsCount => {
  if (numberOfColumns % componentsCount) {
    return numberOfColumns;
  }

  return numberOfColumns / componentsCount;
};

const GridAlignedFlexEnd = styled(Grid)`
  display: flex;
  align-items: flex-end;
`;

export default React.memo(({ components, isAlignedFlexEnd }) => {
  const childrenComponents = Object.values(components);

  return (
    <>
      {childrenComponents.map((child, index) =>
        // eslint-disable-next-line react/no-array-index-key
        isAlignedFlexEnd ? (
          // eslint-disable-next-line react/no-array-index-key
          <GridAlignedFlexEnd key={index} item xs={getXsValue(childrenComponents.length)}>
            {child}
          </GridAlignedFlexEnd>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={index} item xs={getXsValue(childrenComponents.length)}>
            {child}
          </Grid>
        ),
      )}
    </>
  );
});
