import React, { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { cloneDeep } from 'lodash';

import { Page, ServerSideTable, PageContentLayout, PageContainer, useTableCustomFilters } from '@leaf/components';

import { getAll, getCompanies } from 'domain/user/userQueries';
import { StateContext } from 'state/StateProvider';
import { SET_USERS_OVERVIEW_TABLE_STATE } from 'state/stateReducer';
import AddEditUserDialog from 'users/AddEditUserDialog';
import useGQL from 'hooks/useGQL';
import { ROLES_AS_FILTER_OPTIONS } from 'constants/roles';

const getColumns = filterOptions => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  { label: 'Company ID', name: 'company.id', options: { filter: false, sort: false, display: false } },
  {
    label: 'Company',
    name: 'company',
    options: {
      ...filterOptions.company,
      customBodyRender: company => company?.name,
      sort: true,
    },
  },
  { label: 'First name', name: 'first_name', options: { filter: false, sort: true } },
  { label: 'Last name', name: 'last_name', options: { filter: false, sort: true } },
  { label: 'Email', name: 'email', options: { filter: false, sort: true } },
  { label: 'Phone', name: 'phone_number', options: { filter: false, sort: false } },
  { label: 'Role', name: 'role', options: { ...filterOptions.role, sort: true } },
  { label: 'Logins', name: 'login_count', options: { filter: false, sort: true } },
  { label: 'Last login', name: 'last_login_at', options: { filter: false, sort: true } },
];

const UserOverview = () => {
  const [open, setOpen] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const getGQLClient = useGQL();

  React.useEffect(() => {
    getCompanies(getGQLClient).then(setCompanies);
  }, []);

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'company',
      filterOptionsGetter: () => companies,
    },
    {
      inputType: 'dropdown',
      columnName: 'role',
      filterOptionsGetter: () => ROLES_AS_FILTER_OPTIONS,
    },
  ]);

  const toggleDialog = () => {
    if (open && selectedUser) {
      setSelectedUser(null);
    }
    setOpen(!open);
  };

  const options = {
    filter: true,
    onRowClick: (_, rowMeta) => {
      const user = cloneDeep(users[rowMeta.dataIndex]);
      if (user.company) {
        user.company = { label: user.company.name, value: user.company.id };
        user.company_id = user.company.value;
      }
      setSelectedUser(user);
      toggleDialog();
    },
    customToolbar: () => (
      <Tooltip title="Add user">
        <IconButton onClick={toggleDialog}>
          <Add />
        </IconButton>
      </Tooltip>
    ),
  };

  const {
    state: {
      tables: { usersOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const handleChange = setTableState =>
    dispatch({ type: SET_USERS_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  const onResolve = ({ data }) => setUsers(data);

  return (
    <Page title="Users">
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={options}
            columns={getColumns(filterOptions)}
            fn={() => getAll(getGQLClient, tableState)}
            onResolve={onResolve}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>

      {open && (
        <AddEditUserDialog
          title="Add user"
          open={open}
          onClose={toggleDialog}
          companies={companies}
          selectedUser={selectedUser}
        />
      )}
    </Page>
  );
};

export default UserOverview;
