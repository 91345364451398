import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { OverviewTable, ServerSideTable, utility, useCopyToClipboard } from '@leaf/components';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';
import { StateContext } from 'state/StateProvider';
import { SET_OPS_CONTRACTS_TABLE_STATE } from 'state/stateReducer';
import useGQL from 'hooks/useGQL';
import { getOpsPlanContracts } from './domain/opsPlanDetailsModel';
import { focusOpsPlansColumns, getContractsOpsPlansColumns } from './table-config';

const FocusWrapper = styled.div`
  .MuiPaper-root > .MuiToolbar-root {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4a90e2;
  }
`;

const OpsPlanWrapper = styled.div`
  height: 100%;
  .MuiPaper-root > .MuiToolbar-root {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4caf50;
  }
`;

const FocusOpsPlan = ({ opsPlan }) => (
  <FocusWrapper>
    <OverviewTable
      title="Focus Ops Plan"
      customSearch={false}
      options={{ search: false, download: false, filter: false, viewColumns: false }}
      components={{ TableFooter: () => null }}
      columns={focusOpsPlansColumns}
      data={[opsPlan]}
      isLoading={false}
    />
  </FocusWrapper>
);

const OpsPlanContracts = ({ opsPlan }) => {
  const history = useHistory();
  const getGQLClient = useGQL();

  const getOpsPlanContractsData = tableState => {
    const variables = {
      limit: tableState.rowsPerPage,
      offset: tableState.page * tableState.rowsPerPage,
    };
    return getOpsPlanContracts(getGQLClient, opsPlan.id, variables);
  };

  const {
    state: {
      tables: { opsPlanContracts: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const { enqueueSnackbar } = useSnackbar();

  const copy = useCopyToClipboard(
    successMessage => enqueueSnackbar(successMessage, { variant: 'success' }),
    errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' }),
  );

  const handleChange = setTableState =>
    dispatch({ type: SET_OPS_CONTRACTS_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <OpsPlanWrapper>
      <ServerSideTable
        title="Contracts"
        customSearch={false}
        options={{
          search: false,
          download: false,
          filter: false,
          viewColumns: false,
          onRowClick: rowData => {
            const id = rowData[0];
            history.push(`/contracts/${id}`);
          },
          ...utility.table.getRowHoverActionProps('contract'),
        }}
        columns={getContractsOpsPlansColumns(copy)}
        fn={() => getOpsPlanContractsData(tableState)}
        tableState={tableState}
        setTableState={handleChange}
        errorHandler={enqueueSnackbar}
      />
    </OpsPlanWrapper>
  );
};

const OpsPlansDetailsContracts = ({ opsPlan }) => (
  <Box display="flex" flexDirection="column" height="100%">
    {opsPlan && (
      <>
        <FocusOpsPlan opsPlan={opsPlan} />
        <OpsPlanContracts opsPlan={opsPlan} />
      </>
    )}
  </Box>
);

export default OpsPlansDetailsContracts;
