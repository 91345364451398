import React from 'react';
import { Page, Dashboard } from '@leaf/components';
import * as DashboardDomain from 'domain/dashboard/Dashboard';
import { useSnackbar } from 'notistack';
import useDashboard from 'hooks/useDashboard';

const DashboardView = ({ name }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { canEdit } = useDashboard();
  return (
    <Page title="Dashboard">
      <Dashboard
        name={name}
        api={DashboardDomain.default}
        userType="admin"
        onErrorHandler={enqueueSnackbar}
        canEdit={canEdit}
      />
    </Page>
  );
};

export default DashboardView;
