import { getExplorerUrl } from './adaptLanesColumns';

const getOptions = (tableState, history) => ({
  filter: true,
  sortOrder: {
    name: tableState.sort.name,
    direction: tableState.sort.order,
  },
  setRowProps: row => {
    if (getExplorerUrl(row)) {
      return {
        style: {
          cursor: 'pointer',
        },
      };
    }
    return {
      style: {
        cursor: 'default',
      },
    };
  },
  onRowClick: row => {
    const explorerUrl = getExplorerUrl(row);
    if (explorerUrl) {
      history.push(explorerUrl);
    }
  },
});

export default getOptions;
