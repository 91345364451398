import React from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, Typography, IconButton, Button } from '@mui/material';
import { Close, Publish, Clear } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';

import { UploadButton, ImportSummaryTable } from '@leaf/components';
import Ops from 'domain/planning/Ops';
import {
  DialogTitleContainer,
  DialogContentContainer,
  DialogContentPanel,
  UploadButtonContainer,
  FileUploadedContainer,
  IconWrapper,
  DialogContentTitle,
  DownloadTemplateContainer,
  DialogActionsContainer,
  IconButtonDownload,
} from './ImportOpsPlansDialog.styled';

export default ({ open, handleCloseDialog, title }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedFile, setUploadedFile] = React.useState();
  const [failedDataList, setFailedDataList] = React.useState();
  const [isImporting, setIsImporting] = React.useState(false);
  const [importProgress, setImportProgress] = React.useState(0);
  const [openSummaryDialog, setOpenSummaryDialog] = React.useState(false);

  const handleDownload = () => {
    Ops.downloadOpsPlans([]);
  };

  const handleUploadFile = file => {
    setUploadedFile(file);
  };

  const handleCloseImportDialog = () => {
    setUploadedFile(null);
    handleCloseDialog();
  };

  const handleImportContractMatches = async () => {
    if (uploadedFile) {
      try {
        setIsImporting(true);
        const data = await Ops.importShipperOps(uploadedFile, progress => {
          const { loaded, total } = progress;
          const percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setImportProgress(percent);
          }
        });
        if (data?.total === 0) {
          // Empty file
          enqueueSnackbar('Imported file is empty, please add data.', { variant: 'error' });
        } else if (data?.failed > 0) {
          // Errors
          setImportProgress(100);
          setFailedDataList(data);
          setOpenSummaryDialog(true);
        } else {
          // Success
          setImportProgress(100);
          setUploadedFile(null);
          handleCloseImportDialog();
          enqueueSnackbar('Ops Plans successfully uploaded.', { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar(error.split('LINE')[0]);
        setUploadedFile(null);
      } finally {
        setIsImporting(false);
        setTimeout(() => {
          setImportProgress(0);
        }, 1000);
      }
    }
  };

  const renderUploadSuccessfully = () => (
    <FileUploadedContainer>
      <IconWrapper />
      <Typography variant="h6">{uploadedFile?.name ? uploadedFile?.name : 'undefined'}</Typography>
      <Typography>{uploadedFile?.size ? `${uploadedFile?.size}b` : 'undefined'}</Typography>
      <IconButton onClick={() => handleUploadFile(null)} size="large">
        <Clear color="disabled" />
      </IconButton>
    </FileUploadedContainer>
  );

  const handleCloseSummaryDialog = () => {
    setOpenSummaryDialog(false);
    setFailedDataList(null);
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleCloseImportDialog} open={open}>
      <DialogTitleContainer>
        <Typography variant="body2">{title}</Typography>
        <IconButton onClick={handleCloseImportDialog}>
          <Close />
        </IconButton>
      </DialogTitleContainer>
      <DialogContentContainer dividers>
        <DialogContentPanel>
          <DialogContentTitle>
            <Typography variant="body2">Import File</Typography>
            <DownloadTemplateContainer onClick={handleDownload}>
              <IconButtonDownload>
                <LaunchIcon />
              </IconButtonDownload>
              <Typography variant="body2">Download Template</Typography>
            </DownloadTemplateContainer>
          </DialogContentTitle>
          <Typography>Accepted file formats include csv, xls, xlsx</Typography>
        </DialogContentPanel>
        <UploadButtonContainer>
          {uploadedFile ? (
            renderUploadSuccessfully()
          ) : (
            <UploadButton
              variant="contained"
              color="primary"
              icon={<Publish />}
              label="CHOOSE A FILE"
              handleUpload={handleUploadFile}
              fileTypes={['.xls,.xlsx,.csv']}
            />
          )}
        </UploadButtonContainer>
      </DialogContentContainer>
      <DialogActionsContainer>
        <Button variant="outlined" onClick={handleCloseImportDialog}>
          CANCEL
        </Button>
        <Button variant="contained" color="primary" onClick={handleImportContractMatches} disabled={!uploadedFile}>
          IMPORT
        </Button>
      </DialogActionsContainer>
      {openSummaryDialog && (
        <ImportSummaryTable
          importSummary={failedDataList}
          onClose={handleCloseSummaryDialog}
          importProgress={importProgress}
          isLoading={isImporting}
        />
      )}
    </Dialog>
  );
};
