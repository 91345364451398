/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SpacedContainer,
  PageContainer,
  PageContentLayout,
  Overview,
  ContractType,
  CONTRACT_TYPE,
  Form,
  utility,
} from '@leaf/components';
import { Typography, Grid, ListItem, TextField, MenuItem, Box, Stack, Button } from '@mui/material';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';

import useGQL from 'hooks/useGQL';
import { GQL_EQUIPMENT_TYPES } from 'graphql/filter-queries/GQL_EQUIPMENT_TYPES';
import { GQL_SHIPPERS } from 'graphql/filter-queries/GQL_COMPANIES';
import Ops from 'domain/planning/Ops';
import { getOpsPlanId } from './domain/GQL_OPS_PLANS_DETAILS';

const SubtitleWrapper = styled.div`
  padding-top: ${({ theme }) => `${theme.spacing(1)}`};
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}`};
`;

const OpsPlansDetailsEdit = ({ opsPlan, setEditOpsPlan }) => {
  const getGQLClient = useGQL();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [shippers, setShippers] = useState([
    {
      id: opsPlan.shipper.id,
      name: opsPlan.shipper.name,
    },
  ]);
  const [equipmentTypes, setEquipmentTypes] = useState([
    {
      id: opsPlan.equipmentType.id,
      name: opsPlan.equipmentType.name,
    },
  ]);

  const [shipperId, setShipperId] = useState(opsPlan.shipper.id);
  const [routeId, setRouteId] = useState(opsPlan.route.id);
  const [equipmentTypeId, setEquipmentTypeId] = useState(opsPlan.equipmentType.id);
  const [adaptLaneId, setAdaptLaneId] = useState(opsPlan.adaptLaneId);
  const [adaptRunDate, setAdaptRunDate] = useState(opsPlan.adaptRunDate);
  const [adaptWeeklyVolume, setAdaptWeeklyVolume] = useState(opsPlan.adaptWeeklyVolume);
  const [adaptTargetShipperRpm, setAdaptTargetShipperRpm] = useState(opsPlan.adaptTargetShipperRpm);
  const [plannedWeeklyVolume, setPlannedWeeklyVolume] = useState(opsPlan.plannedWeeklyVolume);
  const [plannedAllocationPct, setPlannedAllocationPct] = useState(opsPlan.plannedAllocationPct);
  const [plannedShipperRpm, setPlannedShipperRpm] = useState(opsPlan.plannedShipperRpm);
  const [plannedStartDate, setPlannedStartDate] = useState(opsPlan.plannedStartDate);
  const [plannedEndDate, setPlannedEndDate] = useState(opsPlan.plannedEndDate);
  const [plannedLeafTenderAcceptance, setPlannedLeafTenderAcceptance] = useState(opsPlan.plannedLeafTenderAcceptance);
  const [maxCapacityPctSpot, setMaxCapacityPctSpot] = useState(opsPlan.maxCapacityPctSpot);
  const [maxCapacityPctRg, setMaxCapacityPctRg] = useState(opsPlan.maxCapacityPctRg);
  const [maxCapacityPctFleet, setMaxCapacityPctFleet] = useState(opsPlan.maxCapacityPctFleet);
  const [maxCapacityPctFlexWeekly, setMaxCapacityPctFlexWeekly] = useState(opsPlan.maxCapacityPctFlexWeekly);
  const [maxCapacityPctNetworkMove, setMaxCapacityPctNetworkMove] = useState(opsPlan.maxCapacityPctNetworkMove);
  const [maxCapacityPctFlexDayOf, setMaxCapacityPctFlexDayOf] = useState(opsPlan.maxCapacityPctFlexDayOf);
  const [lspTargetLinehaulRpmSpot, setLspTargetLinehaulRpmSpot] = useState(opsPlan.lspTargetLinehaulRpmSpot);
  const [lspTargetLinehaulRpmRg, setLspTargetLinehaulRpmRg] = useState(opsPlan.lspTargetLinehaulRpmRg);
  const [lspTargetLinehaulRpmFleet, setLspTargetLinehaulRpmFleet] = useState(opsPlan.lspTargetLinehaulRpmFleet);
  const [lspTargetLinehaulRpmFlexWeekly, setLspTargetLinehaulRpmFlexWeekly] = useState(
    opsPlan.lspTargetLinehaulRpmFlexWeekly,
  );
  const [lspTargetLinehaulRpmNetworkMove, setLspTargetLinehaulRpmNetworkMove] = useState(
    opsPlan.lspTargetLinehaulRpmNetworkMove,
  );
  const [lspTargetLinehaulRpmFlexDayOf, setLspTargetLinehaulRpmFlexDayOf] = useState(
    opsPlan.lspTargetLinehaulRpmFlexDayOf,
  );

  const saveOpsPlan = () =>
    Ops.updateShipperOpsPlan({
      shipper_id: shipperId,
      route_id: routeId,
      equipment_type_id: equipmentTypeId,
      adapt_lane_ref: adaptLaneId,
      adapt_run_date: adaptRunDate,
      adapt_weekly_volume: adaptWeeklyVolume,
      adapt_target_shipper_rpm: adaptTargetShipperRpm,
      planned_weekly_volume: plannedWeeklyVolume,
      planned_allocation_pct: plannedAllocationPct,
      planned_shipper_rpm: plannedShipperRpm,
      planned_start_date: plannedStartDate,
      planned_end_date: plannedEndDate,
      planned_leaf_tender_acceptance_pct: plannedLeafTenderAcceptance,
      max_capacity_pct_spot: maxCapacityPctSpot,
      max_capacity_pct_rg: maxCapacityPctRg,
      max_capacity_pct_fleet: maxCapacityPctFleet,
      max_capacity_pct_flex_weekly: maxCapacityPctFlexWeekly,
      max_capacity_pct_network_move: maxCapacityPctNetworkMove,
      max_capacity_pct_flex_day_of: maxCapacityPctFlexDayOf,
      lsp_target_linehaul_rpm_spot: lspTargetLinehaulRpmSpot,
      lsp_target_linehaul_rpm_rg: lspTargetLinehaulRpmRg,
      lsp_target_linehaul_rpm_fleet: lspTargetLinehaulRpmFleet,
      lsp_target_linehaul_rpm_flex_weekly: lspTargetLinehaulRpmFlexWeekly,
      lsp_target_linehaul_rpm_network_move: lspTargetLinehaulRpmNetworkMove,
      lsp_target_linehaul_rpm_flex_day_of: lspTargetLinehaulRpmFlexDayOf,
    }).then(() => enqueueSnackbar('Ops plan saved successfully', { variant: 'success' }));

  const handleSaveEditedOpsPlan = async () => {
    const client = await getGQLClient();

    const newIdRequired =
      shipperId !== opsPlan.shipper.id || routeId !== opsPlan.route.id || equipmentTypeId !== opsPlan.equipmentType.id;
    if (newIdRequired) {
      // If there are changes to shipper, route, or equipment type, it can potentially overwrite another existing plan
      // Check to make sure there are no other plans that have the same shipper, route, and equipement type
      const res = await client.request(getOpsPlanId(shipperId, routeId, equipmentTypeId));
      if (res.shipper_route_plan.length > 0) {
        enqueueSnackbar(
          'Unable to update ops plan: There is nother plan that already exists with the same shipper, route, and equipment type',
          { variant: 'error' },
        );
        return;
      }
    }

    try {
      await saveOpsPlan();
      if (!newIdRequired) {
        // Simple update - Refresh the page
        history.go(0);
        return;
      }
    } catch (error) {
      enqueueSnackbar(error);
      return;
    }

    // Delete the old ops plan
    await Ops.deleteShipperOpsPlan(opsPlan.id).catch(enqueueSnackbar);

    // Get the new plan's ID and go to the details view for the new ops plan
    const res = await client.request(getOpsPlanId(shipperId, routeId, equipmentTypeId));
    setEditOpsPlan(false);
    history.push(`/planning/ops-plans/${res.shipper_route_plan[0]?.id}`);
  };

  useEffect(() => {
    getGQLClient()
      .then(client => client.request(GQL_SHIPPERS))
      .then(res => setShippers(res.company));
    getGQLClient()
      .then(client => client.request(GQL_EQUIPMENT_TYPES))
      .then(res => setEquipmentTypes(res.equipment_type));
  }, []);

  return (
    <PageContainer>
      <SpacedContainer container spacing={2} autoWidth>
        {opsPlan && (
          <>
            <Grid item xs={4}>
              <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
                <Overview title="Lane Overview" titleBgColor="blue">
                  <ListItem disableGutters>
                    <TextField
                      select
                      label="Shipper*"
                      value={shipperId}
                      onChange={e => setShipperId(e.target.value)}
                      fullWidth
                    >
                      {shippers.map(e => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField label="Route ID*" value={routeId} onChange={e => setRouteId(e.target.value)} fullWidth />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      select
                      label="Equipment Type*"
                      value={equipmentTypeId}
                      onChange={e => setEquipmentTypeId(e.target.value)}
                      fullWidth
                    >
                      {equipmentTypes.map(e => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label="Adapt Lane ID"
                      value={adaptLaneId}
                      onChange={e => setAdaptLaneId(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <Form.Generic.Datepicker
                      label="Adapt Run Date"
                      useFieldNameHandler={false}
                      value={utility.date.backendStringToNormalizedUtcDate(adaptRunDate)}
                      onChange={value => setAdaptRunDate(utility.date.transformApiDate(value))}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label="Adapt Weekly Volume"
                      type="number"
                      value={adaptWeeklyVolume}
                      onChange={e => setAdaptWeeklyVolume(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label="Adapt Target Shipper RPM (USD)"
                      type="number"
                      value={adaptTargetShipperRpm}
                      onChange={e => setAdaptTargetShipperRpm(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                </Overview>
              </PageContentLayout>
            </Grid>
            <Grid item xs={4}>
              <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
                <Overview title="Plan Details" titleBgColor="green">
                  <ListItem disableGutters>
                    <TextField
                      label="Planned Weekly Volume*"
                      type="number"
                      value={plannedWeeklyVolume}
                      onChange={e => setPlannedWeeklyVolume(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label="Planned Allocation* (%)"
                      type="number"
                      value={plannedAllocationPct != null ? plannedAllocationPct * 100 : ''}
                      onChange={e => setPlannedAllocationPct(e.target.value !== '' ? e.target.value / 100 : null)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label="Planned Shipper RPM* (USD)"
                      type="number"
                      value={plannedShipperRpm}
                      onChange={e => setPlannedShipperRpm(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <Form.Generic.Datepicker
                      label="Planned Start Date*"
                      useFieldNameHandler={false}
                      value={utility.date.backendStringToNormalizedUtcDate(plannedStartDate)}
                      onChange={value => setPlannedStartDate(utility.date.transformApiDate(value))}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <Form.Generic.Datepicker
                      label="Planned End Date*"
                      useFieldNameHandler={false}
                      value={utility.date.backendStringToNormalizedUtcDate(plannedEndDate)}
                      onChange={value => setPlannedEndDate(utility.date.transformApiDate(value))}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label="Planned Leaf Tender Acceptance* (%)"
                      type="number"
                      value={plannedLeafTenderAcceptance != null ? plannedLeafTenderAcceptance * 100 : ''}
                      onChange={e =>
                        setPlannedLeafTenderAcceptance(e.target.value !== '' ? e.target.value / 100 : null)
                      }
                      fullWidth
                    />
                  </ListItem>
                </Overview>
              </PageContentLayout>
            </Grid>
            <Grid item xs={4}>
              <PageContentLayout hasSubheader withoutSpacing fullHeight={false}>
                <Overview title="Contract Types" titleBgColor="yellow">
                  <SubtitleWrapper>
                    <Typography variant="h6">Maximum Capacity by Contract Type (%)</Typography>
                  </SubtitleWrapper>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.SPOT} />}
                      type="number"
                      value={maxCapacityPctSpot != null ? maxCapacityPctSpot * 100 : ''}
                      onChange={e => setMaxCapacityPctSpot(e.target.value !== '' ? e.target.value / 100 : null)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.UNCOMMITTED} />}
                      type="number"
                      value={maxCapacityPctRg != null ? maxCapacityPctRg * 100 : ''}
                      onChange={e => setMaxCapacityPctRg(e.target.value !== '' ? e.target.value / 100 : null)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.FLEET} />}
                      type="number"
                      value={maxCapacityPctFleet != null ? maxCapacityPctFleet * 100 : ''}
                      onChange={e => setMaxCapacityPctFleet(e.target.value !== '' ? e.target.value / 100 : null)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.WEEK_OF} />}
                      type="number"
                      value={maxCapacityPctFlexWeekly != null ? maxCapacityPctFlexWeekly * 100 : ''}
                      onChange={e => setMaxCapacityPctFlexWeekly(e.target.value !== '' ? e.target.value / 100 : null)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.NETWORK} />}
                      type="number"
                      value={maxCapacityPctNetworkMove != null ? maxCapacityPctNetworkMove * 100 : ''}
                      onChange={e => setMaxCapacityPctNetworkMove(e.target.value !== '' ? e.target.value / 100 : null)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.DAY_OF} />}
                      type="number"
                      value={maxCapacityPctFlexDayOf != null ? maxCapacityPctFlexDayOf * 100 : ''}
                      onChange={e => setMaxCapacityPctFlexDayOf(e.target.value !== '' ? e.target.value / 100 : null)}
                      fullWidth
                    />
                  </ListItem>
                  <SubtitleWrapper>
                    <Typography variant="h6">LSP Target Linehaul RPM (USD) </Typography>
                  </SubtitleWrapper>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.SPOT} />}
                      type="number"
                      value={lspTargetLinehaulRpmSpot}
                      onChange={e => setLspTargetLinehaulRpmSpot(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.UNCOMMITTED} />}
                      type="number"
                      value={lspTargetLinehaulRpmRg}
                      onChange={e => setLspTargetLinehaulRpmRg(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.FLEET} />}
                      type="number"
                      value={lspTargetLinehaulRpmFleet}
                      onChange={e => setLspTargetLinehaulRpmFleet(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.WEEK_OF} />}
                      type="number"
                      value={lspTargetLinehaulRpmFlexWeekly}
                      onChange={e => setLspTargetLinehaulRpmFlexWeekly(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.NETWORK} />}
                      type="number"
                      value={lspTargetLinehaulRpmNetworkMove}
                      onChange={e => setLspTargetLinehaulRpmNetworkMove(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.DAY_OF} />}
                      type="number"
                      value={lspTargetLinehaulRpmFlexDayOf}
                      onChange={e => setLspTargetLinehaulRpmFlexDayOf(e.target.value)}
                      fullWidth
                    />
                  </ListItem>
                  <SubtitleWrapper>
                    <Typography variant="h6">Implementation Week (Auto-Generated)</Typography>
                  </SubtitleWrapper>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.SPOT} />}
                      value={plannedWeeklyVolume && maxCapacityPctSpot ? 0 : '-'}
                      fullWidth
                      disabled
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.UNCOMMITTED} />}
                      value={plannedWeeklyVolume && maxCapacityPctRg ? 0 : '-'}
                      fullWidth
                      disabled
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.FLEET} />}
                      value={plannedWeeklyVolume && maxCapacityPctFleet ? 4 : '-'}
                      fullWidth
                      disabled
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.WEEK_OF} />}
                      value={
                        !maxCapacityPctFlexWeekly
                          ? '-'
                          : plannedWeeklyVolume >= 25
                          ? 2
                          : plannedWeeklyVolume >= 10
                          ? 4
                          : plannedWeeklyVolume >= 4
                          ? 6
                          : '-'
                      }
                      fullWidth
                      disabled
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.NETWORK} />}
                      value={
                        !maxCapacityPctNetworkMove
                          ? '-'
                          : plannedWeeklyVolume >= 25
                          ? 4
                          : plannedWeeklyVolume >= 10
                          ? 6
                          : plannedWeeklyVolume >= 4
                          ? 8
                          : '-'
                      }
                      fullWidth
                      disabled
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <TextField
                      label={<ContractType type={CONTRACT_TYPE.DAY_OF} />}
                      value={
                        !maxCapacityPctFlexDayOf
                          ? '-'
                          : plannedWeeklyVolume >= 25
                          ? 6
                          : plannedWeeklyVolume >= 10
                          ? 8
                          : plannedWeeklyVolume >= 4
                          ? 10
                          : '-'
                      }
                      fullWidth
                      disabled
                    />
                  </ListItem>
                </Overview>
              </PageContentLayout>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '12px 16px',
                position: 'fixed',
                bottom: '0',
                left: '60px',
                width: 'calc(100% - 60px)',
                borderTop: '1px solid #ccc',
                zIndex: '1',
              }}
            >
              <p>* Fields are required</p>
              <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end', margin: '4px' }}>
                <Button variant="outlined" color="secondary" onClick={() => setEditOpsPlan(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSaveEditedOpsPlan}>
                  Save
                </Button>
              </Stack>
            </Box>
          </>
        )}
      </SpacedContainer>
    </PageContainer>
  );
};

export default OpsPlansDetailsEdit;
