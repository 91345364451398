/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Box, Typography, Divider, TextField, IconButton, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Form } from '@leaf/components';
import { debounce } from 'lodash';
import { useGQLClientWithAuth } from 'hooks/useGQL';
import { OverlayLoader } from 'contracts/shared/OverlayLoader';
import CircularProgress from '@mui/material/CircularProgress';
import { isLikeUUID } from 'utility/uuid';
import { FlexBox } from './viewHelpers';
import { searchLane, getLaneById } from '../domain/contractModel';
import { LaneOption } from './LaneOption';

export const LegFields = ({
  index,
  deadHeadMiles = 0,
  defaultLaneOptions = [],
  deliveryPickupOptions = [],
  control,
  field,
  errors,
  clearErrors,
  remove,
  setMapData,
  mapData,
  setValue,
  newLanes,
  liveLanes,
  editMode,
  mapRouteLegs,
  buyerId,
}) => {
  const [variables, setVariables] = useState(null);
  const [laneOptions, setLaneOptions] = useState(defaultLaneOptions);
  const [selectedLane, setSelectedLane] = useState(null);
  const [legToRemove, setLegToRemove] = useState(-1);
  const fetchRequired = useRef(false);
  const firstRender = useRef(true);
  const [loadingLanes, setLoadingLanes] = useState(false);

  const [secureClient, loading] = useGQLClientWithAuth();

  const queryBUYERID = buyerId ? [{ shipper: { id: { _eq: buyerId } } }] : [];

  const constructSearchVariable = debounce(value => {
    const operatorOR = [];
    const operatorAND = [];
    const searchTermValues = value.split(' ');

    if (searchTermValues.length === 1 && isLikeUUID(searchTermValues[0])) {
      operatorOR.push({ id: { _eq: `${searchTermValues[0]}` } });
    } else {
      searchTermValues.forEach(term => {
        const originName = { origin_name: { _ilike: `%${term}%` } };
        const destinationName = { destination_name: { _ilike: `%${term}%` } };
        const shipperName = { shipper: { name: { _ilike: `%${term}%` } } };
        operatorOR.push(originName, destinationName, shipperName);
        operatorAND.push(originName, destinationName, shipperName);
      });
    }

    if (operatorAND.length >= 1) {
      setVariables({ where: { _or: operatorOR, _and: [{ _or: operatorAND }, { _and: queryBUYERID }] } });
    } else {
      setVariables({ where: { _or: operatorOR, _and: queryBUYERID } });
    }
  }, 250);

  const removeByTeaHexFormat = h3Cells => {
    const BYTEA_HEX_FORMAT = '\\x';
    return h3Cells.map(cell => cell.replace(BYTEA_HEX_FORMAT, ''));
  };

  const addLegToMap = React.useCallback(
    lane => {
      setMapData([...mapData, lane]);
    },
    [mapData, setMapData],
  );

  const setDataFields = useCallback(
    lane => {
      liveLanes.current[index] = lane;
      const laneDetail = { ...lane };
      laneDetail.originH3Cells = removeByTeaHexFormat(lane.originH3Cells);
      laneDetail.destinationH3Cells = removeByTeaHexFormat(lane.destinationH3Cells);
      newLanes.current[index] = laneDetail;
      setValue(`legs.${index}.laneSelector`, lane);
      setValue(`legs.${index}.pickupType`, laneDetail.pickupType);
      setValue(`legs.${index}.pickupStops`, laneDetail.pickupStops);

      setValue(`legs.${index}.deliveryType`, laneDetail.deliveryType);
      setValue(`legs.${index}.deliveryStops`, laneDetail.deliveryStops);
    },
    [index, liveLanes, newLanes, setValue],
  );

  const updateLaneDetails = React.useCallback(
    lane => {
      setDataFields(lane);
      clearErrors([`legs.${index}.pickupType`, `legs.${index}.deliveryType`]);
      fetchRequired.current = false;
    },
    [index, setDataFields, clearErrors],
  );

  const removeLeg = () => {
    liveLanes.current.splice(index, 1);
    newLanes.current.splice(index, 1);
    setMapData([]);
    setLegToRemove(index);
  };

  const handleLaneChange = data => {
    if (index === 0) {
      setMapData([]);
    }
    if (data === null) {
      setValue(`legs.${index}.pickupType`, null);
      setValue(`legs.${index}.pickupStops`, '');
      setValue(`legs.${index}.deliveryType`, null);
      setValue(`legs.${index}.deliveryStops`, '');
      liveLanes.current.splice(index, 1);
      newLanes.current.splice(index, 1);
    } else {
      fetchRequired.current = true;
      setSelectedLane({ index, data });
    }
  };

  useEffect(() => {
    if (editMode && firstRender.current) {
      setDataFields(field);
      setMapData(mapRouteLegs);
      firstRender.current = false;
    }
  }, [editMode, setDataFields, field, setMapData, mapRouteLegs]);

  useEffect(() => {
    // Search lanes as the user types
    if (secureClient && variables !== null) {
      searchLane(secureClient, variables).then(res => {
        setLaneOptions(res.lane);
        setLoadingLanes(false);
      });
    }
  }, [secureClient, variables]);

  useEffect(() => {
    // Fetch lane details when user select a lane
    if (selectedLane && selectedLane.data && secureClient && fetchRequired.current) {
      getLaneById(secureClient, { where: { id: { _eq: selectedLane.data.id } } }).then(res => {
        updateLaneDetails(res.lane[0]);
        addLegToMap(res.lane[0]);
      });
    }
  }, [selectedLane, secureClient, updateLaneDetails, addLegToMap]);

  useEffect(() => {
    if (legToRemove !== -1) {
      remove(legToRemove);
      setMapData(liveLanes.current);
    }
  }, [legToRemove, remove, liveLanes, setMapData]);

  if (loading) {
    return null;
  }

  return (
    <Box>
      <OverlayLoader open={fetchRequired.current} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mb: 2 }}>
        <Typography variant="h5">Leg {index + 1}</Typography>
        <IconButton aria-label="delete" size="small" onClick={removeLeg}>
          <DeleteIcon />
        </IconButton>
      </Stack>

      <Controller
        name={`legs.${index}.laneSelector`}
        defaultValue={field.laneSelector}
        control={control}
        rules={{ required: true }}
        loading={loadingLanes}
        render={({ value, onChange }) => (
          <Form.Generic.Autocomplete
            value={value}
            options={laneOptions}
            isOptionEqualToValue={(option, selected) => option.originName === selected.originName}
            getOptionLabel={option => `${option.originName} - ${option.destinationName} ID: ${option.laneId}`}
            filterOptions={x => x}
            onChange={(_, data) => {
              // handle lane selection
              onChange(data);
              handleLaneChange(data);
            }}
            onInputChange={(event, data) => {
              // handles the variables for the search
              if (event && event.type === 'change') {
                setLoadingLanes(true);
                constructSearchVariable(data);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Leg*"
                variant="outlined"
                error={errors.legs ? errors.legs[index].laneSelector : false}
                helperText={errors.legs && errors.legs[index].laneSelector ? 'Required field' : null}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingLanes ? <CircularProgress sx={{ mr: 4 }} size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => <LaneOption props={props} option={option} />}
          />
        )}
      />
      <Grid container spacing={2} sx={{ my: 1 }}>
        <Grid item xs>
          <Controller
            name={`legs.${index}.pickupType`}
            defaultValue={field.pickupType}
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={deliveryPickupOptions}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={option => option}
                onChange={(_, data) => {
                  newLanes.current[index].pickupType = data;
                  onChange(data);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Pickup Type*"
                    error={errors.legs ? errors.legs[index].pickupType : false}
                    helperText={errors.legs && errors.legs[index].pickupType ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name={`legs.${index}.pickupStops`}
            defaultValue={field.pickupStops}
            control={control}
            render={({ onChange, ...rest }) => (
              <TextField
                type="number"
                label="Pickup Stops"
                min="1"
                step="1"
                onChange={event => {
                  newLanes.current[index].pickupStops = event.target.value;
                  onChange(event);
                }}
                {...rest}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name={`legs.${index}.deliveryType`}
            defaultValue={field.deliveryType}
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={deliveryPickupOptions}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={option => option}
                onChange={(_, data) => {
                  newLanes.current[index].deliveryType = data;
                  onChange(data);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Delivery Type*"
                    error={errors.legs ? errors.legs[index].deliveryType : false}
                    helperText={errors.legs && errors.legs[index].deliveryType ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name={`legs.${index}.deliveryStops`}
            defaultValue={field.deliveryStops}
            control={control}
            render={({ onChange, ...rest }) => (
              <TextField
                type="number"
                label="Delivery Stops"
                min="1"
                step="1"
                onChange={event => {
                  newLanes.current[index].deliveryStops = event.target.value;
                  onChange(event);
                }}
                {...rest}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ my: 2 }}>
        Loaded Miles: {newLanes.current[index] !== undefined ? newLanes.current[index].leafMiles : 0}
      </Typography>
      <FlexBox sx={{ py: 2 }}>
        <Divider sx={{ flex: 1, marginBottom: '7px;' }} />
        <Typography>{deadHeadMiles} mi - Deadhead </Typography>
        <Divider sx={{ flex: 1, marginBottom: '7px;' }} />
      </FlexBox>
    </Box>
  );
};
