import React from 'react';
import styled from '@emotion/styled';
import { RouteSchedule } from '@leaf/components';
import { mapDataToRouteSchedule } from '../details/domain/contractDetailsModel';
import RouteCardBody from './RouteCardBody';

const RouteCard = styled.div`
  min-height: 75px;
  width: 100%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
`;

const RouteCardContent = styled.div`
  min-height: 80px;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2)}`};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
`;

export default ({ route, closeRouteDetails, backButton }) => (
  <RouteCard>
    <RouteCardContent>
      <RouteCardBody route={route} closeRouteDetails={closeRouteDetails} backButton={backButton} />
    </RouteCardContent>
    <RouteSchedule route={mapDataToRouteSchedule(route.legs)} shouldFitContainerWidth />
  </RouteCard>
);
