import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Snackbar, FullStory } from '@leaf/components';

import Theme from 'theme';
import Routes from 'navigation';
import StateProvider from 'state/StateProvider';

export default () => (
  <React.StrictMode>
    <FullStory.Component />

    <Theme>
      <StateProvider>
        <Snackbar>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Snackbar>
      </StateProvider>
    </Theme>
  </React.StrictMode>
);
