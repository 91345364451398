/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid, Box, Button, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Form, PolygonMap } from '@leaf/components';
import { disableEnterKey } from 'utility/disableEnterKey';
import { useStateMachine } from 'little-state-machine';
import { useSnackbar } from 'notistack';
import { OverlayLoader } from '../../shared/OverlayLoader';
import { FormActions } from '../partials/viewHelpers';
import { LegFields } from '../partials/LegFields';
import { legInitialValues, updateRouteCount } from '../domain/stateMachine';

import {
  saveRoutes,
  attachRouteToContract,
  saveLanes,
  isLaneEdited,
  cleanLaneDataforAPI,
} from '../domain/contractModel';

// HARDCODE:
const ROUTE_MOVE_TYPE = ['ONE_WAY', 'ROUND_TRIP', 'CIRCUIT', 'CONTINUOUS_MOVE', 'SHORTY'];
const DELIVERY_PICKUP_TYPES = ['ANY', 'LIVE', 'DROP', 'UNKNOWN'];

export function CreateRoute({ dismiss, editingRoute }) {
  const { getState } = useStateMachine();
  const [mapData, setMapData] = React.useState([]);
  const liveLanes = React.useRef([]);
  const newLanes = React.useRef([]);
  const legBoxRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { newContract: contract, LANE_OPTIONS, ROUTE_COUNT } = getState();

  let existingTripType = null;
  let legsToEdit = [];
  let editMode = false;
  const mapRouteLegs = [];

  if (editingRoute) {
    existingTripType = editingRoute.tripType.toUpperCase().replace(' ', '_');

    legsToEdit = editingRoute?.editLegs.map(({ lane }) => {
      mapRouteLegs.push(lane);
      const withLaneSelector = {
        ...lane,
        laneSelector: lane,
      };

      return withLaneSelector;
    });

    editMode = true;
  }

  const availableLegs = legsToEdit.length > 0 ? legsToEdit : legInitialValues;

  const {
    handleSubmit,
    control,
    register,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      tripType: existingTripType,
      legs: availableLegs,
    },
  });

  const { fields, remove, append } = useFieldArray({
    name: 'legs',
    control,
    defaultLaneValues: availableLegs,
    focusAppend: true,
  });

  const attachOneWayLaneToContract = async data => {
    const isPrimary = (editingRoute && editingRoute.isPrimary) || ROUTE_COUNT === 0;
    const res = await attachRouteToContract(data.contractId, liveLanes.current[0].laneId, { isPrimary })
      .then(response => {
        updateRouteCount(ROUTE_COUNT + 1);
        return response;
      })
      .catch(error => {
        enqueueSnackbar(`attachOneWayLaneToContract -- ${error}`);
      });

    return res !== undefined;
  };

  const attachModifyOneWayLaneToContract = async data => {
    const apiData = cleanLaneDataforAPI(newLanes.current[0]);
    let contractRes;
    const laneRes = await saveLanes(apiData).catch(error => {
      enqueueSnackbar(`saveLanes -- ${error}`);
    });

    if (laneRes) {
      const isPrimary = (editingRoute && editingRoute.isPrimary) || ROUTE_COUNT === 0;
      contractRes = await attachRouteToContract(data.contractId, laneRes.id, { isPrimary })
        .then(response => {
          updateRouteCount(ROUTE_COUNT + 1);
          return response;
        })
        .catch(error => {
          enqueueSnackbar(`attachRouteToContract -- ${error}`);
        });
    }

    return contractRes !== undefined;
  };

  const attachMultipleLanesToContract = async data => {
    const laneIds = [];
    const { tripType, contractId } = data;
    let attachRouteRes;

    for (let i = 0; i < fields.length; i += 1) {
      if (isLaneEdited(liveLanes.current[i], newLanes.current[i])) {
        // Case 2.A: Lane was changed -- need to save lane and capture ID
        const apiData = cleanLaneDataforAPI(newLanes.current[i]);
        const laneRes = await saveLanes(apiData).catch(error => {
          enqueueSnackbar(`saveLanes. ${error}`);
        });

        if (laneRes) {
          laneIds.push(laneRes.id);
        }
      } else {
        // Case 2.B: Lane unchanged, just capture ID
        laneIds.push(newLanes.current[i].laneId);
      }
    }

    //  Case 2 - Step 1: Save all routes ids
    const routeRes = await saveRoutes({ tripType, lanes: laneIds }).catch(error => {
      enqueueSnackbar(`Saving routes failded. ${error}`);
    });

    // Case 2 - Step 2: Attach routes to contract
    if (routeRes) {
      const isPrimary = (editingRoute && editingRoute.isPrimary) || ROUTE_COUNT === 0;
      attachRouteRes = await attachRouteToContract(contractId, routeRes.id, { isPrimary })
        .then(response => {
          updateRouteCount(ROUTE_COUNT + 1);
          return response;
        })
        .catch(error => {
          enqueueSnackbar(`Attaching routes to contract failded. ${error}`);
        });
    }

    // SUCCESS!
    return attachRouteRes !== undefined;
  };

  const handleFormSubmit = async data => {
    let success = false;

    // Case 1.A -- select 1 lane and no changes to the lane
    if (fields.length === 1 && !isLaneEdited(liveLanes.current[0], data.legs[0])) {
      success = await attachOneWayLaneToContract(data);
    }

    // Case 1.B -- select 1 lane and user changes lane details
    if (fields.length === 1 && isLaneEdited(liveLanes.current[0], data.legs[0])) {
      success = await attachModifyOneWayLaneToContract(data);
    }

    // Case 2 -- User select more than 1 lane & can change any of the lanes.
    if (fields.length > 1) {
      success = await attachMultipleLanesToContract(data);
    }

    if (success) {
      enqueueSnackbar(`Successfuly attached Route(s) to contract!`, { variant: 'success' });
      sessionStorage.clear();
      dismiss(null, true);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={e => disableEnterKey(e)}>
      <Grid container sx={{ mt: -2 }}>
        <Grid item sx={{ position: 'relative', minWidth: '620px' }}>
          <OverlayLoader active={isSubmitting} />
          {/* <MileSummary /> */}
          <input type="hidden" name="contractId" defaultValue={contract.id} ref={register()} />

          <Box sx={{ p: 2, backgroundColor: '#F5F5F5' }}>
            <Controller
              name="tripType"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <Form.Generic.Autocomplete
                  value={value}
                  options={ROUTE_MOVE_TYPE}
                  isOptionEqualToValue={(option, selected) => option === selected}
                  getOptionLabel={option => option}
                  onChange={(_, data) => onChange(data)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Route Move Type*"
                      error={!!errors.tripType}
                      helperText={errors.tripType ? 'Required field' : null}
                    />
                  )}
                />
              )}
            />
            <Button
              onClick={() => {
                append(legInitialValues, true);
                setTimeout(() => {
                  legBoxRef.current.scroll({ top: 900, behavior: 'smooth' });
                }, 300);
              }}
              startIcon={<AddCircleIcon />}
              sx={{ my: 2 }}
            >
              Add leg
            </Button>
            <Box ref={legBoxRef} sx={{ overflowX: 'scroll', height: '320px' }}>
              {fields.map((field, index) => (
                <LegFields
                  key={field.id}
                  {...{
                    index,
                    control,
                    field,
                    errors,
                    clearErrors,
                    remove,
                    deliveryPickupOptions: DELIVERY_PICKUP_TYPES,
                    defaultLaneOptions: LANE_OPTIONS,
                    setMapData,
                    mapData,
                    setValue,
                    newLanes,
                    liveLanes,
                    editMode,
                    mapRouteLegs,
                    buyerId: contract.buyerId,
                  }}
                />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          {/*  */}
          {/* CordinateType = [float, float] */}
          {/* NOTE: data shape
            [
              {
                destinationGeo: {
                  type: string,
                  cordinates: [CordinateType]
                },
                originGeo: {
                  type: string,
                  cordinates: [CordinateType]
                },
                pathGeo: {
                  type: string,
                  crs: {
                    type: string,
                    properties: {
                      name: string
                    }
                  }
                  cordinates: [CordinateType]
                }
              }
            ]

          */}
          <PolygonMap data={mapData} />
        </Grid>
      </Grid>

      <FormActions sx={{ p: 2, borderTop: '1px solid #e5e5e5' }}>
        <Button
          type="button"
          size="large"
          variant="outlined"
          onClick={() => {
            dismiss(null, false);
          }}
        >
          Cancel
        </Button>
        <Button type="submit" size="large" variant="contained">
          Save
        </Button>
      </FormActions>
    </form>
  );
}
