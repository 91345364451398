import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { Page, ServerSideTable, useTableCustomFilters, PageContentLayout, PageContainer } from '@leaf/components';

import { StateContext } from 'state/StateProvider';
import { SET_ADAPT_NETWORK_MOVES_STATE, RESET_ADAPT_NETWORK_MOVES_STATE } from 'state/stateReducer';

import AdaptNetworkMove from 'domain/adapt/AdaptNetworkMove';

import Beta from 'components/Beta';
import { getColumns } from './adaptNetworkMoveOverviewColumns';

const getOptions = (tableState, history) => ({
  filter: true,
  onRowClick: rowData => {
    const id = rowData[2];

    history.push(`/adapt/network-moves/${id}`);
  },
  sortOrder: {
    name: tableState.sort.name,
    direction: tableState.sort.order,
  },
});

const LOW_LEGS = { 0: '0 Low volume legs', 1: '1 Low volume legs', 2: '2 Low volume legs', 3: '3 Low volume legs' };
const OUTLIER_RPM = { 0: '0 outlier rpms', 1: '1 outlier rpm', 2: '2 outlier rpms', 3: '3 outlier rpms' };

const AdaptNetworkMoveOverview = ({ history }) => {
  const { CancelToken } = axios;
  const apiCancelation = CancelToken.source();
  const [filters, setFilters] = React.useState({
    balanceType: [],
    moveType: [],
    networkType: [],
    status: [],
    owners: [],
    equipmentClass: [],
  });

  React.useEffect(() => {
    AdaptNetworkMove.getFilters().then(setFilters);
  }, []);

  const {
    state: {
      tables: { adaptNetworkMoves: tableState },
    },
    dispatch,
  } = React.useContext(StateContext);

  const tableFilters = useMemo(
    () => [
      {
        inputType: 'autocomplete',
        columnName: 'balanceType',
        filterOptionsGetter: () => Promise.resolve(filters.balanceType),
      },
      {
        inputType: 'autocomplete',
        columnName: 'moveType',
        filterOptionsGetter: () => Promise.resolve(filters.moveType),
      },
      {
        inputType: 'autocomplete',
        columnName: 'networkType',
        filterOptionsGetter: () => Promise.resolve(filters.networkType),
      },
      {
        inputType: 'autocomplete',
        columnName: 'status',
        filterOptionsGetter: () => Promise.resolve(filters.status),
      },
      {
        inputType: 'autocomplete',
        columnName: 'owner',
        filterOptionsGetter: () => Promise.resolve(filters.owners),
      },
      {
        inputType: 'autocomplete',
        columnName: 'networkOwner',
        filterOptionsGetter: () => Promise.resolve(filters.owners),
      },
      {
        inputType: 'autocomplete',
        columnName: 'equipmentClass',
        filterOptionsGetter: () => Promise.resolve(filters.equipmentClass),
      },
      {
        inputType: 'min-max',
        columnName: 'totalMiles',
      },
      {
        inputType: 'text',
        columnName: 'benefit',
      },
      {
        inputType: 'autocomplete',
        columnName: 'lowLeg',
        filterOptionsGetter: () =>
          Promise.resolve(
            Object.keys(LOW_LEGS).map(key => ({
              label: LOW_LEGS[key],
              value: key,
            })),
          ),
      },
      {
        inputType: 'autocomplete',
        columnName: 'outlierRPM',
        filterOptionsGetter: () =>
          Promise.resolve(
            Object.keys(OUTLIER_RPM).map(key => ({
              label: OUTLIER_RPM[key],
              value: key,
            })),
          ),
      },
      {
        inputType: 'autocomplete',
        columnName: 'ownerExcluding',
        filterOptionsGetter: () => Promise.resolve(filters.owners),
      },
    ],
    [filters],
  );
  const [filterOptions] = useTableCustomFilters(tableFilters, true);

  React.useEffect(() => {
    const unregister = history.listen(location => {
      const path = location.pathname.startsWith('/adapt/network-moves');
      if (!path) {
        dispatch({ type: RESET_ADAPT_NETWORK_MOVES_STATE });
      }
      return () => unregister();
    });
  });

  React.useEffect(
    () => () => {
      apiCancelation.cancel('Canceled request');
    },
    [],
  );

  const handleChange = setTableState =>
    dispatch({ type: SET_ADAPT_NETWORK_MOVES_STATE, payload: setTableState(tableState) });

  const shipperIds = filters.owners.reduce((acc, owner) => {
    acc[owner.value] = owner.label;
    return acc;
  }, {});

  return (
    <Page title="Adapt Network Moves" ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(tableState, history)}
            columns={getColumns(tableState, filterOptions, shipperIds)}
            fn={AdaptNetworkMove.getAll}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
};

export default withRouter(AdaptNetworkMoveOverview);
