import axios from 'axios';

import Shipper from 'domain/Shipper';
import FuelPriceIndex from './FuelPriceIndex';

export const isFirstStepZeroValues = {
  YES: 'Yes',
  NO: 'No',
};

const mapIsFirstStepZero = isFirstStepZero => (isFirstStepZero ? isFirstStepZeroValues.YES : isFirstStepZeroValues.NO);

export default class Sfsc {
  constructor({ shipper, isFirstStepZero, fuelPriceIndex, ...response }) {
    Object.assign(this, {
      ...response,
      shipper: shipper && new Shipper(shipper),
      isFirstStepZero: mapIsFirstStepZero(isFirstStepZero),
      fuelPriceIndex: fuelPriceIndex && new FuelPriceIndex(fuelPriceIndex),
    });

    this.view = {
      id: this.id,
      fuelPriceIndexName: this.fuelPriceIndex?.name,
    };
  }

  static getAll = async () =>
    axios.get('jupiter/admin/shipper-fuel-surcharge-schedules').then(({ sfscs }) => sfscs.map(sfsc => new Sfsc(sfsc)));
}
