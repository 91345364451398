import React from 'react';
import { utility, ContractType, NewTabLink } from '@leaf/components';

export const tenderAlocationColumns = [
  {
    label: 'Rank',
    name: 'rank',
    options: { sort: false },
  },
  {
    label: 'Contract Type',
    name: 'contractType',
    options: {
      sort: false,
      customBodyRender: type => <ContractType type={type} />,
    },
  },
  {
    label: '% Fulfilled (this week)',
    name: 'fulfilledWeekly',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: '% Fulfilled (lifetime)',
    name: 'fulfilledLifetime',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'LSP',
    name: 'lsp',
    options: {
      sort: false,
    },
  },
  {
    label: 'Linehaul RPM',
    name: 'linehaulRPM',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Total Cost',
    name: 'totalCost',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Fleet',
    name: 'isFleet',
    options: {
      sort: false,
      customBodyRender: isFleet => (isFleet ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Network Move',
    name: 'isNetworkMove',
    options: {
      sort: false,
      customBodyRender: isNetworkMove => (isNetworkMove ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Link To Contract',
    name: 'contractId',
    options: {
      filter: false,
      sort: false,
      display: true,
      customBodyRender: id => <NewTabLink url={`/contracts/${id}`} />,
    },
  },
];

export const columns = [
  {
    label: 'Invoice date',
    name: 'invoiceDate',
    options: { sort: false },
  },
  {
    label: 'Invoice Number',
    name: 'invoiceNumber',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Total Invouce Amount',
    name: 'invoiceTotal',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Linehaul',
    name: 'linehaul',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Fuel',
    name: 'fuel',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Accesorials (total)',
    name: 'accessorialTotal',
    options: {
      sort: false,
      ...utility.table.rightAlignColumnProps(),
    },
  },
  {
    label: 'Detention',
    name: 'detentions',
    options: { sort: false },
  },
  {
    label: 'TONU',
    name: 'tonu',
    options: { sort: false },
  },
];

export const trackingEventColumns = [
  {
    label: 'Lat / Lon',
    name: 'latlong',
    options: { sort: false },
  },
  {
    label: 'Event Time',
    name: 'eventTime',
    options: { sort: false },
  },
  {
    label: 'Address',
    name: 'address',
    options: { sort: false },
  },
  {
    label: 'Tracking Status',
    name: 'trackingStatus',
    options: {
      sort: false,
    },
  },
  {
    label: 'Description',
    name: 'description',
    options: {
      sort: false,
    },
  },
];
