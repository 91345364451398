import React from 'react';
import { Dialog, Typography, IconButton, Button } from '@mui/material';
import { Close, Publish, Clear, CloudDownload } from '@mui/icons-material';
import { UploadButton, ImportSummaryTable } from '@leaf/components';
import { useSnackbar } from 'notistack';
import Contract from 'domain/contract/Contract';
import {
  DialogTitleContainer,
  DialogContentContainer,
  DialogContentPanel,
  UploadButtonContainer,
  FileUploadedContainer,
  IconWrapper,
  DialogContentTitle,
  DownloadTemplateContainer,
  DialogActionsContainer,
  IconButtonDownload,
} from './ImportContractDialog.styled';

export default ({ open, handleCloseDialog, title }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedFile, setUploadedFile] = React.useState();
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [failedDataList, setFailedDataList] = React.useState();
  const [isImporting, setIsImporting] = React.useState(false);
  const [importProgress, setImportProgress] = React.useState(0);
  const [openSummaryDialog, setOpenSummaryDialog] = React.useState(false);

  const handleDownload = () => {
    Contract.downloadContracts([]);
  };

  const handleUploadFile = file => {
    setUploadedFile(file);
  };

  const handleCloseImportDialog = () => {
    setUploadedFile(null);
    handleCloseDialog();
  };

  const handleImportContracts = async () => {
    if (uploadedFile) {
      try {
        setIsDataLoading(true);
        setIsImporting(true);
        const data = await Contract.importContracts(uploadedFile, false, progress => {
          const { loaded, total } = progress;
          const percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setImportProgress(percent);
          }
        });
        if (data?.total === 0) {
          enqueueSnackbar('Imported file is empty, please add data.', { variant: 'error' });
        } else if (data?.failed === 0 && data.records) {
          if (data.records.find(({ warnings }) => warnings.length > 0)) {
            setImportProgress(100);
            setFailedDataList(data);
            setOpenSummaryDialog(true);
          } else {
            setImportProgress(100);
            setUploadedFile(null);
            handleCloseImportDialog();
            enqueueSnackbar('Contracts successfully uploaded.', { variant: 'success' });
          }
        } else if (data?.failed > 0) {
          setImportProgress(100);
          setFailedDataList(data);
          setOpenSummaryDialog(true);
        }
      } catch (error) {
        enqueueSnackbar(error);
        setUploadedFile(null);
      } finally {
        setIsDataLoading(false);
        setIsImporting(false);
        setTimeout(() => {
          setImportProgress(0);
        }, 1000);
      }
    }
  };

  const renderUploadSuccessfully = () => (
    <FileUploadedContainer>
      <IconWrapper />
      <Typography variant="h6">{uploadedFile?.name ? uploadedFile?.name : 'undefined'}</Typography>
      <Typography>{uploadedFile?.size ? `${uploadedFile?.size}b` : 'undefined'}</Typography>
      <IconButton onClick={() => handleUploadFile(null)} size="large">
        <Clear color="disabled" />
      </IconButton>
    </FileUploadedContainer>
  );

  const handleCloseSummaryDialog = () => {
    setOpenSummaryDialog(false);
    setFailedDataList(null);
  };

  const handleSubmitWarningData = async () => {
    const data = await Contract.importContracts(uploadedFile, true);
    if (data?.total > 0 && data?.succeeded) {
      setOpenSummaryDialog(false);
      setFailedDataList(null);
      setUploadedFile(null);
      handleCloseImportDialog();
      enqueueSnackbar('Contracts successfully uploaded.', { variant: 'success' });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleCloseImportDialog} open={open}>
      <DialogTitleContainer>
        <Typography variant="body2">{title}</Typography>
        <IconButton onClick={handleCloseImportDialog}>
          <Close />
        </IconButton>
      </DialogTitleContainer>
      <DialogContentContainer dividers>
        <DialogContentPanel>
          <DialogContentTitle>
            <Typography variant="body2">Import EXCEL file</Typography>
            <DownloadTemplateContainer onClick={handleDownload}>
              <IconButtonDownload>
                <CloudDownload />
              </IconButtonDownload>
              <Typography variant="body2">Template</Typography>
            </DownloadTemplateContainer>
          </DialogContentTitle>
          <Typography variant="body1">Upload Guide</Typography>
          <Typography>Maximum Rows Permitted: 500</Typography>
        </DialogContentPanel>
        <UploadButtonContainer>
          {uploadedFile ? (
            renderUploadSuccessfully()
          ) : (
            <UploadButton
              variant="contained"
              color="primary"
              icon={<Publish />}
              label="CHOOSE A FILE"
              handleUpload={handleUploadFile}
              fileTypes={['.xlsx']}
            />
          )}
        </UploadButtonContainer>
      </DialogContentContainer>
      <DialogActionsContainer>
        <Button variant="outlined" onClick={handleCloseImportDialog}>
          CANCEL
        </Button>
        <Button variant="contained" color="primary" onClick={handleImportContracts} disabled={!uploadedFile}>
          IMPORT
        </Button>
      </DialogActionsContainer>
      {openSummaryDialog &&
        failedDataList?.records.find(({ errors, warnings }) => errors.length > 0 || warnings.length > 0) && (
          <ImportSummaryTable
            importSummary={failedDataList}
            onClose={handleCloseSummaryDialog}
            importProgress={importProgress}
            isLoading={isImporting}
            submitData={handleSubmitWarningData}
          />
        )}
    </Dialog>
  );
};
