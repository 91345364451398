import { utility } from '@leaf/components';
import { GQL_FUEL_PRICE_INDEX } from 'graphql/filter-queries/GQL_FUEL_PRICE_INDEX';
import { getCompanyFSCGQL } from './GQL_COMPANY_FSC';

const mapFscResponse = fuelSurchargeSchedules =>
  fuelSurchargeSchedules.map(fsc => ({
    ...fsc,
    increment: utility.numbers.formatNumberAsView(fsc.increment, 2, false),
    bucketSize: fsc.bucketSize,
    isFirstStepZero: fsc.isFirstStepZero ? 'Yes' : 'No',
    fuelPriceIndexName: fsc.fuelPriceIndex?.name,
  }));

export const getCompanyFSC = (getGQLClient, id) => {
  const GQL = getCompanyFSCGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapFscResponse(res.fuelSurchargeSchedule),
    })),
  );
};

export const getFuelPriceIndexFSC = getGQLClient =>
  getGQLClient().then(client =>
    client.request(GQL_FUEL_PRICE_INDEX).then(res => ({
      data: res.fuelPriceIndex,
    })),
  );
