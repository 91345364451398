/* eslint-disable consistent-return */
import React from 'react';
import { FormControl, Grid, CircularProgress, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormButtons from 'form/FormButtons';
import { useLoading, Form, LOCATION_TYPE_DISPLAY_NAMES } from '@leaf/components';

import Map, { transformCoordsToPointGeometryType } from 'components/Map';
import styled from '@emotion/styled';
import Location from 'domain/location/Location';
import { useSnackbar } from 'notistack';
import useGQL from 'hooks/useGQL';
import { getShippers, getExecutionLocationById } from '../domain/executionLocationModel';

const MapWrapper = styled.div`
  height: 300px;
`;

const companyCreateSchema = yup.object().shape({
  shipperId: yup.string().required('Required'),
  address: yup.object().required('Required'),
  businessName: yup.string().required('Required'),
  operatingHours: yup.string().nullable(),
  // contact
  contactName: yup.string().nullable(),
  contactEmail: yup.string().email().nullable(),
  contactPhone: yup.string().nullable(),
  // requirement
  accessRequirement: yup.string().nullable(),
  appointmentRequirement: yup.string().nullable(),
  trailerPoolStorageRequirement: yup.string().nullable(),
});

const { Input, ErrorMessage, Textarea, Select, Autocomplete } = Form.Library;

export default props => {
  const { onClose, open, ourHandleSubmit, title, selectedLocation } = props;
  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);
  const [locations, setLocations] = React.useState([]);
  const [locationToEdit, setLocationToEdit] = React.useState(null);
  const [mapData, setMapData] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);

  const getInitialFormData = () => {
    if (selectedLocation) return locationToEdit;
  };

  const { handleSubmit, control, errors, setValue, reset } = useForm({
    resolver: yupResolver(companyCreateSchema),
    defaultValues: getInitialFormData(),
  });

  const [getLocationToEdit, isLocationToEditLoading] = useLoading(async () => {
    if (selectedLocation) {
      return getExecutionLocationById(getGQLClient, selectedLocation)
        .then(res => {
          if (res && res.length === 1) {
            setLocationToEdit(res[0]);
          }
        })
        .catch(enqueueSnackbar);
    }
    return null;
  });

  const handleClose = () => {
    setMapData(null);
    onClose();
  };

  React.useEffect(() => {
    getLocationToEdit();
    getShippers(getGQLClient).then(setCompanies);
  }, []);

  React.useEffect(() => {
    if (locationToEdit) {
      reset(getInitialFormData());
    }
  }, [locationToEdit]);

  React.useEffect(() => {
    if (locationToEdit?.address?.latitude && locationToEdit?.address?.longitude)
      setMapData(
        transformCoordsToPointGeometryType(locationToEdit?.address?.longitude, locationToEdit?.address?.latitude),
      );
  }, [locationToEdit]);

  const handleInputChange = (event, value) => {
    if (value.length >= 3) {
      setIsLoading(true);
      Location.getLocation(value)
        .then(response => {
          setIsLoading(false);
          setLocations(response);
        })
        .catch(enqueueSnackbar);
    } else {
      setLocations([]);
    }
  };

  const handleOnChange = (event, data) => {
    if (data) {
      setValue('address', data, {
        shouldValidate: true,
      });
      setMapData(transformCoordsToPointGeometryType(data.latitude, data.longitude));
    } else {
      setValue('address', undefined, {
        shouldValidate: true,
      });
      setMapData(null);
    }
  };

  const getLocationLabel = loc =>
    loc ? `${loc.address ? loc.address : ''} ${loc.city} ${loc.state} ${loc.zipCode}` : '';

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="add-new-location" open={open}>
      <DialogTitle id="add-new-location-dialog">{title} location</DialogTitle>
      <DialogContent>
        {isLocationToEditLoading ? (
          <Box display="flex" justifyContent="center" m={2}>
            <CircularProgress />
          </Box>
        ) : (
          <form
            onSubmit={handleSubmit(data => {
              ourHandleSubmit(data);
            })}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div>Ships to / from</div>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    id="shipperId"
                    name="shipperId"
                    label="Shipper"
                    options={companies.map(company => ({
                      label: company.label,
                      id: company.value,
                    }))}
                    control={control}
                    error={errors.shipperId}
                    disabled={selectedLocation}
                  />
                  <ErrorMessage errors={errors} name="shipperId" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Input
                    id="businessName"
                    name="businessName"
                    label="Name (consignor/consignee)"
                    control={control}
                    error={errors.businessName}
                  />
                  <ErrorMessage errors={errors} name="businessName" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  name="address"
                  label="Address"
                  options={locations}
                  getOptionLabel={loc => getLocationLabel(loc)}
                  control={control}
                  loading={isLoading}
                  onInputChange={handleInputChange}
                  onChange={handleOnChange}
                  error={errors.address}
                  disabled={selectedLocation}
                />
                <ErrorMessage errors={errors} name="address" />
              </Grid>
              <Grid item xs={12}>
                <MapWrapper>
                  <Map data={mapData} markerPosition={mapData?.coordinates || []} hasMarker />
                </MapWrapper>
              </Grid>
              <Grid item xs={12}>
                <div>Shipper-internal info</div>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    id="type"
                    name="type"
                    label="Location type"
                    options={Object.keys(LOCATION_TYPE_DISPLAY_NAMES).map(key => ({
                      label: LOCATION_TYPE_DISPLAY_NAMES[key],
                      id: key,
                    }))}
                    control={control}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div>Contact</div>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input id="contactName" name="contactName" label="Contact name" control={control} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input id="contactEmail" name="contactEmail" label="Contact email" control={control} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input id="contactPhone" name="contactPhone" label="Contact phone" control={control} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div>Operations</div>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Textarea id="operatingHours" name="operatingHours" label="Operating hours" control={control} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Textarea
                    id="accessRequirement"
                    name="accessRequirement"
                    label="Access requirements"
                    control={control}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Textarea
                    id="appointmentRequirement"
                    name="appointmentRequirement"
                    label="Appointment requirements"
                    control={control}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Textarea
                    id="trailerPoolStorageRequirement"
                    name="trailerPoolStorageRequirement"
                    label="Trailer pool storage requirements"
                    control={control}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormButtons onCancelClick={handleClose} submitButton={selectedLocation ? 'Save' : undefined} />
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};
