import React from 'react';
import { TextField } from '@mui/material';

export default React.memo(({ formik, name, label, ...rest }) => (
  <TextField
    id={name}
    name={name}
    label={label}
    value={formik.values[name]}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
    fullWidth
    {...rest}
  />
));
