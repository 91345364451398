import React from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import leaflet from 'leaflet';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';

import { Page, PageContentLayout, PageContent, PageContainer } from '@leaf/components';

import Beta from 'components/Beta';

const Map = styled(LeafletMap)`
  height: 100%;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  position: relative;
`;

export default () => {
  const [shippers, setShippers] = React.useState([{ id: 1331, name: 'Albertsons' }]);
  const [lanes, setLanes] = React.useState(null);
  const [type, setType] = React.useState('path');
  const [isLoading, setLoading] = React.useState(false);
  const [showOrigins, setShowOrigins] = React.useState(false);
  const [showDestinations, setShowDestinations] = React.useState(false);
  const [markLaneStartEnd, setMarkLaneStartEnd] = React.useState(false);
  const [batchDate, setBatchDate] = React.useState(null);

  const mapRef = React.useRef();

  const { control, watch, setValue } = useForm({ defaultValues: { shipperSelect: shippers[0].id, batchDate } });

  React.useEffect(() => {
    axios.get('view/adapt/lanes/shippers').then(({ shippers: response }) => setShippers(response));
  }, []);

  const shipper = watch('shipperSelect');

  React.useEffect(() => {
    setLoading(true);
    setBatchDate(null);
    axios
      .get(`view/adapt/lanes/shippers/${shipper}/network`)
      .then(({ lanes: response }) => {
        setLanes(response);
        setBatchDate(response[0]?.batchDate);
      })
      .finally(() => setLoading(false));
  }, [shipper]);

  React.useEffect(() => {
    setValue('batchDate', batchDate);
  }, [batchDate]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if (map && lanes) {
      const geoLanes = [];
      const geoOrigins = [];
      const geoDestinations = [];
      const geoLanesStart = [];
      const geoLanesEnd = [];

      lanes.forEach(lane => {
        geoLanes.push({
          type: 'Feature',
          id: lane.id,
          name: `${lane.origin} - ${lane.destination}`,
          geometry: lane[type],
        });
        geoLanesStart.push({
          type: 'Feature',
          id: lane.id,
          name: `START: ${lane.origin} - ${lane.destination}`,
          geometry: {
            type: 'Point',
            coordinates: lane[type].coordinates[0],
          },
        });
        geoLanesEnd.push({
          type: 'Feature',
          id: lane.id,
          name: `END: ${lane.origin} - ${lane.destination}`,
          geometry: {
            type: 'Point',
            coordinates: lane[type].coordinates[lane[type].coordinates.length - 1],
          },
        });

        geoOrigins.push({
          type: 'Feature',
          id: lane.id,
          name: `${lane.origin}`,
          geometry: lane.originGeo,
        });

        geoDestinations.push({
          type: 'Feature',
          id: lane.id,
          name: `${lane.destination}`,
          geometry: lane.destinationGeo,
        });
      });

      if (markLaneStartEnd) {
        leaflet
          .geoJSON(
            {
              type: 'FeatureCollection',
              features: geoLanesStart,
            },
            {
              pointToLayer: (feature, latlng) =>
                leaflet.circleMarker(latlng, {
                  color: 'blue',
                  radius: 1,
                }),
            },
          )
          .addTo(map);
        leaflet
          .geoJSON(
            {
              type: 'FeatureCollection',
              features: geoLanesEnd,
            },
            {
              pointToLayer: (feature, latlng) =>
                leaflet.circleMarker(latlng, {
                  color: 'red',
                  radius: 1,
                }),
            },
          )
          .addTo(map);
      }

      leaflet
        .geoJSON(
          {
            type: 'FeatureCollection',
            features: geoLanes,
          },
          {
            style: () => ({ weight: 1.5, color: '#7BC67E' }),
            onEachFeature: (feature, layer) => {
              layer.bindTooltip(`${feature.name}`);

              layer.on('mouseover', () => {
                layer.setStyle({
                  color: 'red',
                });
                layer.bringToFront();
              });
              layer.on('mouseout', () => {
                layer.setStyle({
                  color: '#7BC67E',
                });
                layer.bringToBack();
              });
            },
          },
        )
        .addTo(map);

      const originGeoJSON = leaflet.geoJSON(
        {
          type: 'FeatureCollection',
          features: geoOrigins,
        },
        {
          style: () => ({ weight: 0, color: 'blue', fill: 'none', fillOpacity: 0.03 }),
          onEachFeature: (feature, layer) => {
            layer.bindTooltip(`${feature.name}`);

            layer.on('mouseover', () => {
              layer.setStyle({
                color: 'red',
              });
              layer.bringToFront();
            });
            layer.on('mouseout', () => {
              layer.setStyle({
                color: 'blue',
              });
              layer.bringToBack();
            });
          },
        },
      );

      if (showOrigins) {
        originGeoJSON.addTo(map);
      }

      const destinationGeoJSON = leaflet.geoJSON(
        {
          type: 'FeatureCollection',
          features: geoDestinations,
        },
        {
          style: () => ({ weight: 0, color: 'red', fill: 'none', fillOpacity: 0.03 }),
          onEachFeature: (feature, layer) => {
            layer.bindTooltip(`${feature.name}`);

            layer.on('mouseover', () => {
              layer.setStyle({
                color: 'red',
              });
              layer.bringToFront();
            });
            layer.on('mouseout', () => {
              layer.setStyle({
                color: 'red',
              });
              layer.bringToBack();
            });
          },
        },
      );

      if (showDestinations) {
        destinationGeoJSON.addTo(map);
      }

      return () =>
        map.eachLayer(layer => {
          if (layer.feature) {
            layer.remove();
          }
        });
    }
  }, [lanes, type, showOrigins, showDestinations, markLaneStartEnd]);

  const handleTypeChange = (_, newType) => {
    if (newType) {
      setType(newType);
    }
  };

  const options = {
    center: [39.5, -98.35],
    maxBoundsViscosity: 1.0,
    maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
    zoom: 4,
    minZoom: 4,
    attributionControl: false,
  };

  return (
    <Page title="Adapt Shipper Network" ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout noStyling fullHeight standaloneView>
          <Grid item xs={12}>
            <PageContent withPadding>
              <Grid container spacing={2}>
                <Grid style={{ display: 'flex', justifyContent: 'space-evenly' }} item xs={12}>
                  <Controller
                    name="shipperSelect"
                    control={control}
                    as={field => (
                      <FormControl>
                        <InputLabel>Shipper</InputLabel>

                        <Select {...field}>
                          {shippers.map(s => (
                            <MenuItem key={s.id} value={s.id}>
                              {s.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="batchDate"
                    control={control}
                    as={field => (
                      <FormControl style={{ marginLeft: '1em', minWidth: '100px' }}>
                        <InputLabel>Batch</InputLabel>

                        <Select {...field} disabled>
                          <MenuItem value={batchDate} selected>
                            {new Date(batchDate).toLocaleDateString()}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />

                  <ToggleButtonGroup value={type} onChange={handleTypeChange} exclusive>
                    <ToggleButton value="path">Lane path</ToggleButton>
                    <ToggleButton value="directPath">Direct path</ToggleButton>
                  </ToggleButtonGroup>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={markLaneStartEnd}
                        onChange={event => {
                          setMarkLaneStartEnd(event.target.checked);
                        }}
                        name="laneStartEnd"
                      />
                    }
                    label="Highlight lane start/end"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showOrigins}
                        onChange={event => {
                          setShowOrigins(event.target.checked);
                        }}
                        name="origins"
                      />
                    }
                    label="Show origin clusters"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showDestinations}
                        onChange={event => {
                          setShowDestinations(event.target.checked);
                        }}
                        name="destinations"
                      />
                    }
                    label="Show destination clusters"
                  />
                </Grid>

                <Grid item xs={12} style={{ height: '75vh' }}>
                  {isLoading && (
                    <Grid
                      style={{ display: 'flex', height: '100%' }}
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  <Map {...options} ref={mapRef} isLoading={isLoading} preferCanvas>
                    <TileLayer url={process.env.REACT_APP_MAPBOX} />
                  </Map>
                </Grid>
              </Grid>
            </PageContent>
          </Grid>
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
};
