/* eslint-disable no-restricted-globals */
import { gql } from 'graphql-request';

const WHERE = {
  STATUS_VAR: gql`$status: [String!]`,
  STATUS_CLAUSE: gql`shipment_execution_status: { _in: $status }`,
  EXECUTION_PARTNER_NAME_VAR: gql`$execution_partner_name: [bigint!]`,
  EXECUTION_PARTNER_NAME_CLAUSE: gql`execution_partner_company: {id: { _in: $execution_partner_name }}`,
  LEGNUMBER_VAR: gql`$legNumber: [Int!]`,
  LEGNUMBER_CLAUSE: gql`leg_number: { _in: $legNumber }`,
  CARRIER_VAR: gql`$carrier: [bigint!]`,
  CARRIER_CLAUSE: gql`carrier_id: { _in: $carrier }`,
  SHIPPER_VAR: gql`$shipper: [bigint!]`,
  SHIPPER_CLAUSE: gql`shipper_id: { _in: $shipper }`,
  ORIGIN_VAR: gql`$origin: [Int!]`,
  ORIGIN_CLAUSE: gql`origin: {id: {_in: $origin }}`,
  DESTINATION_VAR: gql`$destination: [Int!]`,
  DESTINATION_CLAUSE: gql`destination: {id: {_in: $destination }}`,
  EQUIPMENT_TYPE_VAR: gql`$equipment_type: [bigint!]`,
  EQUIPMENT_TYPE_CLAUSE: gql`equipment_type_id: { _in: $equipment_type }`,
  ORIGIN_APPOINTMENT_TIME_VAR: gql`$origin_appointment_time: [timestamp!]`,
  ORIGIN_APPOINTMENT_TIME_CLAUSE: gql`origin_appointment_time: { _in: $origin_appointment_time }`,
  CONTRACT_TYPE_VAR: gql`$contract_type: [String!]`,
  CONTRACT_TYPE_CLAUSE: gql`carrier_trip_tender: {sell_contract: {contract_type: { _in: $contract_type }}}`,
  CONTRACT_TYPE_OTHER_VAR: gql`$contract_type_other: [Boolean]`,
  CONTRACT_TYPE_OTHER_CLAUSE: gql`carrier_trip_tender: { sell_contract_id: { _is_null: true }}`,
  STATUS_ACTIVE_CLAUSE: gql`shipment_execution_status: { _neq: "CANCELED" }`,
  STATUS_CANCELED_CLAUSE: gql`shipment_execution_status: { _eq: "CANCELED" }`,
};

const whereClause = ({ where, search }) => {
  let whereGQL = gql``;
  const whereVarsKeys = Object.keys(where).filter(k => Array.isArray(where[k]) && where[k].length);

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: [
          ${whereVarsKeys.map(key =>
            where[key]
              ? gql`{
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                }`
              : '',
          )}

          ${
            search !== undefined && search !== ''
              ? gql`{
              _or: [
                {origin_address_name: { _ilike: "%${search}%" }}
                {origin_business_name: { _ilike: "%${search}%" }}
                {origin_city: { _ilike: "%${search}%" }}
                {origin_country: { _ilike: "%${search}%" }}
                {origin_state: { _ilike: "%${search}%" }}
                {origin_zip: { _ilike: "%${search}%" }}
                {destination_address_name: { _ilike: "%${search}%" }}
                {destination_business_name: { _ilike: "%${search}%" }}
                {destination_city: { _ilike: "%${search}%" }}
                {destination_country: { _ilike: "%${search}%" }}
                {destination_state: { _ilike: "%${search}%" }}
                {destination_zip: { _ilike: "%${search}%" }}
                {equipment_type: { name: { _ilike: "%${search}%" }}}
                {execution_partner_company: { name: { _ilike: "%${search}%" }}}
              ]
            }`
              : ''
          }
        ]
      }
    `;
  }
  return whereGQL;
};

const whereVar = ({ where }) => gql`
  ${where.status ? WHERE.STATUS_VAR : ``}
  ${where.legNumber ? WHERE.LEGNUMBER_VAR : ``}
  ${where.execution_partner_name ? WHERE.EXECUTION_PARTNER_NAME_VAR : ``}
  ${where.shipper ? WHERE.SHIPPER_VAR : ``}
  ${where.carrier ? WHERE.CARRIER_VAR : ``}
  ${where.origin ? WHERE.ORIGIN_VAR : ``}
  ${where.destination ? WHERE.DESTINATION_VAR : ``}
  ${where.equipment_type ? WHERE.EQUIPMENT_TYPE_VAR : ``}
  ${where.origin_appointment_time ? WHERE.ORIGIN_APPOINTMENT_TIME_VAR : ``}
  ${where.contract_type ? WHERE.CONTRACT_TYPE_VAR : ``}
  ${where.contract_type_other ? WHERE.CONTRACT_TYPE_OTHER_VAR : ``}
`;

export const getShipmenstGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getShipments($limit: Int, $orderBy: [shipment_tender_order_by], $offset: Int, ${whereVar(variables)}) {
      shipmentTender: shipment_tender(limit: $limit, order_by: $orderBy, offset: $offset, ${whereGQL}) {
        id
        executionPartnerShipmentId: execution_partner_shipment_id
        legNumber: leg_number
        transportationMode: transportation_mode
        shipmentExecutionStatus: shipment_execution_status
        originArrivalTime: origin_arrival_time
        originAppointmentTime: origin_appointment_time
        destinationArrivalTime: destination_arrival_time
        destinationAppointmentTime: destination_appointment_time
        shipperShipmentId: shipper_shipment_id
        buyContract: buy_contract {
            contractType: contract_type
        }
        carrierTripTender: carrier_trip_tender {
          id
          executionPartnerShipmentId: execution_partner_shipment_id
          spotConfirmed: spot_confirmed
          sellContract: sell_contract {
            contractType: contract_type
          }
          company {
            id
            name
          }
        }
        equipmentType: equipment_type {
            name
        }
        destination {
            businessName: business_name
            city
            state
            street
            zip
        }
        origin {
            businessName: business_name
            city
            state
            street
            zip
        }
        executionPartnerCompany: execution_partner_company {
            name
        }
        company {
          id
          name
        }
      }
      shipmentTenderAggregate: shipment_tender_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};

export const getExecutionPartnerNamesGQL = () => gql`
  query getExecutionPartnerNames {
    shipmentTender: shipment_tender(distinct_on: execution_partner_id) {
      executionPartnerCompany: execution_partner_company {
        id
        name
      }
    }
  }
`;

export const getLegNumbersGQL = () => gql`
  query getLegNumbers {
    shipmentTender: shipment_tender(distinct_on: leg_number) {
      legNumber: leg_number
    }
  }
`;

export const getOriginsGQL = () => gql`
  query getOrigins {
    shipmentTender: shipment_tender(distinct_on: [origin_business_name, origin_state, origin_city, origin_zip]) {
      origin {
        id
      }
      businessName: origin_business_name
      state: origin_state
      city: origin_city
      zip: origin_zip
    }
  }
`;

export const getDestinationsGQL = () => gql`
  query getDestinations {
    shipmentTender: shipment_tender(
      distinct_on: [destination_business_name, destination_state, destination_city, destination_zip]
    ) {
      destination {
        id
      }
      businessName: destination_business_name
      state: destination_state
      city: destination_city
      zip: destination_zip
    }
  }
`;

export const getCarriersGQL = () => gql`
  query getCarriers {
    shipmentTender: shipment_tender(distinct_on: carrier_id) {
      carrierTripTender: carrier_trip_tender {
        company {
          id
          name
        }
      }
    }
  }
`;

export const getShippersGQL = () => gql`
  query getShippers {
    shipmentTender: shipment_tender(distinct_on: shipper_id) {
      company {
        id
        name
      }
    }
  }
`;

export const getEquipmentTypeGQL = () => gql`
  query getEquipmentTypes {
    shipmentTender: shipment_tender(distinct_on: equipment_type_id) {
      equipmentType: equipment_type {
        id
        name
      }
    }
  }
`;

export const getPickupDatesGQL = () => gql`
  query getPickupDates {
    shipmentTender: shipment_tender(distinct_on: origin_appointment_time, order_by: { origin_appointment_time: desc }) {
      originAppointmentTime: origin_appointment_time
    }
  }
`;
