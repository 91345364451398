import React, { useState } from 'react';
import {
  Page,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
  Beta,
  useTableCustomFilters,
  LOCATION_TYPE_DISPLAY_NAMES,
} from '@leaf/components';
import { useSnackbar } from 'notistack';
import useGQL from 'hooks/useGQL';
import { StateContext } from 'state/StateProvider';
import { SET_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE } from 'state/stateReducer';
import Company from 'domain/company/Company';
import { getOptions, getColumns } from './table-config';
import { getExecutionLocations, getDistinctShippers } from './domain/executionLocationModel';
import AddEditExecutionLocationDialog from './new-location/AddEditExecutionLocationDialog';

const LocationsOverview = () => {
  const {
    state: {
      tables: { executionLocationsOverview: tableState },
    },
    dispatch,
  } = React.useContext(StateContext);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const getGQLClient = useGQL();

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'locationType',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(LOCATION_TYPE_DISPLAY_NAMES).map(key => ({
            label: LOCATION_TYPE_DISPLAY_NAMES[key],
            value: key,
          })),
        ),
    },
    {
      inputType: 'autocomplete',
      columnName: 'shipperName',
      filterOptionsGetter: () => getDistinctShippers(getGQLClient),
    },
  ]);

  const handleAddOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = id => {
    setSelectedLocation(id);
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setSelectedLocation(null);
    setOpenEdit(false);
  };

  const handleEditSubmit = editedLocation => {
    Company.updateLocation(editedLocation, selectedLocation)
      .then(response => {
        handleEditClose();
        enqueueSnackbar(`Edited location ${response.businessName}`, { variant: 'success' });
        dispatch({ type: SET_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE, payload: { ...tableState } });
      })
      .catch(enqueueSnackbar);
  };

  const ourHandleSubmit = newEditLocation => {
    Company.postLocation(newEditLocation)
      .then(response => {
        handleClose();
        enqueueSnackbar(`Added new location ${response.businessName}`, { variant: 'success' });
        dispatch({ type: SET_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE, payload: { ...tableState } });
      })
      .catch(enqueueSnackbar);
  };

  const handleChange = setTableState =>
    dispatch({ type: SET_LOCATIONS_EXECUTION_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <Page title="Execution locations" ComponentTitleRight={<Beta />}>
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          {open && (
            <AddEditExecutionLocationDialog
              open={open}
              onClose={handleClose}
              ourHandleSubmit={ourHandleSubmit}
              title="Add"
            />
          )}
          {openEdit && (
            <AddEditExecutionLocationDialog
              open={openEdit}
              onClose={handleEditClose}
              ourHandleSubmit={handleEditSubmit}
              title="Edit"
              selectedLocation={selectedLocation}
            />
          )}
          <ServerSideTable
            options={getOptions(handleAddOpen)}
            columns={getColumns(filterOptions, handleEditOpen)}
            fn={() => getExecutionLocations(getGQLClient, tableState)}
            tableState={tableState}
            setTableState={handleChange}
            errorHandler={enqueueSnackbar}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
};

export default LocationsOverview;
