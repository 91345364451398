export const onChangeName = (setter, cb) => name => value => {
  setter(prev => {
    const next = {
      ...prev,
      [name]: value,
    };
    if (cb) cb(next);

    return next;
  });
};

export const onChangeEvent = (setter, cb) => event => {
  const { name, checked, value } = event.target;
  setter(prev => {
    const next = {
      ...prev,
      [name]: checked || value,
    };
    if (cb) cb(next);

    return next;
  });
};

export const onChange = setter => () => value => setter(() => value);
