import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Grid, Box, Typography, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import {
  Page,
  BackButton,
  PageContentLayout,
  PageContainer,
  SpacedContainer,
  DetailsOverview,
  useLoading,
  OnTimeStatusBadge,
  CONTRACT_TYPE,
  Form,
  Overview,
  OverviewTable,
  PolygonMap,
  Lane,
} from '@leaf/components';
import useGQL from 'hooks/useGQL';
import { handleConfirmSpot } from 'shipments/domain/Shipment';
import {
  getShipmentGeneralDetails,
  getPickupDetails,
  getDeliveryDetails,
  getMatchedShipperDetails,
  getMatchedLSPDetails,
} from './domain/shipmentDetailsDataMapper';
import { getShipmentById, getShipmentTrackingEvent } from './domain/shipmentDetailsModel';
import { columns, trackingEventColumns, tenderAlocationColumns } from './table-config';

const { Switch, Input } = Form.Generic;

const InputWrapper = styled(Input)`
  background-color: #fff;
`;

const OrderDetails = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const getGQLClient = useGQL();

  const [shipment, setShipment] = useState(null);
  const [shipmentTrackingEvents, setShipmentTrackingEvents] = useState([]);
  const [isChecked, setIsChecked] = React.useState();

  const [getShipmentDetails, isShipmentDetailsLoading] = useLoading(async () => {
    if (id) {
      getShipmentById(getGQLClient, id)
        .then(({ data }) => {
          setShipment(data);
        })
        .catch(enqueueSnackbar);

      getShipmentTrackingEvent(getGQLClient, id)
        .then(({ data }) => {
          setShipmentTrackingEvents(data);
        })
        .catch(enqueueSnackbar);
    }
    return null;
  });

  useEffect(() => {
    getShipmentDetails();
  }, []);

  useEffect(() => {
    if (shipment?.contractType) setIsChecked(shipment?.contractType?.spotConfirmed);
  }, [shipment]);

  const handleChangeStatus = idShipments => {
    setIsChecked(!isChecked);
    handleConfirmSpot(idShipments, !isChecked)
      .then(() => {
        enqueueSnackbar(`Contract type successfully updated`, { variant: 'success' });
      })
      .catch(error => {
        enqueueSnackbar(`Failed to update contract type! ${error}`);
        setIsChecked(isChecked);
      });
  };

  const getConfirmStatusComponent = contractType => {
    if (!contractType?.type || contractType?.type === CONTRACT_TYPE.SPOT) {
      return (
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isChecked}
                  size="small"
                  onChange={() => handleChangeStatus(contractType.idShipments)}
                />
              }
              label="Confirm as Spot"
            />
          </FormGroup>
        </FormControl>
      );
    }
    return null;
  };

  const getOverviewHeader = (title, linehaul, fuel, accessorials, total) => (
    <Box sx={{ width: 1 }} display="flex" flexDirection="column">
      <Typography variant="h6">{title}</Typography>
      <Box mt={1} display="flex" justifyContent="space-between">
        <InputWrapper label="Linehaul" disabled value={linehaul} size="small" InputLabelProps={{ shrink: true }} />
        <InputWrapper label="Fuel" disabled value={fuel} size="small" InputLabelProps={{ shrink: true }} />
        <InputWrapper
          label="Accessorials"
          disabled
          value={accessorials}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <InputWrapper label="Total" disabled value={total} size="small" InputLabelProps={{ shrink: true }} />
      </Box>
    </Box>
  );

  return (
    <Page
      ComponentTitleLeft={
        <Box display="flex">
          <BackButton history={history} path="/shipments" />
          {shipment && (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Lane
                origin={{
                  primaryContent: [`${shipment.originCity}, ${shipment.originState} ${shipment.originZip}`],
                }}
                destination={{
                  primaryContent: [
                    `${shipment.destinationCity}, ${shipment.destinationState} ${shipment.destinationZip}`,
                  ],
                }}
              />
              <Typography variant="body1">{`Shipment ID #${shipment.id}`}</Typography>
            </Box>
          )}
        </Box>
      }
      isUppercase={false}
      lazySpinner={isShipmentDetailsLoading}
    >
      <PageContainer>
        <PageContentLayout withoutSpacing fullHeight={false}>
          <SpacedContainer container spacing={2} autoWidth>
            <Grid item xs={12}>
              <DetailsOverview title="General" data={getShipmentGeneralDetails(shipment)} />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DetailsOverview
                    title="Pickup"
                    titleBadge={<OnTimeStatusBadge status={shipment?.pickupStatus} />}
                    data={getPickupDetails(shipment)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DetailsOverview
                    title="Delivery"
                    titleBadge={<OnTimeStatusBadge status={shipment?.deliveryStatus} />}
                    data={getDeliveryDetails(shipment)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {shipment && (
                <PolygonMap
                  data={[
                    {
                      originGeo: shipment?.originGeo,
                      destinationGeo: shipment?.destinationGeo,
                      pathGeo: shipment?.pathGeo,
                    },
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <DetailsOverview title="Matched Shipper Contract" data={getMatchedShipperDetails(shipment)} />
            </Grid>
            <Grid item xs={6}>
              <DetailsOverview
                title="Matched LSP Contract"
                titleBadge={getConfirmStatusComponent(shipment?.contractType)}
                data={getMatchedLSPDetails(shipment)}
              />
            </Grid>
            <Grid item xs={12}>
              <Overview title="Tender Allocation Guide">
                <OverviewTable columns={tenderAlocationColumns} data={shipment?.shipmentTagEntries} />
              </Overview>
            </Grid>
            <Grid item xs={6}>
              <Overview
                title={getOverviewHeader(
                  'Shipper Invoice',
                  shipment?.shipperInvoicesLinehaulTotal,
                  shipment?.shipperInvoicesFuelTotal,
                  shipment?.shipperInvoicesAccessorialsTotal,
                  shipment?.shipperInvoicesTotal,
                )}
                titleContainerHeight={90}
              >
                <OverviewTable columns={columns} data={shipment?.shipperInvoices} />
              </Overview>
            </Grid>
            <Grid item xs={6}>
              <Overview
                title={getOverviewHeader(
                  'LSP Invoice (for whole trip)',
                  shipment?.carrierInvoicesLinehaulTotal,
                  shipment?.carrierInvoicesFuelTotal,
                  shipment?.carrierInvoicesAccessorialsTotal,
                  shipment?.carrierInvoicesTotal,
                )}
                titleContainerHeight={90}
              >
                <OverviewTable columns={columns} data={shipment?.carrierInvoices} />
              </Overview>
            </Grid>
            <Grid item xs={12}>
              <Overview title="Tracking Events">
                <OverviewTable columns={trackingEventColumns} data={shipmentTrackingEvents} />
              </Overview>
            </Grid>
          </SpacedContainer>
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
};

export default React.memo(withRouter(OrderDetails));
