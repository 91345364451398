/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { SpacedContainer, PolygonMap, PageContent } from '@leaf/components';
import { Grid, MenuItem, Select } from '@mui/material';
import useGQL from 'hooks/useGQL';
import { v4 } from 'uuid';
import RouteCard from './RouteCard';
import RouteCardDetails from './RouteCardDetails';
import { getContractRoutes } from '../details/domain/contractDetailsModel';
import { OverlayLoader } from './OverlayLoader';

const ContainerWrapper = styled(SpacedContainer)`
  height: calc(100vh - 310px);
  position: relative;
`;

const RouteSelectContainer = styled.div`
  height: 100%;
  margin: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2)}`};
  background-color: #fafafa;
`;

const RouteLegsContainer = styled.div`
  height: 100%;
  background-color: #fafafa;
`;

const PrimaryRouteSelect = styled(Select)`
  & .css-yjsfm1 {
    overflow: visible;
  }
  & .css-14lo706 > span,
  & .css-yjsfm1 > span {
    opacity: 1;
    background-color: #fff;
  }
`;

const RoutesOverview = ({ contractId, newRoutes, editMode = false, actions }) => {
  const [routeDetails, setRouteDetails] = useState(null);
  const [showRouteDetails, setshowRouteDetails] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mapData, setMapData] = useState([]);
  const firstRender = useRef(newRoutes || true);

  const [primaryRoute, setPrimaryRoute] = useState(null);

  const getGQLClient = useGQL();

  const fetchRoutes = useCallback(() => {
    getContractRoutes(getGQLClient, contractId).then(({ data }) => {
      if (data[0].routes.length === 1) {
        setRouteDetails(data[0].routes[0]);
        setMapData(
          data[0].routes[0]?.legs.map(leg => ({
            originGeo: leg.originGeo,
            destinationGeo: leg.destinationGeo,
            pathGeo: leg.pathGeo,
          })),
        );
      }
      if (data[0].routes.length >= 1) {
        setRoutes(
          data[0].routes.map(item => ({
            ...item,
            expand: item.isPrimary,
          })),
        );
      }
      setIsLoading(false);
    });
  }, [contractId, getGQLClient]);

  useEffect(() => {
    if (firstRender.current) {
      fetchRoutes();
      firstRender.current = false;
    }
  }, [fetchRoutes]);

  useEffect(() => {
    routes
      ?.filter(singleRoute => singleRoute.expand)
      .map(singleRoute => {
        setMapData(
          singleRoute?.legs.map(leg => ({
            originGeo: leg.originGeo,
            destinationGeo: leg.destinationGeo,
            pathGeo: leg.pathGeo,
          })),
        );
      });
  }, [routes]);

  useEffect(() => {
    if (routes) {
      const foundPrimaryRoute = routes.find(item => item.isPrimary === true);
      setPrimaryRoute(foundPrimaryRoute);
    }
  }, [routes]);

  const handleOpenRouteDetails = route => {
    setRouteDetails(route);
    setRoutes(prevRoutes =>
      prevRoutes.map(item => ({
        ...item,
        expand: item.id === route.id,
      })),
    );
    setshowRouteDetails(true);
  };

  const handleCloseRouteDetails = () => {
    setRouteDetails(null);
    setshowRouteDetails(false);
  };

  const handleExpand = (event, routeId) => {
    event.stopPropagation();
    setRoutes(prevRoutes =>
      prevRoutes.map(item => ({
        ...item,
        expand: item.id === routeId ? !item.expand : false,
      })),
    );
  };

  const handlePrimaryRouteChange = event => {
    const selectedRoute = event.target.value;
    setPrimaryRoute(selectedRoute);
    actions('setPrimaryRoute', selectedRoute);
  };
  return (
    <ContainerWrapper container spacing={0} autoWidth>
      <OverlayLoader active={isLoading} />
      <Grid item xs={3}>
        {(routeDetails && !editMode) || showRouteDetails ? (
          <RouteLegsContainer>
            <RouteCardDetails
              route={routeDetails}
              closeRouteDetails={handleCloseRouteDetails}
              backButton={showRouteDetails}
            />
          </RouteLegsContainer>
        ) : (
          <RouteSelectContainer>
            <PrimaryRouteSelect
              label="Display Route on summary page"
              name="primaryRoute"
              value={primaryRoute}
              fullWidth
              disabled={editMode === false}
              onChange={handlePrimaryRouteChange}
            >
              {routes.map(route => (
                <MenuItem key={route.id} value={route}>
                  {route.name}
                </MenuItem>
              ))}
            </PrimaryRouteSelect>
            {routes.map(route => (
              <RouteCard
                key={v4()}
                route={route}
                openRouteDetails={handleOpenRouteDetails}
                handleExpand={handleExpand}
                isEditable={editMode}
                actions={actions}
              />
            ))}
          </RouteSelectContainer>
        )}
      </Grid>
      <Grid item xs={9}>
        <PageContent fullHeight>
          <PolygonMap data={mapData} />
        </PageContent>
      </Grid>
    </ContainerWrapper>
  );
};

export default RoutesOverview;
