import React, { useContext, useState } from 'react';
import { ServerSideTable, Form, utility, ContractType } from '@leaf/components';
import styled from '@emotion/styled';
import { StateContext } from 'state/StateProvider';
import { SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE } from 'state/stateReducer';
import useGQL from 'hooks/useGQL';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Edit, InfoOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { contractMatchingQuickFilters, getContractMatches, saveMatchedContract } from './domain/contractMatchModel';
import EditContractMatch from './EditContractMatch';

const InfoIcon = styled(InfoOutlined)`
  color: ${({ theme }) => `${theme.palette.primary.main}`};
  &&& {
    width: 24px;
    height: 24px;
  }
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacing(1)}`};
`;

const { ActionButtons } = utility.table;

const Wrapper = styled.div`
  flex-grow: 1;
  .MuiPaper-root {
    height: 700px;
  }
  .MuiPaper-root > .MuiToolbar-root {
    background: #eee;
  }
`;

const ImpliedDateTooltip = () => (
  <Tooltip title="Implied based on the overlap between the buy and sell contract date ranges.">
    <InfoIcon fontSize="small" className="info-icon" />
  </Tooltip>
);

export default ({ contractId, isBuyerContract }) => {
  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: {
      tables: { shipperContractMatchesOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const [contractIdsToEdit, setContractIdsToEdit] = useState();

  const handleChange = setTableState =>
    dispatch({ type: SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <Wrapper>
      <ServerSideTable
        title="Contract Matches"
        customSearch={false}
        options={{
          search: false,
          download: false,
          filter: false,
          viewColumns: false,
          ...utility.table.getRowHoverActionProps('contract-match'),
        }}
        quickFilterComponents={[
          {
            label: 'Time',
            name: 'timeStatuses',
            values: ['PAST', 'ONGOING', 'FUTURE'],
            inputType: 'toggle-button',
          },
        ]}
        gqlQuickFiltersMeta={contractMatchingQuickFilters}
        columns={[
          {
            name: 'ids',
            label: 'IDs',
            options: {
              display: 'excluded',
              filter: false,
              sort: false,
            },
          },
          {
            label: 'Enabled',
            name: 'toggleEnabled',
            options: {
              sort: false,
              customBodyRender: ({ isEnabled, ids }) => (
                <Form.Generic.Switch
                  onChange={e => {
                    saveMatchedContract({ isEnabled: e.target.checked, ...ids })
                      .catch(enqueueSnackbar) // TODO better to revert switch on error then to retrieve table data again
                      .finally(() => {
                        dispatch({
                          type: SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE,
                          payload: { ...tableState },
                        });
                      });
                  }}
                  defaultChecked={isEnabled}
                  disabled={isEnabled}
                />
              ),
            },
          },
          {
            name: 'contractType',
            label: 'Contract Type',
            options: {
              sort: false,
              customBodyRender: type => <ContractType type={type} />,
            },
          },
          {
            name: 'accountName',
            label: 'Account Name',
            options: { sort: false },
          },
          {
            name: 'origin',
            label: 'Origin Area',
            options: { sort: false },
          },
          {
            name: 'destination',
            label: 'Destination Area',
            options: { sort: false },
          },
          {
            name: 'startDate',
            label: 'Start Date',
            options: {
              sort: false,
              customBodyRender: ({ value, isImplied }) => (
                <Date>
                  <Typography variant="body1">{value}</Typography>
                  {isImplied && <ImpliedDateTooltip />}
                </Date>
              ),
            },
          },
          {
            name: 'endDate',
            label: 'End Date',
            options: {
              sort: false,
              customBodyRender: ({ value, isImplied }) => (
                <Date>
                  <Typography variant="body1">{value}</Typography>
                  {isImplied && <ImpliedDateTooltip />}
                </Date>
              ),
            },
          },
          {
            name: 'remainingWeeks',
            label: 'Remaining weeks',
            options: {
              sort: false,
              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'assignedWeeklyVolume',
            label: 'Assigned Weekly Volume',
            options: {
              sort: false,
              display: false,
            },
          },
          {
            name: 'shipperMaxUnallocatedVolumeUntilEnd',
            label: 'Remaining Available Shipper Volume',
            options: {
              sort: false,

              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'weeklyShipperMaxUnallocatedVolumeUntilEnd',
            label: 'Remaining Available Weekly Shipper Volume',
            options: {
              sort: false,

              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'carrierMaxUnallocatedVolumeUntilEnd',
            label: 'Remaining Available LSP Volume',
            options: {
              sort: false,

              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'weeklyCarrierMaxUnallocatedVolumeUntilEnd',
            label: 'Remaining Available Weekly LSP Volume',
            options: {
              sort: false,

              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'allocatedVolume',
            label: 'Match Total Volume',
            options: {
              sort: false,

              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'weeklyAllocatedVolume',
            label: 'Match Weekly Volume',
            options: {
              sort: false,

              ...utility.table.rightAlignColumnProps(),
            },
          },
          {
            name: 'areaOverlap',
            label: 'Area Overlap',
            options: { sort: false },
          },
          {
            label: ' ',
            name: 'actionsData',
            options: {
              sort: false,
              filter: false,
              viewColumns: false,
              ...utility.table.rightStickyColumnProps,
              customBodyRender: actionsData => {
                const { ids } = actionsData;
                return (
                  <ActionButtons id={`Actions-contract-match-${Object.values(ids).join(',')}`}>
                    <Tooltip title="Edit Matched Contract">
                      <IconButton onClick={() => setContractIdsToEdit(ids)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </ActionButtons>
                );
              },
            },
          },
        ]}
        fn={() => getContractMatches({ getGQLClient, tableState, contractId, isBuyerContract })}
        tableState={tableState}
        setTableState={handleChange}
      />
      {contractIdsToEdit && (
        <EditContractMatch
          contractIdsToEdit={contractIdsToEdit}
          setContractIdsToEdit={setContractIdsToEdit}
          handleFormSubmit={values => {
            saveMatchedContract(values)
              .then(() => {
                setContractIdsToEdit();
                // refetch matched contract overview table
                dispatch({ type: SET_SHIPPER_CONTRACT_MATCHES_OVERVIEW_TABLE_STATE, payload: { ...tableState } });
              })
              .catch(enqueueSnackbar);
          }}
        />
      )}
    </Wrapper>
  );
};
