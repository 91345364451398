import { utility, CONTRACT_TYPE } from '@leaf/components';
import axios from 'axios';
import { getFocusContractVolumeDetailsGQL, getContractMatchesGQL, getMatchedContractGQL } from './GQL_CONTRACT_MATCHES';

const mapContractMatch = contracts =>
  contracts.map(c => {
    const ids = {
      buyContractId: c.buyContractId,
      sellContractId: c.sellContractId,
    };
    let isImpliedStartDate = false;
    let isImpliedEndDate = false;
    let { startDate, endDate } = c;
    if (!c.startDate) {
      isImpliedStartDate = true;
      startDate = c.contractMatchVolumes.startDate;
    }
    if (!c.endDate) {
      isImpliedEndDate = true;
      endDate = c.contractMatchVolumes.endDate;
    }
    return {
      ids: Object.values(ids).join(','),
      toggleEnabled: {
        isEnabled: c.isEnabled,
        ids,
      },
      accountName: c.matchedContract.account.name,
      contractType:
        c.matchedContract.contractType === CONTRACT_TYPE.DEDICATED && c.matchedContract.isFleet
          ? CONTRACT_TYPE.FLEET
          : c.matchedContract.contractType,
      startDate: { value: utility.date.backendStringToViewString(startDate), isImplied: isImpliedStartDate },
      endDate: { value: utility.date.backendStringToViewString(endDate), isImplied: isImpliedEndDate },
      origin: c.matchedContract.contractRoutes?.[0]?.route?.legs?.[0]?.lane.originName ?? '-',
      destination: c.matchedContract.contractRoutes?.[0]?.route?.legs?.[0]?.lane.destinationName ?? '-',
      assignedWeeklyVolume: c.assignedWeeklyVolume,
      actionsData: {
        ids,
      },
      shipperMaxUnallocatedVolumeUntilEnd: utility.numbers.formatNumberAsView(
        c?.contractMatchVolumes?.shipperMaxUnallocatedVolumeUntilEnd,
        0,
        false,
      ),
      weeklyShipperMaxUnallocatedVolumeUntilEnd: utility.numbers.formatNumberAsView(
        c?.contractMatchVolumes?.weeklyShipperMaxUnallocatedVolumeUntilEnd,
        1,
        false,
      ),
      carrierMaxUnallocatedVolumeUntilEnd: utility.numbers.formatNumberAsView(
        c?.contractMatchVolumes?.carrierMaxUnallocatedVolumeUntilEnd,
        0,
        false,
      ),
      weeklyCarrierMaxUnallocatedVolumeUntilEnd: utility.numbers.formatNumberAsView(
        c?.contractMatchVolumes?.weeklyCarrierMaxUnallocatedVolumeUntilEnd,
        1,
        false,
      ),
      allocatedVolume: utility.numbers.formatNumberAsView(c?.contractMatchVolumes?.allocatedVolume, 0, false),
      weeklyAllocatedVolume: utility.numbers.formatNumberAsView(
        c?.contractMatchVolumes?.weeklyAllocatedVolume,
        0,
        false,
      ),
      remainingWeeks: utility.numbers.formatNumberAsView(c?.contractMatchVolumes?.remainingWeeks, 0, false),
      areaOverlap: c.contractMatchVolumes.areaOverlap ? 'Yes' : 'No',
    };
  });

const mapContractDetails = contracts => {
  const contract = contracts[0];
  if (!contract) {
    return contracts;
  }
  return {
    origin: contract.contractRoutes[0]?.route.legs?.[0]?.lane.origin ?? '-',
    destination: contract.contractRoutes[0]?.route.legs?.[0]?.lane.destination ?? '-',
    maxVolume: contract.maxVolume ?? '-',
    accountName: contract.seller ? contract.seller.name : contract.buyer.name,
    dateRange: [
      utility.date.backendStringToViewString(contract.startDate),
      utility.date.backendStringToViewString(contract.endDate),
    ],
    maxExpected: utility.numbers.formatNumberAsView(contract?.contractVolumes?.maxExpected, 0, false),
    weeklyMaxExpected: utility.numbers.formatNumberAsView(contract?.contractVolumes?.weeklyMaxExpected, 0, false),
    allocatedUntilContractEnd: utility.numbers.formatNumberAsView(
      contract?.contractVolumes?.allocatedUntilContractEnd,
      0,
      false,
    ),
    weeklyAllocatedUntilContractEnd: utility.numbers.formatNumberAsView(
      contract?.contractVolumes?.weeklyAllocatedUntilContractEnd,
      1,
      false,
    ),
    maxUnallocatedUntilContractEnd: utility.numbers.formatNumberAsView(
      contract?.contractVolumes?.maxUnallocatedUntilContractEnd,
      0,
      false,
    ),
    weeklyMaxUnallocatedUntilContractEnd: utility.numbers.formatNumberAsView(
      contract?.contractVolumes?.weeklyMaxUnallocatedUntilContractEnd,
      1,
      false,
    ),
  };
};

/** For isNestedQuery = true, you have to specify an explicit query string. Otherwise a query will be generated from the returned array */
export const contractMatchingQuickFilters = {
  time_statuses: {
    metaGenerator: selectedValues => {
      const now = utility.date.transformApiDate(new Date());
      const past = [
        {
          query: `{_or: [{end_date: { _lte: "${now}" }}, {_and: [{end_date: { _is_null: true }}, {contract_match_volumes: {end_date: { _lte: "${now}" }}}]}]}`,
        },
      ];
      const ongoing = [
        {
          query: `{_or: [{end_date: { _gte: "${now}" }}, {_and: [{end_date: { _is_null: true }}, {contract_match_volumes: {end_date: { _gte: "${now}" }}}]}]}`,
        },
        {
          query: `{_or: [{start_date: { _lte: "${now}" }}, {_and: [{start_date: { _is_null: true }}, {contract_match_volumes: {start_date: { _lte: "${now}" }}}]}]}`,
        },
      ];
      const future = [
        {
          query: `{_or: [{start_date: { _gte: "${now}" }}, {_and: [{start_date: { _is_null: true }}, {contract_match_volumes: {start_date: { _gte: "${now}" }}}]}]}`,
        },
      ];
      return [
        ...(selectedValues.includes('PAST') ? [past] : []),
        ...(selectedValues.includes('ONGOING') ? [ongoing] : []),
        ...(selectedValues.includes('FUTURE') ? [future] : []),
      ];
    },
  },
};

const mapMatchedContract = ([matchedContract]) => ({
  ...matchedContract,
});

export const getFocusContractVolumeDetails = (getGQLClient, id) => {
  const GQL = getFocusContractVolumeDetailsGQL(id);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapContractDetails(res.contract),
    })),
  );
};

export const getMatchedContract = ({ getGQLClient, ids }) => {
  const GQL = getMatchedContractGQL(ids);
  return getGQLClient().then(client =>
    client.request(GQL).then(res => ({
      data: mapMatchedContract(res.contract_match),
    })),
  );
};

export const getContractMatches = ({ getGQLClient, tableState, contractId, isBuyerContract }) => {
  const variables = {
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    varsToRemap: {
      time_statuses: tableState.quickFilters.timeStatuses?.length ? tableState.quickFilters.timeStatuses : undefined,
    },
    gqlQuickFiltersMeta: tableState.gqlQuickFiltersMeta,
  };

  const { varsToRemap, gqlQuickFiltersMeta, search, sort, ...rest } = variables;

  const GQL = getContractMatchesGQL({
    varsToRemap,
    gqlQuickFiltersMeta,
    search,
    contractId,
    isBuyerContract,
    ...rest,
  });
  return getGQLClient().then(client =>
    client.request(GQL, rest).then(res => ({
      limit: variables.limit,
      offset: variables.offset,
      total: res.contract_match_aggregate.aggregate.count,
      data: mapContractMatch(res.contract_match),
    })),
  );
};

export const saveMatchedContract = contract => {
  const startDate = utility.date.transformApiDate(contract.startDate);
  const endDate = utility.date.transformApiDate(contract.endDate);
  return axios.put('contracting/contract-matches', {
    ...contract,
    isIncumbent: contract.isIncumbent ?? false,
    isEnabled: contract.isEnabled ?? false,
    assignedWeeklyVolume: contract.assignedWeeklyVolume ?? '1', // TODO check what should be default or even required on BE side
    startDate,
    endDate,
  });
};
