import React from 'react';
import { CompanyAvatar, USER_TYPE, utility, MultiRowInfo, ContractType } from '@leaf/components';

export const getShipmentGeneralDetails = data => [
  {
    type: 'multiColumn',
    data: [
      {
        label: 'Shipper',
        value: <CompanyAvatar name={data?.company?.name} id={data?.company?.id} type={USER_TYPE.SHIPPER} isChip />,
        divider: true,
      },
      {
        label: 'Execution Partner',
        value: data?.executionPartnerCompany?.name,
        divider: true,
      },
    ],
  },
  {
    type: 'multiColumn',
    data: [
      {
        label: 'LSP',
        value: (
          <CompanyAvatar
            name={data?.carrierTripTender?.company?.name}
            id={data?.carrierTripTender?.company?.id}
            type={USER_TYPE.CARRIER}
            isChip={data?.carrierTripTender?.company?.name}
          />
        ),
        divider: true,
      },
      {
        label: 'Execution Partner Shipment ID',
        value: data?.executionPartnerShipmentId,
        divider: true,
      },
    ],
  },
  {
    type: 'multiColumn',
    data: [
      {
        label: 'Equipment Type',
        value: data?.equipmentType?.name,
        divider: true,
      },
      {
        label: 'Shipper’s Shipment ID',
        value: data?.shipperShipmentId,
        divider: true,
      },
    ],
  },
  {
    type: 'multiColumn',
    data: [
      {
        label: 'Matched Shipper Ops Plan ID',
        value: data?.buyContract?.shipperRoutePlans[0]?.shipperRoutePlanId,
        divider: true,
      },
      {
        label: 'Shipment Tender Status',
        value: data?.shipmentExecutionStatus,
        divider: true,
      },
    ],
  },
  {
    type: 'multiColumn',
    data: [
      {
        label: 'Shipment Miles (Leaf)',
        value: utility.format.miles(data?.lane?.leafDistance),
        divider: true,
      },
      {
        label: 'LSP Trip ID',
        value: data?.carrierTripTender?.executionPartnerShipmentId,
        divider: true,
      },
    ],
  },
];

export const getPickupDetails = data => [
  {
    type: 'row',
    data: {
      label: 'Shipment Tender Origin Address',
      value: (
        <MultiRowInfo
          primaryContent={[data?.originPrimaryContent]}
          secondaryContent={[data?.originSecondaryContent]}
          hasNoWrap
        />
      ),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Appointment Time',
      value: data && utility.datetime.formatBackendDatetime(data?.originAppointmentTime),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'In & Out Time ',
      value: data?.pickupInOutTime,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Type',
      value: data?.origin?.locationType,
    },
  },
  {
    type: 'divider',
  },
];

export const getDeliveryDetails = data => [
  {
    type: 'row',
    data: {
      label: 'Shipment Tender Destination Address',
      value: (
        <MultiRowInfo
          primaryContent={[data?.destinationPrimaryContent]}
          secondaryContent={[data?.destinationSecondaryContent]}
          hasNoWrap
        />
      ),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Appointment Time',
      value: data && utility.datetime.formatBackendDatetime(data?.destinationAppointmentTime),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'In & Out Time ',
      value: data?.deliveryInOutTime,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Type',
      value: data?.destination?.locationType,
    },
  },
  {
    type: 'divider',
  },
];

export const getMatchedShipperDetails = data => [
  {
    type: 'row',
    data: {
      label: 'Contract ID',
      value: data?.buyContract?.id,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Route ID',
      value: data?.buyContractRouteId,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Contract Type',
      value: <ContractType type={data?.buyContract?.contractType} />,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Linehaul',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.shipperLinehaul, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Linehaul RPM',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.shipperLinehaulRPM, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Fuel',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.shipperFuel, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Accessorials',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.shipperAccessorials, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Total Cost',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.shipperTotalCost, 2, true),
    },
  },
  {
    type: 'divider',
  },
];

export const getMatchedLSPDetails = data => [
  {
    type: 'row',
    data: {
      label: 'Contract ID',
      value: data?.carrierTripTender?.contractId ? data?.carrierTripTender?.contractId : '-',
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Route ID',
      value: data?.carrierTripTender?.routeId,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Contract Type',
      value: data?.carrierTripTender?.sellContract?.contractType ? (
        <ContractType type={data?.carrierTripTender?.sellContract?.contractType} />
      ) : (
        '-'
      ),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Linehaul',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.carrierLinehaul, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Linehaul RPM',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.carrierLinehaulRPM, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Fuel',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.carrierFuel, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Accessorials',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.carrierAccessorials, 2, true),
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'row',
    data: {
      label: 'Total Cost',
      value: utility.numbers.formatNumberAsView(data?.dashboardShipmentTender?.carrierTotalCost, 2, true),
    },
  },
  {
    type: 'divider',
  },
];
