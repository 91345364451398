/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Page, PageContentLayout, PageContainer } from '@leaf/components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogContentText from '@mui/material/DialogContentText';
import Beta from 'components/Beta';
import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { useGQLClientWithAuth } from 'hooks/useGQL';
import { useSnackbar } from 'notistack';
import { getContractById, useContractOptions, setDataForContractFormView } from './domain/contractModel';
import { defaultValues } from './domain/stateMachine';
import { TabContent } from './partials/TabContent';
import CustomDialog from '../shared/CustomDialog';
import { Notice } from './partials/Notice';

import { ContractSteps } from './contract-information/Steps';
import { SelectRoutes } from './routes-information/SelectRoutes';

function a11yProps(index) {
  return {
    'id': `contract-tab-${index}`,
    'aria-controls': `contract-tabpanel-${index}`,
    'data-tabindex': index,
  };
}
const ROUTE_PATH = '/contracts/create-contract/:id/route';

export default withRouter(({ history, match, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const url = match.path;
  const tabBasedUrl = url.indexOf(ROUTE_PATH) !== -1 ? 1 : 0;
  const editContractId = match.params.id;
  const duplicateContract = location.search.toLowerCase().includes('duplicate');
  const [existingContractValues, setExistingContractValues] = useState();
  const [currentTab, setCurrentTab] = useState(tabBasedUrl);
  const [openDialog, setOpenDialog] = useState(false);
  const [abortContract, setAbortContract] = useState(false);
  const [secureClient] = useGQLClientWithAuth();
  const [contractOptions, loading, error] = useContractOptions();
  const [contractRouteCount, setContractRouteCount] = useState(0);
  const determinePageTitle = () => {
    if (duplicateContract) {
      return 'Duplicate Contract';
    }
    return editContractId ? 'Edit Contract' : 'New Contract';
  };
  const pageTittle = determinePageTitle();
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (editContractId && secureClient && contractOptions) {
      getContractById(secureClient, { _eq: editContractId }).then(({ contract }) => {
        setContractRouteCount(contract[0].contractRoutes.length);
        // remove contract id if this is a duplication
        const purgeData = duplicateContract ? { ...contract[0], id: null } : contract[0];
        const viewData = setDataForContractFormView(purgeData, contractOptions);
        setExistingContractValues(viewData);
      });
    }
  }, [editContractId, secureClient, contractOptions, duplicateContract]);

  createStore({
    newContract: editContractId ? existingContractValues : defaultValues,
    CONTRACT_FORM_OPTIONS: contractOptions,
    LANE_OPTIONS: [],
    ROUTE_COUNT: contractRouteCount,
  });

  const callNextTab = () => {
    setCurrentTab(currentTab + 1);
  };
  const manageTabState = event => {
    setCurrentTab(event.target.getAttribute('data-tabindex') * 0);
  };
  const handleAbort = e => {
    setOpenDialog(!openDialog);
    const confirm = e.target.getAttribute('data-accept');
    if (confirm) {
      setAbortContract(true);
      history.push('/contracts');
    }
  };

  useEffect(() => {
    const navigateAway = history.listen(historyLocation => {
      // clear store if user navigates aways from contract flow
      if (!historyLocation.pathname.includes('route')) {
        sessionStorage.clear();
      }
      return navigateAway;
    }, []);

    return () => {
      navigateAway();
    };
  }, [history]);

  if (loading) {
    return <Notice />;
  }
  if (error) {
    enqueueSnackbar(error);
  }

  const readyToRender = (editContractId && existingContractValues) || !editContractId;

  if (!readyToRender || loading) {
    return null;
  }

  return (
    <StateMachineProvider>
      <Page title={pageTittle} ComponentTitleRight={<Beta />}>
        <PageContainer>
          <PageContentLayout hasSubheader fullHeight={false}>
            <Tabs
              value={currentTab}
              onChange={manageTabState}
              aria-label="Create a new contract"
              variant="fullWidth"
              centered={false}
              sx={{ mb: 3 }}
            >
              <Tab
                label="1. Create Contract"
                disabled
                {...a11yProps(0)}
                sx={{
                  paddingTop: '24px',
                  paddingBottom: '24px',
                }}
              />
              <Tab label="2. Select Route" {...a11yProps(1)} disabled />
            </Tabs>
            {loading && <Notice />}
            <TabContent value={currentTab} index={0}>
              <ContractSteps callNextTab={callNextTab} abortContract={abortContract} />
            </TabContent>
            <TabContent value={currentTab} index={1}>
              <SelectRoutes />
            </TabContent>
          </PageContentLayout>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '12px 16px',
              backgroundColor: '#fff',
              borderTop: '1px solid #ccc',
              position: 'fixed',
              right: '0',
              left: '0',
            }}
          >
            <Typography>* All new shipper contracts will be created with Leaf as the Carrier</Typography>
            {currentTab === 0 ? (
              <Button variant="contained" size="large" color="secondary" onClick={handleAbort}>
                Cancel
              </Button>
            ) : (
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  history.push('/contracts');
                }}
              >
                Done
              </Button>
            )}
          </Stack>
          <CustomDialog open={openDialog} title="Cancel Contract" handleClose={handleAbort}>
            <DialogContentText sx={{ p: '0 16px' }}>
              <Typography sx={{ mb: 2 }}>
                {editContractId
                  ? 'Are you sure you want to cancel your modifications?'
                  : 'Are you sure you want to cancel the creation of this contract?'}
              </Typography>
              <Typography sx={{ mb: 2 }}>
                {editContractId
                  ? 'You will not be able to recover modifications made since the last time the contract was saved.'
                  : 'You will not be able to recover it and your data will be lost.'}
              </Typography>
            </DialogContentText>
          </CustomDialog>
        </PageContainer>
      </Page>
    </StateMachineProvider>
  );
});
