import axios from 'axios';

import { utility } from '@leaf/components';
import Address from '../location/Address';
import ShipmentInvoice from './ShipmentInvoice';
import ShipmentSimple from './ShipmentSimple';
import ShipmentTrackingEvent from './ShipmentTrackingEvent';

export const SHIPMENTS_URL = '/saturn/admin/execution-details';

export default class Shipment {
  constructor({
    pickupAppointment,
    pickupArrivalActual,
    pickupDepartureActual,
    pickupStatus,
    deliveryAppointment,
    deliveryArrivalActual,
    deliveryDepartureActual,
    deliveryStatus,
    origin,
    originDelay,
    destination,
    destinationDelay,
    executionPartner,
    ...response
  }) {
    Object.assign(this, {
      deliveryAppointmentTime: utility.datetime.transformIsoDateTime(deliveryAppointment),
      deliveryArrivalActual: utility.datetime.transformIsoDateTime(deliveryArrivalActual),
      deliveryDelay: destinationDelay,
      deliveryDepartureActual: utility.datetime.transformIsoDateTime(deliveryDepartureActual),
      deliveryStatus,
      laneDestination: new Address({
        businessName: response.destinationBusinessName,
        street: response.destinationAddressName,
        city: response.destinationCity,
        zipCode: response.destinationZip,
        state: response.destinationState,
      }),
      laneOrigin: new Address({
        businessName: response.originBusinessName,
        street: response.originAddressName,
        city: response.originCity,
        zipCode: response.originZip,
        state: response.originState,
      }),
      pickupAppointmentTime: utility.datetime.transformIsoDateTime(pickupAppointment),
      pickupArrivalActual: utility.datetime.transformIsoDateTime(pickupArrivalActual),
      pickupDelay: originDelay,
      pickupDepartureActual: utility.datetime.transformIsoDateTime(pickupDepartureActual),
      pickupStatus,
      ...response,
    });

    this.view = {
      lane: `${this.laneOrigin.city} ${this.laneOrigin.state} ${this.laneOrigin.zipCode} - ${this.laneDestination.city} ${this.laneDestination.state} ${this.laneDestination.zipCode}`,
      originAddress: `${this.laneOrigin.businessName}, ${this.laneOrigin.street}, ${this.laneOrigin.city} ${this.laneOrigin.state} ${this.laneOrigin.zipCode}`,
      destinationAddress: `${this.laneDestination.businessName}, ${this.laneDestination.street}, ${this.laneDestination.city} ${this.laneDestination.state} ${this.laneDestination.zipCode}`,
      pickupStatus: this.pickupStatus,
      pickupInOutTime: [
        utility.datetime.normalizedUtcDateToViewStringTime(this.pickupArrivalActual),
        utility.datetime.normalizedUtcDateToViewStringTime(this.pickupDepartureActual),
      ],
      pickupDelay: utility.numbers.formatNumberAsView(this.pickupDelay, null, false),
      pickupAppointmentTime: utility.datetime.normalizedUtcDateToViewStringTime(this.pickupAppointmentTime),
      deliveryStatus: this.deliveryStatus,
      deliveryInOutTime: [
        utility.datetime.normalizedUtcDateToViewStringTime(this.deliveryArrivalActual),
        utility.datetime.normalizedUtcDateToViewStringTime(this.deliveryDepartureActual),
      ],
      deliveryDelay: utility.numbers.formatNumberAsView(this.deliveryDelay, null, false),
      deliveryAppointmentTime: utility.datetime.normalizedUtcDateToViewStringTime(this.deliveryAppointmentTime) ?? '-',
      referenceNumbers: this.shipperShipmentId ?? '-',
      equipmentName: this.equipmentTypeName ?? '-',
      shipperName: this.shipperName ?? '-',
      carrierName: this.carrierName ?? '-',
      executionPartnerName: this.executionPartnerName ?? '-',
      id: this.id,
      originSimple: `${this.laneOrigin.city} ${this.laneOrigin.state} ${this.laneOrigin.zipCode}`,
      destinationSimple: `${this.laneDestination.city} ${this.laneDestination.state} ${this.laneDestination.zipCode}`,
    };
  }

  static getAll = async params =>
    axios.get(`view/shipment-tenders?${utility.pagination.toServerSideParams(params)}`).then(response => ({
      ...response,
      data: response.data.map(shipment => new ShipmentSimple(shipment)),
    }));

  static getById = async id => axios.get(`view/shipment-tenders/${id}`).then(response => new Shipment(response));

  static getShipperInvoice = async shipmentId =>
    axios.get(`saturn/admin/shipments/${shipmentId}/shipper-invoices`).then(response => new ShipmentInvoice(response));

  static getCarrierInvoice = async shipmentId =>
    axios.get(`saturn/admin/shipments/${shipmentId}/carrier-invoices`).then(response => new ShipmentInvoice(response));

  static getTrackingHistory = async shipmentId =>
    axios
      .get(`saturn/admin/shipments/${shipmentId}/tracking-history`)
      .then(response => response.trackingHistory.map(item => new ShipmentTrackingEvent(item)));

  // filter values
  static getExecutionPartners = async () =>
    axios.get('view/shipment-tenders/execution-partners').then(
      partners =>
        partners?.map(({ id, name }) => ({
          label: name,
          value: id,
        })) ?? [],
    );

  static getOrigins = async () =>
    axios.get('view/shipment-tenders/origins').then(
      origins =>
        origins?.map(origin => ({
          label: origin.split(';').join(', '),
          value: origin,
        })) ?? [],
    );

  static getDestinations = async () =>
    axios.get('view/shipment-tenders/destinations').then(
      destinations =>
        destinations?.map(destination => ({
          label: destination.split(';').join(', '),
          value: destination,
        })) ?? [],
    );

  static getShippers = async () =>
    axios.get('view/shipment-tenders/shippers').then(
      shippers =>
        shippers?.map(({ id, name }) => ({
          label: name,
          value: id,
        })) ?? [],
    );

  static getCarriers = async () =>
    axios.get('view/shipment-tenders/carriers').then(
      carriers =>
        carriers?.map(({ id, name }) => ({
          label: name,
          value: id,
        })) ?? [],
    );

  static getTripIds = async () =>
    axios.get('view/shipment-tenders/trip-ids').then(
      tripIds =>
        tripIds?.map(tripId => ({
          label: tripId,
          value: tripId,
        })) ?? [],
    );

  static getLegNumbers = async () =>
    axios.get('view/shipment-tenders/leg-numbers').then(
      legNumbers =>
        legNumbers?.map(legNumber => ({
          label: legNumber.toString(),
          value: legNumber.toString(),
        })) ?? [],
    );

  static download = async params =>
    axios
      .get(`view/shipment-tenders/download?${utility.pagination.toServerSideParams(params)}`)
      .then(res => utility.table.triggerBrowserDownload(res, 'shipmentTendersTableDownload'));
}
