import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, DialogActions, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Form, useLoading, LazySpinner } from '@leaf/components';
import useGQL from 'hooks/useGQL';
import { useSnackbar } from 'notistack';
import { getMatchedContract } from './domain/contractMatchModel';

export default ({ handleFormSubmit, contractIdsToEdit, setContractIdsToEdit }) => {
  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, register, reset } = useForm();
  const [contract, setContract] = useState();
  const [canEnable, setCanEnable] = useState(false);

  const onClosePopup = useCallback(() => setContractIdsToEdit(), [setContractIdsToEdit]);

  const [getContractToEdit, isContractToEditLoading] = useLoading(
    async ids =>
      getMatchedContract({ getGQLClient, ids })
        .then(({ data }) => {
          setContract(data);
        })
        .catch(enqueueSnackbar),
    true,
  );

  useEffect(() => {
    if (contract) {
      setCanEnable(!contract.isEnabled);
      reset(contract);
    }
  }, [contract]);

  useEffect(() => {
    if (contractIdsToEdit) {
      getContractToEdit(contractIdsToEdit);
    }
  }, [contractIdsToEdit]);

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClosePopup}>
      <DialogTitle>EDIT CONTRACT MATCH</DialogTitle>
      {!isContractToEditLoading ? (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <input name="sellContractId" hidden ref={register()} />
          <input name="buyContractId" hidden ref={register()} />
          <DialogContent dividers>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Form.Library.Switch label="Enable" name="isEnabled" control={control} disabled={!canEnable} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Form.Library.Switch label="Is Incumbent Relationship?" name="isIncumbent" control={control} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Form.Library.Datepicker label="Start date" name="startDate" control={control} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Form.Library.Datepicker label="End date" name="endDate" control={control} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Form.Library.Input
                    type="number"
                    name="assignedWeeklyVolume"
                    label="Weekly Match Volume"
                    control={control}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Form.Library.Input
                    sx={{ '.MuiInputBase-root > textarea': { padding: theme => theme.spacing(2) } }}
                    multiline
                    rows={4}
                    name="notes"
                    label="Notes"
                    control={control}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={onClosePopup}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      ) : (
        <LazySpinner offset="50vh" />
      )}
    </Dialog>
  );
};
