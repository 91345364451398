/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@mui/material';
import { Form } from '@leaf/components';

export default React.memo(
  // Prop `value` is type of object when Autocomplete is single, type of `array` when multiple
  ({ name, label, options, getOptionLabel, multiple, formik, onChangeCb, fieldVariant, ...rest }) => {
    const handleChange = (e, v) => {
      formik.setFieldValue(name, v);
      onChangeCb?.(v);
    };

    return (
      <Form.Generic.Autocomplete
        name={name}
        options={options}
        value={formik.values[name]}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        renderInput={props => <TextField variant={fieldVariant} label={label} {...props} />}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        multiple={multiple}
        fullWidth
        {...rest}
      />
    );
  },
);
