import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Box, Tooltip, IconButton } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import {
  Page,
  Tabs,
  BackButton,
  PageContentLayout,
  SpacedContainer,
  PageContainer,
  utility,
  RoutesOverview,
  useCopyToClipboard,
} from '@leaf/components';
import useGQL from 'hooks/useGQL';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
// import FleetDiscoveryDetailsSummary from './FleetDiscoveryDetailsSummary';
import { getFleetDiscoveryDetails, getFleetDiscoveryDetailsRoutes } from './domain/fleetDiscoveryDetailsModel';

const BoxWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const { REACT_APP_UI_CLIENT_APP_URL } = process.env;

const FleetDiscoveryDetails = ({
  match: {
    params: { id },
  },
  history,
  location,
}) => {
  const text = `${REACT_APP_UI_CLIENT_APP_URL}/share/fleet-opportunities/${id}`;
  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();
  const [fleetDiscoveryRoutes, setFleetDiscoveryRoutes] = useState();
  const [fleetDiscovery, setFleetDiscovery] = useState();

  const copy = useCopyToClipboard(
    successMessage => enqueueSnackbar(successMessage, { variant: 'success' }),
    errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' }),
  );

  useEffect(() => {
    getFleetDiscoveryDetailsRoutes(getGQLClient, id).then(setFleetDiscoveryRoutes).catch(enqueueSnackbar);
    getFleetDiscoveryDetails(getGQLClient, id).then(setFleetDiscovery).catch(enqueueSnackbar);
  }, [id]);

  const getTabs = useCallback(
    () => [
      // {
      //   label: 'Details',
      //   content: <FleetDiscoveryDetailsSummary fleetDiscovery={fleetDiscovery} meta={fleetDiscoveryRoutes} />,
      // },
      {
        label: 'Permutations',
        content: <RoutesOverview routesData={fleetDiscoveryRoutes} />,
      },
    ],
    [fleetDiscovery, fleetDiscoveryRoutes],
  );

  return (
    <Page
      ComponentTitleLeft={
        <>
          <BackButton history={history} path="/planning/fleet-discovery" />
          {fleetDiscovery && (
            <Typography variant="h5">{`${fleetDiscovery.domicile} - ${utility.datetime.formatBackendDatetime(
              fleetDiscovery.runTs,
            )}`}</Typography>
          )}
        </>
      }
      isUppercase={false}
      UserDescription={
        <BoxWrapper>
          <Tooltip title="Copy External Share Link">
            <IconButton
              onClick={event => {
                event.stopPropagation();
                copy(text);
              }}
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </BoxWrapper>
      }
    >
      <PageContainer>
        <SpacedContainer container spacing={2} autoWidth>
          <Grid item xs={12}>
            <PageContentLayout withoutSpacing fullHeight={false}>
              <Tabs
                location={location}
                history={history}
                mainUrlPath={`/planning/fleet-discovery/${id}`}
                tabs={getTabs()}
              />
            </PageContentLayout>
          </Grid>
        </SpacedContainer>
      </PageContainer>
    </Page>
  );
};

export default withRouter(FleetDiscoveryDetails);
