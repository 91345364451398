/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl } from '@mui/material';
// import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Form, utility } from '@leaf/components';
import { disableEnterKey } from 'utility/disableEnterKey';
import { useStateMachine } from 'little-state-machine';
import { FormContent, FormItem, FormFooter, FormActions } from '../partials/viewHelpers';
import { WeekDays } from '../partials/WeekDays';
import { updateContract, defaultValues as resetValues } from '../domain/stateMachine';

const VOLUME_TYPE = Object.freeze(['FIXED', 'VARIABLE']);
const VOLUME_FREQUENCY = Object.freeze(['DAILY', 'WEEKLY']);

const setDateForView = date => {
  // eslint-disable-next-line no-restricted-globals
  if (date instanceof Date && isFinite(date.getTime())) {
    return utility.date.formatDate(date, utility.date.VIEW_FORMAT);
  }
  return date;
};

export function TimeframeVolumeForm({ next, back, abortContract }) {
  const { actions, getState } = useStateMachine({ updateContract });
  const defaultValues = getState().newContract;
  const firstRender = useRef(true);
  const [minEndDate, setMinEndDate] = useState(defaultValues.startDate);
  const today = defaultValues.startDate || utility.date.transformApiDate(new Date());

  const handleFormSubmit = data => {
    const formattedData = {
      ...data,
      startDate: setDateForView(data.startDate),
      endDate: setDateForView(data.endDate),
    };
    actions.updateContract(formattedData);
    next();
  };
  const rhfApi = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
  } = rhfApi;

  const watchVolumeFrequency = watch('volumeFrequency');

  useEffect(() => {
    if (abortContract) {
      actions.updateAction(resetValues);
      reset(resetValues);
    }
  }, [abortContract, actions, reset]);

  useEffect(() => {
    if (!firstRender.current) {
      if (watchVolumeFrequency === 'DAILY') {
        clearErrors('maxVolume');
        setValue('includeWeekends', false);
        setValue('minVolume', '');
        setValue('maxVolume', '');
      } else if (watchVolumeFrequency === 'WEEKLY') {
        setValue('volumePattern', '');
      }
    }
    firstRender.current = false;
  }, [watchVolumeFrequency, setValue, clearErrors]);

  return (
    <FormProvider {...rhfApi}>
      <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={e => disableEnterKey(e)}>
        <FormContent>
          <FormItem>
            <Form.Library.Datepicker
              label="Start date"
              name="startDate"
              control={control}
              minDate={defaultValues.id ? '' : today}
              onAccept={value => {
                setMinEndDate(value);
              }}
            />
            <Form.Library.Datepicker label="End date" name="endDate" control={control} minDate={minEndDate} />

            <Controller
              name="volumeType"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <Form.Generic.Autocomplete
                  value={value}
                  options={VOLUME_TYPE}
                  isOptionEqualToValue={(option, selected) => option === selected}
                  getOptionLabel={option => option}
                  onChange={(_, data) => onChange(data)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Voulme Type*"
                      error={!!errors.volumeType}
                      helperText={errors.volumeType ? 'Required field' : null}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="volumeFrequency"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <Form.Generic.Autocomplete
                  value={value}
                  options={VOLUME_FREQUENCY}
                  isOptionEqualToValue={(option, selected) => option === selected}
                  getOptionLabel={option => option}
                  onChange={(_, data) => onChange(data)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Voulme Frequency*"
                      error={!!errors.volumeFrequency}
                      helperText={errors.volumeFrequency ? 'Required field' : null}
                    />
                  )}
                />
              )}
            />
          </FormItem>
          <FormItem>
            <TextField
              type="number"
              InputProps={{
                inputProps: { min: 0, step: 1 },
              }}
              name="minVolume"
              label="Min Volume"
              variant="outlined"
              fullWidth
              inputRef={register}
              disabled={watchVolumeFrequency === 'DAILY' || watchVolumeFrequency === ''}
            />

            <Controller
              name="maxVolume"
              control={control}
              rules={{ required: watchVolumeFrequency === 'WEEKLY' }}
              render={props => (
                <TextField
                  fullWidth
                  label="Max Volume"
                  InputProps={{
                    inputProps: { min: 1, step: 1 },
                  }}
                  error={!!errors.maxVolume}
                  helperText={errors.maxVolume ? 'Required field' : null}
                  disabled={watchVolumeFrequency === 'DAILY' || watchVolumeFrequency === ''}
                  {...props}
                />
              )}
            />

            <FormControl component="fieldset" error={!!errors?.includeWeekends}>
              <Controller
                name="includeWeekends"
                defaultValue={defaultValues.includeWeekends}
                control={control}
                render={props => (
                  <FormControlLabel
                    label="Include weekends"
                    control={
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                        disabled={watchVolumeFrequency === 'DAILY' || watchVolumeFrequency === ''}
                      />
                    }
                  />
                )}
              />
            </FormControl>

            <WeekDays
              label="Day Of Flex Volume"
              name="volumePattern"
              defaultValue={defaultValues.volumePattern}
              required={watchVolumeFrequency === 'DAILY'}
              disabled={watchVolumeFrequency === null || watchVolumeFrequency === 'WEEKLY'}
            />
          </FormItem>
        </FormContent>
        <FormFooter requiredText="* Fields are required">
          <FormActions>
            <Button type="button" variant="outlined" size="large" onClick={back}>
              Previous
            </Button>
            <Button type="submit" variant="contained" size="large">
              continue
            </Button>
          </FormActions>
        </FormFooter>
      </form>
    </FormProvider>
  );
}
