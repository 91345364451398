import { gql } from 'graphql-request';

const whereClause = ({ contractId, isBuyerContract, varsToRemap, gqlQuickFiltersMeta }) => {
  let whereGQL = gql``;

  let nestedQueries = [];
  if (gqlQuickFiltersMeta) {
    nestedQueries =
      Object.keys(varsToRemap).filter(
        k => Array.isArray(varsToRemap[k]) && varsToRemap[k].length && gqlQuickFiltersMeta[k].isNestedQuery,
      ) ?? [];
  }

  /**
   * TODO: document "varsToRemapKeys" what's going on here.
   * It's pretty hard to follow.
   *
   */
  const varsToRemapKeys = Object.keys(varsToRemap)
    .filter(k => Array.isArray(varsToRemap[k]) && varsToRemap[k].length)
    .some(k => varsToRemap[k]?.length > 0)
    ? Object.keys(varsToRemap).filter(k => !nestedQueries.includes(k))
    : [];

  whereGQL = gql`
      where: {
        _and: [
          {${isBuyerContract ? 'buy_contract' : 'sell_contract'}_id: { _eq: "${contractId}" }}
          ${
            varsToRemapKeys.length > 0
              ? gql`{
                _and: [
                  ${varsToRemapKeys.map(key =>
                    varsToRemap[key]
                      ? gql`{
                            _or: [
                                ${gqlQuickFiltersMeta[key].metaGenerator(varsToRemap[key]).map(
                                  operations => gql`{
                                      _and: [
                                        ${operations.map(
                                          operation =>
                                            operation.query ??
                                            gql`
                                              {${operation.field}: { ${operation.name}: ${
                                              operation.type === 'string' ? `"${operation.value}"` : operation.value
                                            }}
                                            }`,
                                        )}]
                                      }`,
                                )}
                              ]
                            }`
                      : '',
                  )}
                  ]}`
              : ''
          }
        ]
      }
  `;

  return whereGQL;
};

export const getContractMatchesGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getContractMatches(
      $limit: Int
      $offset: Int
    ) {
      contract_match(
        limit: $limit
        offset: $offset
        order_by: { is_enabled: desc }
        ${whereGQL}
      ) {
        buyContractId: buy_contract_id
        sellContractId: sell_contract_id
        isEnabled: is_enabled
        isIncumbent: is_incumbent
        endDate: end_date
        startDate: start_date
        assignedWeeklyVolume: assigned_weekly_volume
        matchedContract: ${variables.isBuyerContract ? 'sell_contract' : 'buy_contract'} {
          contractType: contract_type
          isFleet: is_fleet
          account: ${variables.isBuyerContract ? 'seller' : 'buyer'} {
            name
          }
          contractRoutes: contract_routes(where: {is_primary: {_eq: true}}) {
            route {
              legs(order_by: {sequence: asc}) {
                lane {
                  destinationName: destination_name
                  originName: origin_name
                }
              }
            }
          }
        }
        contractMatchVolumes: contract_match_volumes {
          shipperMaxUnallocatedVolumeUntilEnd: shipper_max_unallocated_volume_until_end
          weeklyShipperMaxUnallocatedVolumeUntilEnd: weekly_shipper_max_unallocated_volume_until_end
          carrierMaxUnallocatedVolumeUntilEnd: carrier_max_unallocated_volume_until_end
          weeklyCarrierMaxUnallocatedVolumeUntilEnd: weekly_carrier_max_unallocated_volume_until_end
          allocatedVolume: allocated_volume
          weeklyAllocatedVolume: weekly_allocated_volume
          areaOverlap: area_overlap
          startDate: start_date
          endDate:end_date
          remainingWeeks: remaining_weeks
        }
      }
      contract_match_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};

export const getMatchedContractGQL = ids => {
  const { sellContractId, buyContractId } = ids;
  return gql`
    query getMatchedContract {
      contract_match(where: { _and: { sell_contract_id: { _eq: "${sellContractId}" }, buy_contract_id: { _eq: "${buyContractId}" } } }) {
        isEnabled: is_enabled
        isIncumbent: is_incumbent
        assignedWeeklyVolume: assigned_weekly_volume
        startDate: start_date
        endDate: end_date
        notes
        buyContractId: buy_contract_id
        sellContractId: sell_contract_id
      }
    }
  `;
};

export const getFocusContractVolumeDetailsGQL = id =>
  gql`
    query getFocusContractVolumeDetails {
      contract(where: { id: { _eq: "${id}" } }) {
        maxVolume: max_volume
        startDate: start_date
        endDate: end_date
        seller {
          name
        }
        buyer {
          name
        }
        contractRoutes: contract_routes(where: { is_primary: { _eq: true } }) {
          route {
            legs(order_by: { sequence: asc }) {
              lane {
                origin: origin_name
                destination: destination_name
              }
            }
          }
        }
        contractVolumes: contract_volumes {
          maxExpected: max_expected
          weeklyMaxExpected: weekly_max_expected
          allocatedUntilContractEnd: allocated_until_contract_end
          weeklyAllocatedUntilContractEnd: weekly_allocated_until_contract_end
          maxUnallocatedUntilContractEnd: max_unallocated_until_contract_end
          weeklyMaxUnallocatedUntilContractEnd: weekly_max_unallocated_until_contract_end
        }
      }
    }
  `;
