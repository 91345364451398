import React, { useEffect, useState } from 'react';
import { Page, PageContentLayout, LaneType, utility, useLoading, PageContainer, Form } from '@leaf/components';
import { useForm } from 'react-hook-form';
import Location from 'domain/location/Location';
import { useSnackbar } from 'notistack';
import { Grid, Typography, CircularProgress, Box } from '@mui/material';
import styled from '@emotion/styled';
import leaflet from 'leaflet';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';

const Map = styled(LeafletMap)`
  height: 50%;
  position: relative;
`;

const GridContainer = styled(Grid)`
  margin: ${({ theme }) => theme.spacing(4)} 0;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;
const LaneTypeWrapper = styled.div`
  &&& {
    .MuiSvgIcon-root {
      height: 100%;
    }
  }
`;

export default () => {
  const [originLocations, setOriginLocations] = useState([]);
  const [destinationLocations, setDestinationLocations] = useState([]);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [response, setResponse] = useState(0);
  const mapRef = React.useRef();

  const options = {
    center: [39.5, -98.35],
    maxBoundsViscosity: 1.0,
    maxBounds: leaflet.latLngBounds(leaflet.latLng(-90, -200), leaflet.latLng(90, 200)),
    zoom: 4,
    minZoom: 4,
    attributionControl: false,
  };

  const { enqueueSnackbar } = useSnackbar();

  const [getResponse, isResponseLoading] = useLoading(async stops =>
    Location.getDistance(stops).then(setResponse).catch(enqueueSnackbar),
  );

  useEffect(() => {
    if (origin && destination) {
      const stops = `${origin.longitude},${origin.latitude};${destination.longitude},${destination.latitude}`;
      getResponse(stops);
    }
  }, [origin, destination]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if (map && response) {
      leaflet
        .geoJSON({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: response.geometry,
            },
          ],
        })
        .addTo(map);

      return () =>
        map.eachLayer(layer => {
          if (layer.feature) {
            layer.remove();
          }
        });
    }
  }, [response]);

  const { control } = useForm({
    defaultValues: {
      origin: null,
      destination: null,
    },
  });

  const handleInputChange = (value, setter) => {
    if (value.length >= 3) {
      Location.getLocation(value).then(setter).catch(enqueueSnackbar);
    } else {
      setter([]);
    }
  };

  const getAddress = location =>
    location
      ? `${location.address ? location.address : ''} ${location.city} ${location.state} ${location.zipCode}`
      : '';

  return (
    <Page title="PC Miler Lookup">
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <form>
            <GridContainer container>
              <Grid item xs={3}>
                <Form.Library.Autocomplete
                  name="origin"
                  label="Origin"
                  options={originLocations}
                  getOptionLabel={getAddress}
                  control={control}
                  onInputChange={(_, value) => handleInputChange(value, setOriginLocations)}
                  onChange={(_, data) => setOrigin(data)}
                  value={origin}
                />
              </Grid>
              <LaneTypeWrapper>
                <LaneType />
              </LaneTypeWrapper>
              <Grid item xs={3}>
                <Form.Library.Autocomplete
                  name="destination"
                  label="Destination"
                  options={destinationLocations}
                  getOptionLabel={getAddress}
                  control={control}
                  value={destination}
                  onInputChange={(_, value) => handleInputChange(value, setDestinationLocations)}
                  onChange={(_, data) => setDestination(data)}
                />
              </Grid>
            </GridContainer>
            {isResponseLoading ? (
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Grid container>
                <Grid container item xs={12} justifyContent="center">
                  <Typography variant="h3">PCM says...</Typography>
                </Grid>

                <Grid container item xs={12} justifyContent="space-evenly">
                  <Typography variant="h4">Distance: {utility.format.miles(response.distance)}</Typography>

                  <Typography variant="h4">
                    Time: {response?.drivingTimeMinutes ? `${response?.drivingTimeMinutes} mins` : '-'}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </form>

          <Map {...options} ref={mapRef} isLoading={isResponseLoading} preferCanvas>
            <TileLayer url={process.env.REACT_APP_MAPBOX} />
          </Map>
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
};
