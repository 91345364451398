/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { Form } from '@leaf/components';
import { disableEnterKey } from 'utility/disableEnterKey';
import { useStateMachine } from 'little-state-machine';
import { updateContract, defaultValues as resetValues } from '../domain/stateMachine';
import { FormContent, FormItem, FormFooter, FormActions } from '../partials/viewHelpers';

// HARDCODE: contract status and shopping status
const SHIPPER = 'SHIPPER';

const CONTRACT_STATUS_OPTIONS = Object.freeze(['DRAFT', 'CONTRACTED']);
const CONTRACT_TYPE_OPTIONS = Object.freeze([
  { label: 'Shipper Contract', value: SHIPPER },
  { label: 'LSP Contract', value: 'CARRIER' },
]);

export function GeneralForm({ next, abortContract }) {
  const { actions, getState } = useStateMachine({ updateContract });
  const { CONTRACT_FORM_OPTIONS, newContract: defaultValues } = getState();
  const [contractPartyList, setcontractPartyList] = useState({ data: [], type: null });

  const generateContractPartyOptions = useCallback(
    value => {
      if (value === null) return;
      const customer = value === SHIPPER ? 'buyerId' : 'sellerId';

      const companytype = CONTRACT_FORM_OPTIONS.contractParties.filter(company => {
        if (customer === 'buyerId') {
          // SHIPPER list
          return company.type.includes(value);
        }
        // CARRIER list (carrier & broker)
        return company.type.includes(value);
      });
      setcontractPartyList({ data: companytype, customer });
    },
    [CONTRACT_FORM_OPTIONS],
  );
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const watchCustomerId = watch('contractParty');
  const watchTypeOfContract = watch('contractPartyFlag');

  // capture asset pricing if they are set (edit/duplicating)
  const assetPricingCache = useRef({
    assetPricing: defaultValues.assetPricing,
    assetPricingFrequency: defaultValues.assetPricingFrequency,
    minChargeableMiles: defaultValues.minChargeableMiles,
    minChargeableMilesFrequency: defaultValues.minChargeableMilesFrequency,
  });

  const handleFormSubmit = formData => {
    actions.updateContract(formData);
    next();
  };

  useEffect(() => {
    // Generate options for Contract party dropdown
    if (defaultValues.contractPartyFlag !== '') {
      generateContractPartyOptions(defaultValues.contractPartyFlag);
    }
  }, [generateContractPartyOptions, defaultValues.contractPartyFlag]);

  useEffect(() => {
    // reset values in the machine state
    // when click on Cancel
    if (abortContract) {
      actions.updateAction(resetValues);
      reset(resetValues);
    }
  }, [abortContract, actions, reset]);

  useEffect(() => {
    if (watchTypeOfContract === SHIPPER) {
      setValue('isFleet', false);
      defaultValues.assetPricing = '';
      defaultValues.assetPricingFrequency = '';
      defaultValues.minChargeableMiles = '';
      defaultValues.minChargeableMilesFrequency = '';
    } else {
      setValue('isFleet', defaultValues.isFleet);
      defaultValues.assetPricing = assetPricingCache.current.assetPricing;
      defaultValues.assetPricingFrequency = assetPricingCache.current.assetPricingFrequency;
      defaultValues.minChargeableMiles = assetPricingCache.current.minChargeableMiles;
      defaultValues.minChargeableMilesFrequency = assetPricingCache.current.minChargeableMilesFrequency;
    }
  }, [assetPricingCache, defaultValues, setValue, watchTypeOfContract]);

  /**
   * @param {string} customerType is either "buyerId" or "sellerId" input field name
   * @returns {string } the customer ID or empty string
   */
  const determineCustomerTypeID = customerType => {
    if (contractPartyList.customer === customerType) {
      return watchCustomerId ? watchCustomerId.id : '';
    }
    return '';
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={e => disableEnterKey(e)}>
      <FormContent>
        <FormItem>
          <input type="hidden" name="id" defaultValue={defaultValues.id} ref={register()} />
          <Controller
            name="contractPartyFlag"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <RadioGroup
                onChange={(_, data) => {
                  onChange(_, data);
                  generateContractPartyOptions(data);
                  setValue('contractParty', '');
                }}
                value={value}
                row
                aria-labelledby="contract-type"
                sx={{ height: '51px' }}
              >
                {CONTRACT_TYPE_OPTIONS.map(option => (
                  <FormControlLabel key={option.value} label={option.label} value={option.value} control={<Radio />} />
                ))}
              </RadioGroup>
            )}
          />
          <input type="hidden" name="buyerId" ref={register()} defaultValue={determineCustomerTypeID('buyerId')} />
          <input type="hidden" name="sellerId" ref={register()} defaultValue={determineCustomerTypeID('sellerId')} />

          <Controller
            name="contractParty"
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={contractPartyList.data || []}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={option => option.label || option}
                disabled={!watchTypeOfContract}
                onChange={(_, data) => onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Contract Party*"
                    error={!!errors.contractParty}
                    helperText={errors.contractParty ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
          <Controller
            name="contractStatus"
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={CONTRACT_STATUS_OPTIONS}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={option => option}
                onChange={(_, data) => onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Contract Status*"
                    error={!!errors.contractStatus}
                    helperText={errors.contractStatus ? 'Required field' : null}
                  />
                )}
              />
            )}
          />

          <TextField
            type="text"
            label="Shippers Ops Plan ID"
            name="shipperRoutePlanId"
            defaultValue={defaultValues.shipperRoutePlanId}
            variant="outlined"
            inputRef={register}
            fullWidth
            disabled={watchTypeOfContract !== SHIPPER}
          />
          {watchTypeOfContract !== SHIPPER && (
            <FormControlLabel
              label="Fleet"
              control={
                <Controller
                  name="isFleet"
                  control={control}
                  render={props => (
                    <Checkbox {...props} checked={props.value} onChange={e => props.onChange(e.target.checked)} />
                  )}
                />
              }
            />
          )}
        </FormItem>

        <FormItem>
          <Controller
            name="equipmentTypeId"
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={CONTRACT_FORM_OPTIONS ? CONTRACT_FORM_OPTIONS.equipment : []}
                isOptionEqualToValue={(option, selected) => option.id === selected.id}
                getOptionLabel={option => option.label || option}
                onChange={(_, data) => onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Equipment Type"
                    error={!!errors.equipmentTypeId}
                    helperText={errors.equipmentTypeId ? 'Required field' : null}
                  />
                )}
              />
            )}
          />

          <Controller
            name="accessorialIds"
            defaultValue={defaultValues.accessorialIds}
            control={control}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={CONTRACT_FORM_OPTIONS ? CONTRACT_FORM_OPTIONS.accessorial : []}
                multiple
                filterSelectedOptions
                isOptionEqualToValue={(option, selected) => option.id === selected.id}
                getOptionLabel={option => option.label || option}
                onChange={(_, data) => onChange(data.map(option => option))}
                renderInput={params => <TextField {...params} variant="outlined" label="Accessorials" />}
              />
            )}
          />
          <TextField
            label="Notes"
            name="notes"
            defaultValue={defaultValues.notes}
            multiline
            rows={5}
            fullWidth
            inputRef={register}
            sx={{ '& textarea': { m: 2 } }}
          />
        </FormItem>
      </FormContent>
      <FormFooter requiredText="* Fields are required">
        <FormActions>
          <Button type="submit" variant="contained" size="large">
            continue
          </Button>
        </FormActions>
      </FormFooter>
    </form>
  );
}
