import { useLocation, NavLink } from 'react-router-dom';
import {
  EmojiObjects as EmojiObjectsIcon,
  ExitToApp,
  Dashboard,
  Explore,
  PendingActions as PlanningIcon,
  LocalShipping as ShippingIcon,
  Business,
  AssignmentTurnedIn as ContractsIcon,
} from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

import { isUserAllowedToAccessAdaptBoard } from 'features';

export default () => {
  const location = useLocation();
  const { logout, user } = useAuth0();

  return [
    {
      text: 'Reporting',
      icon: Dashboard,
      childs: [
        { to: '/dashboard', text: 'Dashboard' },
        { to: '/lanes', text: 'Lanes', show: false },
        { to: '/network-moves', text: 'Network moves', show: false },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Accounts',
      icon: Business,
      childs: [
        { to: '/companies', text: 'Companies' },
        { to: '/users', text: 'Users' },
        { to: '/locations', text: 'Locations', show: false },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Adapt',
      icon: Explore,
      childs: [
        { to: '/adapt/lanes', text: 'Lanes' },
        { to: '/adapt/network-moves', text: 'Network Moves' },
        { to: '/adapt/board', text: 'Board', show: isUserAllowedToAccessAdaptBoard(user?.email) },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Planning',
      icon: PlanningIcon,
      childs: [
        { to: '/planning/ops-plans', text: 'Ops Plans' },
        { to: '/planning/fleet-discovery', text: 'Fleet Discovery' },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Contracting',
      to: '/contracts',
      icon: ContractsIcon,
      childs: [
        { to: '/contracts', text: 'Contracts' },
        { to: '/contract-matching', text: 'Contract Matching' },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'Execution',
      icon: ShippingIcon,
      childs: [
        { to: '/shipments', text: 'Shipments' },
        { to: '/partner-locations-execution', text: 'Location Mapping' },
        { to: '/fuel-prices', text: 'Fuel prices' },
        { to: '/execution-locations', text: 'Locations' },
      ],
      link: NavLink,
      location,
    },
    {
      text: 'EXPERIMENTAL',
      to: '/experimental',
      icon: EmojiObjectsIcon,
      link: NavLink,
    },
    {
      text: 'LOGOUT',
      icon: ExitToApp,
      click: () => {
        logout({
          returnTo: window.location.origin,
        });
      },
    },
  ];
};
