import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';
import {
  Page,
  Tabs,
  BackButton,
  PageContentLayout,
  SpacedContainer,
  PageContainer,
  DropdownMenu,
  CompanyAvatar,
  Lane,
  ConfirmationDialog,
  RoutesOverview,
} from '@leaf/components';

import useGQL from 'hooks/useGQL';
import Ops from 'domain/planning/Ops';
import OpsPlansDetailsGeneral from './OpsPlansDetailsGeneral';
import OpsPlansDetailsContracts from './OpsPlansDetailsContracts';
import OpsPlansDetailsEdit from './OpsPlansDetailsEdit';
import { getOpsPlanDetails } from './domain/opsPlanDetailsModel';

const BoxWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const RouteHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RouteItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const OpsPlansDetails = ({
  match: {
    params: { id },
  },
  history,
  location,
}) => {
  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();

  const [opsPlan, setOpsPlan] = useState();
  const [editOpsPlan, setEditOpsPlan] = useState(false);
  const [deleteOpsPlan, setDeleteOpsPlan] = useState(false);

  useEffect(() => {
    getOpsPlanDetails(getGQLClient, id).then(setOpsPlan).catch(enqueueSnackbar);
  }, [id]);

  const getTabs = useCallback(
    () =>
      !editOpsPlan
        ? [
            { label: 'Details', content: <OpsPlansDetailsGeneral opsPlan={opsPlan} /> },
            { label: 'Route', content: <RoutesOverview routesData={opsPlan} /> },
            { label: 'Contracts', content: <OpsPlansDetailsContracts opsPlan={opsPlan} /> },
          ]
        : [
            {
              label: 'Details',
              content: <OpsPlansDetailsEdit opsPlan={opsPlan} setEditOpsPlan={setEditOpsPlan} />,
            },
          ],
    [opsPlan, editOpsPlan],
  );

  const handleEditOpsPlan = () => {
    if (opsPlan) {
      setEditOpsPlan(true);
    }
  };

  const handleDeleteOpsPlan = () => {
    if (opsPlan) {
      Ops.deleteShipperOpsPlan(opsPlan.id)
        .then(() => {
          enqueueSnackbar(`Successfully deleted ops plan!`, { variant: 'success' });
          history.push('/planning/ops-plans');
        })
        .catch(enqueueSnackbar);
    }
  };

  return (
    <Page
      ComponentTitleLeft={
        <RouteHeader>
          <BackButton history={history} path="/planning/ops-plans" />
          {opsPlan && (
            <>
              <CompanyAvatar id={opsPlan.shipper.id} isChip />
              <RouteItem>
                <Lane
                  origin={{ primaryContent: [opsPlan.route.legs?.[0].lane.originName] }}
                  destination={{
                    primaryContent: [opsPlan.route.legs?.[0].lane.destinationName],
                  }}
                  fullWidth={false}
                />
                <Typography variant="body1">{`Shipper Ops Plan ID #${id}`}</Typography>
              </RouteItem>
            </>
          )}
        </RouteHeader>
      }
      UserDescription={
        <BoxWrapper>
          <DropdownMenu
            options={[
              { label: 'Edit Ops Plan', onClick: handleEditOpsPlan },
              { label: 'Delete Ops Plan', onClick: () => setDeleteOpsPlan(true) },
            ]}
          />
        </BoxWrapper>
      }
      isUppercase={false}
    >
      <PageContainer>
        <SpacedContainer container spacing={2} autoWidth>
          <Grid item xs={12}>
            <PageContentLayout withoutSpacing fullHeight={false}>
              <Tabs location={location} history={history} mainUrlPath={`/planning/ops-plans/${id}`} tabs={getTabs()} />
            </PageContentLayout>
          </Grid>
        </SpacedContainer>
      </PageContainer>
      <ConfirmationDialog
        title="Delete Ops Plan"
        message="Are you sure you want to delete this plan? You will not be able to recover it once this action is completed!"
        open={opsPlan && deleteOpsPlan}
        handleConfirmation={handleDeleteOpsPlan}
        handleClose={() => setDeleteOpsPlan(false)}
      />
    </Page>
  );
};

export default withRouter(OpsPlansDetails);
