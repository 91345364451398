import { Card, Grid, CardContent, Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';

import { Page, useCopyToClipboard } from '@leaf/components';

import Beta from 'components/Beta';
import { withRouter } from 'react-router-dom';

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  cursor: pointer;

  &:hover {
    background-color: rgb(0, 0, 0, 0.1);
  }
`;

const StyledGrid = styled(Grid)`
  margin: ${({ theme }) => theme.spacing(3)};
  height: calc(100vh - 60px);
  align-content: flex-start;
`;

export { default as UploadView } from './upload';
export { default as ShipperNetworkView } from './shipper-network';
export { default as GridExplorerView } from './grid-explorer';
export { default as AdaptStatus } from './adapt-status';

export default withRouter(({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const copy = useCopyToClipboard(
    successMessage => enqueueSnackbar(successMessage, { variant: 'success' }),
    errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' }),
    'Copied JWT to clipboard',
    'Failed to copy JWT to clipboard',
  );
  return (
    <Page title="Experimental" ComponentTitleRight={<Beta />}>
      <StyledGrid container spacing={6}>
        <Grid item xs={6}>
          <StyledCard onClick={() => copy(localStorage.getItem('jwt'))}>
            <CardContent>
              <Typography variant="h5">Copy JWT to clipboard</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={6}>
          <StyledCard onClick={() => history.push('/experimental/adapt-upload')}>
            <CardContent>
              <Typography variant="h5">Adapt upload</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={6}>
          <StyledCard onClick={() => history.push('/experimental/shipper-network')}>
            <CardContent>
              <Typography variant="h5">Shipper network</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={6}>
          <StyledCard onClick={() => history.push('/experimental/grid-explorer')}>
            <CardContent>
              <Typography variant="h5">Grid explorer</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={6}>
          <StyledCard onClick={() => history.push('/experimental/pc-miler-distance-lookup')}>
            <CardContent>
              <Typography variant="h5">PC*Miler Distance Lookup</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={6}>
          <StyledCard onClick={() => history.push('/experimental/adapt-status')}>
            <CardContent>
              <Typography variant="h5">Adapt Status</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </StyledGrid>
    </Page>
  );
});
