import { gql } from 'graphql-request';

export const GQL_CONTRACT_BY_ID = gql`
  query contractById($_eq: uuid = "") {
    contract(where: { id: { _eq: $_eq } }) {
      id
      linehaulRpm: linehaul_rpm
      fscId: fsc_id
      sellerId: seller_id
      assetPricing: asset_pricing
      buyerId: buyer_id
      contractStatus: contract_status
      contractType: contract_type
      endDate: end_date
      includeWeekends: include_weekends
      maxVolume: max_volume
      minChargeableMiles: min_chargeable_miles
      minChargeableMilesFrequency: min_chargeable_miles_frequency
      minVolume: min_volume
      notes
      pricingIndex: pricing_index
      pricingIndexPremium: pricing_index_premium
      pricingMechanism: pricing_mechanism
      startDate: start_date
      volumeFrequency: volume_frequency
      volumePattern: volume_pattern
      shipperRoutePlans: shipper_route_plans {
        id: shipper_route_plan_id
      }
      isFleet: is_fleet
      accessorialIds: contract_accessorials {
        accessorial {
          id
          label: name
        }
      }
      volumeType: volume_type
      assetPricingFrequency: asset_pricing_frequency
      equipmentTypeId: equipment_type {
        id
        label: name
      }
      contractRoutes: contract_routes {
        isPrimary: is_primary
        route {
          id
        }
      }
    }
  }
`;
