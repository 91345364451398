import React from 'react';
import { Typography, Chip } from '@mui/material';
import styled from '@emotion/styled';

const Beta = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

/** DEPRECATED Use same component, but from 'components' package */
export default () => <Beta label={<Typography variant="caption">BETA</Typography>} color="primary" size="small" />;
