import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { includes } from 'lodash';

import { utility, USER_TYPE, USER_ROLE } from '@leaf/components';

import { Input, Switch, Select, AutocompleteWithFormik, Datepicker } from 'components/form/with-formik';
import WithGridLayout from 'components/form/WithGridLayout';
import { notANumberMessage } from 'form/validations';
import { emptyToNull } from 'utility/types/string';
import { geoStates } from 'constants/states';
import { sortByField } from 'utility/types/array';

import AdaptCompanyData from 'domain/company/AdaptCompanyData';

import { useSnackbar } from 'notistack';
import { onChange } from 'utility/form';
import { Autocomplete } from 'components/form';
import useGQL from 'hooks/useGQL';

import { getUsers, getAccountTeamMembersByCompany } from './overview/domain/companyModel';

const DialogContentWithoutScroll = styled(DialogContent)`
  overflow-y: hidden;
`;

const InputWrapper = styled(Input)`
  .MuiInputBase-root {
    padding: 8px !important;
  }
`;

const validationSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  transactionFee: yup
    .number()
    .transform(emptyToNull)
    .nullable()
    .typeError(notANumberMessage)
    .min(0)
    .max(100)
    .required(),
});

const companyTypeOptions = [USER_TYPE.SHIPPER, USER_TYPE.CARRIER];

export default React.memo(({ onClose, open, handleCreateCompany, editCompany }) => {
  const defaults = {
    type: companyTypeOptions[0],
    hasAdapt: false,
    use4KitesIntegration: false,
    useTrimbleIntegration: false,
    useProject44Integration: false,
  };

  const getGQLClient = useGQL();
  const [isCarrier, setIsCarrier] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(defaults);
  const [leAdmins, setLeAdmins] = React.useState([]);
  const [areUsDotNumberAndUsMcNumberPopulated, setAreUsDotNumberAndUsMcNumberPopulated] = React.useState(false);
  const [accountTeamMembers, setAccountTeamMembers] = React.useState([]);

  const [companyData, setCompanyData] = React.useState({
    runDate: null,
    windowStartDate: null,
    windowEndDate: null,
    hasAdapt: null,
  });

  const leAdminsSorted = leAdmins?.sort(sortByField('firstName'));

  const handleCompanySave = (values, { resetForm }) => {
    const runDate = utility.date.transformApiDate(values.runDate ?? companyData.runDate);
    const windowStartDate = utility.date.transformApiDate(values.windowStartDate ?? companyData.windowStartDate);
    const windowEndDate = utility.date.transformApiDate(values.windowEndDate ?? companyData.windowEndDate);
    const adaptData = {
      runDate,
      windowStartDate,
      windowEndDate,
      hasAdapt: values.hasAdapt,
    };
    if (editCompany?.id) {
      AdaptCompanyData.write(editCompany?.id, adaptData);
    }
    handleCreateCompany({ ...values, accountTeamMembers }, resetForm, adaptData);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleCompanySave,
    enableReinitialize: true,
  });

  // accountTeamMembers are coming through separate EP (not through GET companies EP)
  const getAccountTeamMembers = async id => {
    getAccountTeamMembersByCompany(getGQLClient, id).then(response => {
      setAccountTeamMembers(response);
    });
  };
  const resetAccountTeamMembers = () => setAccountTeamMembers([]);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    getUsers(getGQLClient, USER_ROLE.LE_ADMIN).then(resp => {
      setLeAdmins(resp);
    });

    if (editCompany) {
      AdaptCompanyData.read(editCompany.id).then(response => {
        setCompanyData(response);
        formik.setFieldValue('hasAdapt', response.hasAdapt);
      });
    }
  }, []);

  React.useEffect(() => {
    if (editCompany) {
      getAccountTeamMembers(editCompany.id).catch(enqueueSnackbar);
      setInitialValues({ ...editCompany, accountTeamMembers, ...companyData });
    } else {
      resetAccountTeamMembers();
      setInitialValues(defaults);
    }
  }, [editCompany, editCompany?.id, companyData]);

  React.useEffect(() => {
    if (formik.values.type === USER_TYPE.CARRIER) {
      setIsCarrier(true);
    } else {
      setIsCarrier(false);
    }
  }, [formik.values.type]);

  React.useEffect(() => {
    if (formik.values.usDotNumber && formik.values.usMcNumber) {
      setAreUsDotNumberAndUsMcNumberPopulated(true);
    } else {
      setAreUsDotNumberAndUsMcNumberPopulated(false);
      formik.setFieldValue('useProject44Integration', false);
    }
  }, [formik.values.usDotNumber, formik.values.usMcNumber, formik.setFieldValue]);

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog onClose={handleClose} fullWidth open={open}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Company</DialogTitle>
        <DialogContentWithoutScroll>
          <Grid container spacing={2}>
            <WithGridLayout
              isAlignedFlexEnd
              components={{
                1: <Input formik={formik} variant="standard" name="name" label="Name" required />,
                2: (
                  <Select
                    formik={formik}
                    variant="standard"
                    name="type"
                    options={companyTypeOptions}
                    disabled={!!editCompany}
                  />
                ),
              }}
            />
            <WithGridLayout
              components={{
                1: (
                  <Input
                    formik={formik}
                    variant="standard"
                    name="transactionFee"
                    label="Transaction fee [%]"
                    type="number"
                    required
                  />
                ),
                2: <Input formik={formik} variant="standard" name="phone" label="Phone" />,
              }}
            />
            <WithGridLayout
              components={{
                1: <InputWrapper formik={formik} variant="standard" name="registeredName" label="Registered name" />,
                2: (
                  <AutocompleteWithFormik
                    fieldVariant="standard"
                    name="registeredState"
                    label="Registered state"
                    options={geoStates}
                    getOptionLabel={option => option.content}
                    defaultValue={geoStates?.find(state =>
                      includes(state.content.toLowerCase(), editCompany?.registeredState?.toLowerCase()),
                    )}
                    formik={formik}
                    disableClearable
                  />
                ),
              }}
            />
            <WithGridLayout
              components={{
                1: <Input formik={formik} variant="standard" name="tenderEmailAddress" label="Tender E-mail address" />,
                2: <Input formik={formik} variant="standard" name="tenderPhoneNumber" label="Tender Phone number" />,
              }}
            />
            <WithGridLayout
              components={{
                1: (
                  <Autocomplete
                    variant="standard"
                    name="accountTeamMembers"
                    label="Account team members"
                    options={leAdminsSorted}
                    getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                    isOptionEqualToValue={option => accountTeamMembers?.map(member => member.id).includes(option.id)}
                    onChange={onChange(setAccountTeamMembers)}
                    value={accountTeamMembers}
                    multiple
                  />
                ),
              }}
            />
            {!!editCompany && !isCarrier && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">Shipper-specific fields</Typography>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={4}>
                    <Datepicker name="runDate" label="Run date" inputVariant="outlined" formik={formik} />
                  </Grid>

                  <Grid item xs={4}>
                    <Datepicker
                      name="windowStartDate"
                      label="Window start date"
                      inputVariant="outlined"
                      formik={formik}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Datepicker name="windowEndDate" label="Window end date" inputVariant="outlined" formik={formik} />
                  </Grid>
                </Grid>

                <WithGridLayout
                  components={{
                    1: <Switch name="hasAdapt" label="Adapt" formik={formik} />,
                  }}
                />

                <WithGridLayout
                  components={{
                    1: <Switch name="use4KitesIntegration" label="4Kites integration" formik={formik} />,
                  }}
                />

                <WithGridLayout
                  components={{
                    1: <Switch name="useTrimbleIntegration" label="Trimble integration" formik={formik} />,
                  }}
                />
              </>
            )}
            {editCompany && isCarrier && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">Carrier-specific fields</Typography>
                </Grid>

                <WithGridLayout
                  components={{
                    1: <Input formik={formik} variant="standard" name="scacCode" label="SCAC code" />,
                  }}
                />
                <WithGridLayout
                  components={{
                    1: <Input formik={formik} variant="standard" name="usDotNumber" label="DOT number" />,
                    2: <Input formik={formik} variant="standard" name="usMcNumber" label="MC number" />,
                  }}
                />
                <WithGridLayout
                  components={{
                    1: (
                      <Switch
                        name="useProject44Integration"
                        label="Project44 integration"
                        formik={formik}
                        disabled={!areUsDotNumberAndUsMcNumberPopulated}
                      />
                    ),
                  }}
                />
              </>
            )}
          </Grid>
        </DialogContentWithoutScroll>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>

          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});
