import { ON_TIME_STATUS, utility, CONTRACT_TYPE } from '@leaf/components';
import {
  getDestinationsGQL,
  getLegNumbersGQL,
  getOriginsGQL,
  getShipmenstGQL,
  getExecutionPartnerNamesGQL,
  getCarriersGQL,
  getShippersGQL,
  getEquipmentTypeGQL,
  getPickupDatesGQL,
} from './GQL_SHIPMENTS';

const calculateStatus = (arrivalTime, appointmentTime) => {
  if (appointmentTime == null || arrivalTime == null) {
    return ON_TIME_STATUS.NOT_AVAILABLE;
  }

  return utility.date.isDateBefore(
    utility.date.addMinutesOnDateTime(new Date(appointmentTime), 120),
    new Date(arrivalTime),
  )
    ? ON_TIME_STATUS.LATE
    : ON_TIME_STATUS.ON_TIME;
};

const mapShipments = shipments =>
  shipments.map(shipment => ({
    ...shipment,
    shipper: shipment.company,
    carrier: shipment.carrierTripTender?.company,
    equipmentType: shipment.equipmentType?.name,
    lanes: {
      origin: {
        primaryContent: `${shipment.origin?.city}, ${shipment.origin?.state} ${shipment.origin?.zip}`,
        secondaryContent: shipment.origin?.businessName,
      },
      destination: {
        primaryContent: `${shipment.destination?.city}, ${shipment.destination?.state} ${shipment.destination?.zip}`,
        secondaryContent: shipment.destination?.businessName,
      },
    },
    executionPartnerName: shipment.executionPartnerCompany?.name,
    contractType: {
      id: shipment.carrierTripTender?.id,
      type: shipment.carrierTripTender?.sellContract?.contractType,
      spotConfirmed: shipment.carrierTripTender?.spotConfirmed,
    },
    pickupAppointment: utility.date.formatDate(new Date(shipment?.originAppointmentTime), utility.date.VIEW_FORMAT),
    pickupStatus: calculateStatus(shipment.originArrivalTime, shipment.originAppointmentTime),
    deliveryStatus: calculateStatus(shipment.destinationArrivalTime, shipment.destinationAppointmentTime),
    tripId: shipment?.carrierTripTender?.executionPartnerShipmentId,
  }));

const getOrderBy = sort => {
  if (sort) {
    if (sort.field === 'company') {
      return [{ [sort?.field]: { name: sort.order } }];
    }
    if (sort.field === 'carrier_trip_tender') {
      return [{ [sort?.field]: { company: { name: sort.order } } }];
    }
    if (sort.field === 'origin_appointment_time') {
      return [{ [sort?.field]: sort.order === 'asc' ? 'asc_nulls_last' : 'desc_nulls_last' }];
    }
    return [{ [sort.field ?? sort.name]: sort.order }];
  }
  return null;
};

export const getShipments = (getGQLClient, tableState) => {
  let contract_type_filter;
  if (tableState.filters.contractType) {
    if (tableState.filters.contractType[0] === CONTRACT_TYPE.OTHER) {
      contract_type_filter = { contract_type_other: [true] };
    } else {
      contract_type_filter = { contract_type: tableState.filters.contractType };
    }
  }
  const variables = {
    sort: tableState?.sort,
    limit: tableState.rowsPerPage,
    offset: tableState.page * tableState.rowsPerPage,
    search: tableState.search,
    where: {
      status: tableState.filters.shipmentExecutionStatus ? tableState.filters.shipmentExecutionStatus : undefined,
      legNumber: tableState.filters.legNumber ? tableState.filters.legNumber : undefined,
      execution_partner_name: tableState.filters.executionPartnerName
        ? tableState.filters.executionPartnerName
        : undefined,
      shipper: tableState.filters.company ? tableState.filters.company : undefined,
      carrier: tableState.filters.carrier_trip_tender ? tableState.filters.carrier_trip_tender : undefined,
      origin: tableState.filters.origin ? tableState.filters.origin : undefined,
      destination: tableState.filters.destination ? tableState.filters.destination : undefined,
      equipment_type: tableState.filters.equipmentType ? tableState.filters.equipmentType : undefined,
      origin_appointment_time: tableState.filters.origin_appointment_time
        ? tableState.filters.origin_appointment_time
        : undefined,
      ...contract_type_filter,
      status_active:
        tableState.quickFilters.statusQuickFilter?.length === 1 &&
        tableState.quickFilters.statusQuickFilter[0] === 'ACTIVE'
          ? tableState.quickFilters.statusQuickFilter
          : undefined,
      status_canceled:
        tableState.quickFilters.statusQuickFilter?.length === 1 &&
        tableState.quickFilters.statusQuickFilter[0] === 'CANCELED'
          ? tableState.quickFilters.statusQuickFilter
          : undefined,
    },
  };

  const { where, search, sort, ...rest } = variables;

  return getGQLClient().then(client => {
    const GQL = getShipmenstGQL({
      where,
      search,
      ...rest,
    });

    // Exclude where variables unused in query
    delete where.status_active;
    delete where.status_canceled;

    return client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then(res => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.shipmentTenderAggregate.aggregate.count,
      data: mapShipments(res.shipmentTender),
    }));
  });
};

export const getCarriers = getGQLClient =>
  getGQLClient().then(client =>
    client.request(getCarriersGQL()).then(res =>
      res.shipmentTender.map(e => ({
        label: e.carrierTripTender?.company?.name,
        value: e.carrierTripTender?.company?.id,
      })),
    ),
  );

export const getShippers = getGQLClient =>
  getGQLClient().then(client =>
    client
      .request(getShippersGQL())
      .then(res => res.shipmentTender.map(e => ({ label: e.company?.name, value: e.company?.id }))),
  );

export const getExecutionPartnerNames = getGQLClient =>
  getGQLClient().then(client =>
    client
      .request(getExecutionPartnerNamesGQL())
      .then(res =>
        res.shipmentTender.map(e => ({ label: e.executionPartnerCompany.name, value: e.executionPartnerCompany.id })),
      ),
  );

export const getLegNumbers = getGQLClient =>
  getGQLClient().then(client =>
    client
      .request(getLegNumbersGQL())
      .then(res => res.shipmentTender.map(e => ({ label: e.legNumber, value: e.legNumber }))),
  );

export const getOrigins = getGQLClient =>
  getGQLClient().then(client =>
    client.request(getOriginsGQL()).then(res =>
      res.shipmentTender.map(e => ({
        label: `${e.businessName}, ${e.city}, ${e.state} ${e.zip}`,
        value: e?.origin?.id,
      })),
    ),
  );

export const getDestinations = getGQLClient =>
  getGQLClient().then(client =>
    client.request(getDestinationsGQL()).then(res =>
      res.shipmentTender.map(e => ({
        label: `${e.businessName}, ${e.city}, ${e.state} ${e.zip}`,
        value: e?.destination?.id,
      })),
    ),
  );

export const getEquipmentTypes = getGQLClient =>
  getGQLClient().then(client =>
    client.request(getEquipmentTypeGQL()).then(res =>
      res.shipmentTender.map(e => ({
        label: e.equipmentType?.name,
        value: e.equipmentType?.id,
      })),
    ),
  );

export const getPickupDates = getGQLClient =>
  getGQLClient().then(client =>
    client.request(getPickupDatesGQL()).then(res =>
      res.shipmentTender.map(e => ({
        label: utility.datetime.formatBackendDatetime(e.originAppointmentTime),
        value: e.originAppointmentTime,
      })),
    ),
  );
