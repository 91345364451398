const AUTH0_METADATA_NAMESPACE = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/metadata`;

const parseJwt = token => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    return undefined;
  }
};

export const getCurrentUserMetadata = token => {
  const data = parseJwt(token);
  return data[AUTH0_METADATA_NAMESPACE]?.app_metadata ?? {};
};
