import React, { forwardRef } from 'react';

export default forwardRef(() => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M11.1238 6.09522C11.1238 6.01935 11.0968 5.94601 11.0472 5.88855L7.23335 1.44411C7.17296 1.37363 7.08492 1.33331 6.99212 1.33331L2.22476 1.33331C2.04901 1.33331 1.90694 1.47554 1.90694 1.65077L1.90694 7.36506H1.58912C1.41336 7.36506 1.27129 7.50728 1.27129 7.68252L1.27129 10.9682C0.531719 11.2305 0 11.9343 0 12.7619C0 13.8124 0.855263 14.6666 1.90694 14.6666C2.95862 14.6666 3.81388 13.8124 3.81388 12.7619L8.62923 12.7619C8.85075 13.8473 9.81376 14.6666 10.9649 14.6666C12.1161 14.6666 13.0791 13.8473 13.3006 12.7619L16 12.7619V12.127L13.3406 12.127C13.2933 11.4159 12.9332 10.7905 12.3951 10.3854L12.3951 1.65077L11.7595 1.65077L11.7595 10.0438C11.5538 9.97077 11.3361 9.92601 11.1092 9.91236L11.1238 9.90474L11.1238 6.09522ZM2.54259 1.96823L6.84592 1.96823L10.4882 6.21268L10.4882 9.95268C10.2352 10.0041 9.99778 10.0974 9.7807 10.2222L7.85692 10.2222L6.97591 7.5822C6.93268 7.45236 6.81128 7.36506 6.67429 7.36506L2.54259 7.36506L2.54259 1.96823ZM1.90694 14.0317C1.20582 14.0317 0.635647 13.4622 0.635647 12.7619C0.635647 12.0616 1.20582 11.492 1.90694 11.492C2.60806 11.492 3.17824 12.0616 3.17824 12.7619C3.17824 13.4622 2.60806 14.0317 1.90694 14.0317ZM3.70264 12.127C3.44012 11.3882 2.73551 10.8571 1.90694 10.8571L1.90694 7.99998L6.44514 7.99998L7.32615 10.64C7.36969 10.7698 7.49078 10.8571 7.62777 10.8571L9.06242 10.8571C8.79163 11.2159 8.62065 11.652 8.58918 12.127L3.70264 12.127ZM12.7129 12.2857C12.7129 13.2486 11.9286 14.0317 10.9649 14.0317C10.0013 14.0317 9.21688 13.2486 9.21688 12.2857C9.21688 11.3228 10.0013 10.5397 10.9649 10.5397C11.9286 10.5397 12.7129 11.3228 12.7129 12.2857Z"
        fill="#333333"
        strokeWidth={2}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(16) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
));
