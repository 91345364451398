import { utility, CONTRACT_TYPE } from '@leaf/components';
import { GQL_EQUIPMENT_TYPES } from 'graphql/filter-queries/GQL_EQUIPMENT_TYPES';
import { GQL_ACCESSORIALS } from 'graphql/filter-queries/GQL_ACCESSORIALS';
import { GQL_COMPANIES } from 'graphql/filter-queries/GQL_COMPANIES';
import { getContractMatchesGQL } from './GQL_CONTRACT_MATCHING';

const mapContractMatches = contractMatches =>
  contractMatches.map(c => ({
    ...c,
    lspContractType:
      c.sell_contract.contract_type === CONTRACT_TYPE.DEDICATED && c.sell_contract.is_fleet
        ? CONTRACT_TYPE.FLEET
        : c.sell_contract.contract_type,
    contractRoute: c.buy_contract.contract_routes[0].route,
    startDate: c.start_date ?? c.contract_match_volumes.start_date,
    endDate: c.end_date ?? c.contract_match_volumes.end_date,
  }));

const getOrderBy = sort => {
  if (sort) {
    return [{ [sort.field ?? sort.name]: sort.order }];
  }
  return null;
};

const getDate = (dates, index) => {
  if (dates?.length === 2) {
    return Array.isArray(dates[index]) ? dates[index][0] : dates[index];
  }
  return undefined;
};

export const getContractMatches = (getGQLClient, tableState, forDownload = false) => {
  const rangeStartDate = getDate(tableState.filters.rangeDate, 0);
  const rangeEndDate = getDate(tableState.filters.rangeDate, 1);
  const hasRangeDates = !!rangeStartDate || !!rangeEndDate;
  const rangeDates = {
    range_start_date: !rangeStartDate ? utility.date.transformApiDate(new Date()) : rangeStartDate,
    range_end_date: !rangeEndDate ? utility.date.transformApiDate(new Date()) : rangeEndDate,
  };
  const variables = {
    sort: tableState.sort,
    search: tableState.search,
    ...(!forDownload && {
      limit: tableState.rowsPerPage,
      offset: tableState.page * tableState.rowsPerPage,
    }),
    // NOTE: field names need to be snake case for correct mapping, with GraphQL, to be achieved
    where: {
      buyer_ids: tableState.filters.shipperName?.length ? tableState.filters.shipperName : undefined,
      seller_ids: tableState.filters.lspName?.length ? tableState.filters.lspName : undefined,
      range_assigned_weekly_volume_min: tableState.filters.rangeAssignedWeeklyVolume?.length // eslint-disable-line no-nested-ternary
        ? tableState.filters.rangeAssignedWeeklyVolume[0]
        : tableState.sort?.name === 'assigned_weekly_volume'
        ? 0
        : undefined,
      range_assigned_weekly_volume_max: tableState.filters.rangeAssignedWeeklyVolume?.length
        ? tableState.filters.rangeAssignedWeeklyVolume[1]
        : undefined,
      ...(hasRangeDates && rangeDates),
    },
    varsToRemap: {
      is_enabled: tableState.quickFilters.isEnabled?.length ? tableState.quickFilters.isEnabled : undefined,
      time_statuses: tableState.quickFilters.timeStatuses?.length ? tableState.quickFilters.timeStatuses : undefined,
      lsp_contract_types: tableState.filters.lspContractType?.length ? tableState.filters.lspContractType : undefined,
    },
    gqlQuickFiltersMeta: tableState.gqlQuickFiltersMeta,
  };
  const { varsToRemap, where, gqlQuickFiltersMeta, search, sort, ...rest } = variables;

  const GQL = getContractMatchesGQL({
    varsToRemap,
    where,
    gqlQuickFiltersMeta,
    search,
    ...rest,
  });
  return getGQLClient().then(client =>
    client.request(GQL, { ...where, ...rest, orderBy: getOrderBy(sort) }).then(res => ({
      limit: rest.limit,
      offset: rest.offset,
      orderBy: getOrderBy(sort),
      total: res.contract_match_aggregate.aggregate.count,
      data: mapContractMatches(res.contract_match),
    })),
  );
};

/** For isNestedQuery = true, you have to specify an explicit query string. Otherwise a query will be generated from the returned array */
export const contractMatchingQuickFilters = {
  is_enabled: {
    metaGenerator: selectedValues => {
      const yes = [{ field: 'is_enabled', name: '_eq', value: 'true', type: 'boolean' }];
      const no = [{ field: 'is_enabled', name: '_eq', value: 'false', type: 'boolean' }];
      return [...(selectedValues.includes('YES') ? [yes] : []), ...(selectedValues.includes('NO') ? [no] : [])];
    },
  },
  time_statuses: {
    metaGenerator: selectedValues => {
      const now = utility.date.transformApiDate(new Date());
      const past = [
        {
          query: `{_or: [{end_date: { _lte: "${now}" }}, {_and: [{end_date: { _is_null: true }}, {contract_match_volumes: {end_date: { _lte: "${now}" }}}]}]}`,
        },
      ];
      const ongoing = [
        {
          query: `{_or: [{end_date: { _gte: "${now}" }}, {_and: [{end_date: { _is_null: true }}, {contract_match_volumes: {end_date: { _gte: "${now}" }}}]}]}`,
        },
        {
          query: `{_or: [{start_date: { _lte: "${now}" }}, {_and: [{start_date: { _is_null: true }}, {contract_match_volumes: {start_date: { _lte: "${now}" }}}]}]}`,
        },
      ];
      const future = [
        {
          query: `{_or: [{start_date: { _gte: "${now}" }}, {_and: [{start_date: { _is_null: true }}, {contract_match_volumes: {start_date: { _gte: "${now}" }}}]}]}`,
        },
      ];
      return [
        ...(selectedValues.includes('PAST') ? [past] : []),
        ...(selectedValues.includes('ONGOING') ? [ongoing] : []),
        ...(selectedValues.includes('FUTURE') ? [future] : []),
      ];
    },
  },
  lsp_contract_types: {
    metaGenerator: selectedValues => {
      if (!selectedValues) {
        return [[]];
      }
      return selectedValues.map(type =>
        type === CONTRACT_TYPE.DEDICATED || type === CONTRACT_TYPE.FLEET
          ? [
              {
                query: `{_and: [{sell_contract: {contract_type: { _eq: "${
                  CONTRACT_TYPE.DEDICATED
                }" }}}, {sell_contract: {is_fleet: { _eq: ${type === CONTRACT_TYPE.FLEET} }}}]}`,
              },
            ]
          : [{ query: `{sell_contract: {contract_type: { _eq: "${type}" }}}` }],
      );
    },
  },
};

export const getEquipmentTypes = getGQLClient =>
  getGQLClient().then(client =>
    client.request(GQL_EQUIPMENT_TYPES).then(res => res.equipment_type.map(e => ({ label: e.name, value: e.id }))),
  );

export const getAccessorials = getGQLClient =>
  getGQLClient().then(client =>
    client.request(GQL_ACCESSORIALS).then(res => res.accessorial.map(e => ({ label: e.name, value: e.id }))),
  );

export const getCompanies = getGQLClient =>
  getGQLClient().then(client =>
    client.request(GQL_COMPANIES).then(res => res.company.map(e => ({ label: e.name, value: e.id }))),
  );
