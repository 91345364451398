import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import { utility } from '@leaf/components';

const { ActionButtons } = utility.table;

export const getOptions = handleAddOpen => ({
  selectableRows: 'none',
  print: false,
  filter: true,
  download: false,
  ...utility.table.getRowHoverActionProps('location'),
  customToolbar: () => (
    <Tooltip title="Add New Location">
      <IconButton onClick={handleAddOpen}>
        <Add />
      </IconButton>
    </Tooltip>
  ),
});

export const getColumns = (filterOptions, handleEditOpen) => [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: 'Shipper name',
    name: 'shipperName',
    options: {
      ...filterOptions.shipperName,
      sort: true,
    },
  },
  { label: 'City', name: 'city', options: { filter: false, sort: true } },
  {
    label: 'Address',
    name: 'addressName',
    field: 'addressName',
    options: {
      sort: true,
    },
  },
  {
    label: 'Consignor/Consignee',
    name: 'businessName',
    field: 'businessName',
    options: { filter: false, sort: true },
  },
  {
    label: 'Location Type',
    name: 'locationType',
    field: 'locationType',
    options: {
      ...filterOptions.locationType,
      sort: true,
    },
  },
  {
    label: 'Shipper ID',
    name: 'shipperId',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    label: ' ',
    name: 'actionsData',
    options: {
      sort: false,
      filter: false,
      viewColumns: false,
      ...utility.table.rightStickyColumnProps,
      customBodyRender: actionsData => {
        const { id } = actionsData;
        return (
          <ActionButtons id={`Actions-location-${id}`}>
            <Tooltip title="Edit location">
              <IconButton onClick={() => handleEditOpen(id)}>
                <Edit />
              </IconButton>
            </Tooltip>
          </ActionButtons>
        );
      },
    },
  },
];
