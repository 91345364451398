import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { OverviewTable, PageContentLayout } from '@leaf/components';
import Company from 'domain/company/Company';
import CardActions from 'components/CardActions';
import useGQL from 'hooks/useGQL';
import AddEditSfscDialog from './AddEditSfscDialog';
import { getCompanyFSC } from './domain/companyFSCmodel';

const columnsStatic = [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  { label: 'Name', name: 'name', options: { filter: true, sort: true } },
  { label: 'Fuel Price Index', name: 'fuelPriceIndexName' },
  { label: 'PEG', name: 'peg', options: { filter: true, sort: true } },
  { label: 'Increment', name: 'increment', options: { filter: true, sort: true } },
  { label: 'Bucket size', name: 'bucketSize', options: { filter: true, sort: true } },
  { label: 'First step zero', name: 'isFirstStepZero', options: { filter: true, sort: true } },
];

export default ({ companyId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const getGQLClient = useGQL();
  const [open, setOpen] = React.useState(false);
  const [shipperFuelSurcharges, setShipperFuelSurcharges] = React.useState([]);
  const [selectedSfsc, setSelectedSfsc] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const getShipperFuelSurcharges = () => {
    setIsLoading(true);
    getCompanyFSC(getGQLClient, companyId)
      .then(({ data }) => {
        setShipperFuelSurcharges(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getShipperFuelSurcharges();
  }, []);

  const handleAddOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditOpen = id => {
    setSelectedSfsc(shipperFuelSurcharges.find(sfsc => sfsc.id === id));
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditSubmit = editedSfsc => {
    Company.updateShipperFuelSurcharge(editedSfsc, selectedSfsc.id, companyId)
      .then(response => {
        handleEditClose();
        enqueueSnackbar(`Edited fsc ${response.name}`, { variant: 'success' });
        getShipperFuelSurcharges();
      })
      .catch(enqueueSnackbar);
  };

  const handleAddSubmit = newEditFsc => {
    Company.postShipperFuelSurcharge(newEditFsc, companyId)
      .then(response => {
        handleClose();
        enqueueSnackbar(`Added new fsc ${response.name}`, { variant: 'success' });
        getShipperFuelSurcharges();
      })
      .catch(enqueueSnackbar);
  };

  const columns = [
    ...columnsStatic,
    {
      label: 'Actions',
      name: 'id',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: id => (
          <IconButton onClick={() => handleEditOpen(id)} size="large">
            <Edit />
          </IconButton>
        ),
      },
    },
  ];

  return (
    <PageContentLayout hasSubheader>
      <AddEditSfscDialog open={open} onClose={handleClose} ourHandleSubmit={handleAddSubmit} title="Add new" />
      <AddEditSfscDialog
        defaultEditValues={selectedSfsc}
        open={openEdit}
        onClose={handleEditClose}
        ourHandleSubmit={handleEditSubmit}
        title="Edit"
        isEdit
      />
      <OverviewTable
        isLoading={isLoading}
        data={shipperFuelSurcharges}
        columns={columns}
        options={{
          customToolbar: () => <CardActions onAdd={handleAddOpen} />,
        }}
      />
    </PageContentLayout>
  );
};
