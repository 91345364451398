import React from 'react';
import { Grid, Typography, Paper, LinearProgress } from '@mui/material';

export const Notice = ({ message = 'Please wait while we prepare the contract.' }) => (
  <Grid container direction="row" justifyContent="center" alignItems="center">
    <Paper variant="outlined" sx={{ mt: 8, p: 2 }}>
      <Typography variant="b" sx={{ mb: 2 }}>
        {message}
      </Typography>
      <LinearProgress />
    </Paper>
  </Grid>
);
