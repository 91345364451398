import { gql } from 'graphql-request';
import { isLikeUUID } from 'utility/uuid';

export const GQL_COMPANIES = gql`
  query getCompanies {
    shipper_route_plan(distinct_on: shipper_id) {
      shipper {
        id
        name
      }
    }
  }
`;

const WHERE = {
  COMPANY_ID_VAR: gql`$company_id: [bigint!]`,
  COMPANY_ID_CLAUSE: gql`shipper_id: { _in: $company_id }`,
  EQUIPMENT_TYPE_ID_VAR: gql`$equipment_type_id: [bigint!]`,
  EQUIPMENT_TYPE_ID_CLAUSE: gql`equipment_type_id: { _in: $equipment_type_id }`,
};

const whereClause = ({ where, search }) => {
  let whereGQL = gql``;
  const whereVarsKeys = Object.keys(where).filter(k => Array.isArray(where[k]) && where[k].length);

  if (whereVarsKeys.length || (search !== undefined && search !== '')) {
    whereGQL = gql`
    where: {
        _and: {
          ${whereVarsKeys.map(key =>
            where[key]
              ? gql`
                  ${WHERE[`${key.toUpperCase()}_CLAUSE`]}
                `
              : '',
          )}

          ${
            search !== undefined && search !== ''
              ? gql`
            _or: [
              ${isLikeUUID(search) ? gql`{ id: { _eq: "${search}" } }` : ''}
              ${isLikeUUID(search) ? gql`{ route: { id: { _eq: "${search}" } } }` : ''}
              { shipper: { name: { _ilike: "%${search}%" } } }
              { route: { legs: { lane: { origin_name: { _ilike: "%${search}%" } } } } }
              { route: { legs: { lane: { destination_name: { _ilike: "%${search}%" } } } } }
              { equipment_type: { name: { _ilike: "%${search}%" } } }
            ]
            `
              : ''
          }
        }
      }
    `;
  }
  return whereGQL;
};

const whereVar = ({ where }) => gql`
  ${where.company_id ? WHERE.COMPANY_ID_VAR : ``}
  ${where.equipment_type_id ? WHERE.EQUIPMENT_TYPE_ID_VAR : ``}
`;

export const getOpsGQL = variables => {
  const whereGQL = whereClause(variables);
  return gql`
    query getOps(
      $orderBy: [shipper_route_plan_order_by]
      $limit: Int
      $offset: Int
      ${whereVar(variables)}
    ) {
      shipperOps: shipper_route_plan(
        order_by: $orderBy
        limit: $limit
        offset: $offset
        ${whereGQL}
      ) {
        id
        shipper {
          id
          name
        }
        route {
          id
          legs(order_by: {sequence: asc}) {
            lane {
              destination: destination_name
              origin: origin_name
            }
          }
        }
        equipmentType: equipment_type {
          id
          name
        }
        adaptLaneId: adapt_lane_ref
        adaptRunDate: adapt_run_date
        adaptWeeklyVolume: adapt_weekly_volume
        adaptTargetShipperRpm: adapt_target_shipper_rpm
        plannedWeeklyVolume: planned_weekly_volume
        plannedAllocationPct: planned_allocation_pct
        plannedShipperRpm: planned_shipper_rpm
        plannedStartDate: planned_start_date
        plannedEndDate: planned_end_date
        plannedLeafTenderAcceptance: planned_leaf_tender_acceptance_pct
        maxCapacityPctSpot: max_capacity_pct_spot
        maxCapacityPctRg: max_capacity_pct_rg
        maxCapacityPctFleet: max_capacity_pct_fleet
        maxCapacityPctFlexWeekly: max_capacity_pct_flex_weekly
        maxCapacityPctNetworkMove: max_capacity_pct_network_move
        maxCapacityPctFlexDayOf: max_capacity_pct_flex_day_of
        lspTargetLinehaulRpmSpot: lsp_target_linehaul_rpm_spot
        lspTargetLinehaulRpmRg: lsp_target_linehaul_rpm_rg
        lspTargetLinehaulRpmFleet: lsp_target_linehaul_rpm_fleet
        lspTargetLinehaulRpmFlexWeekly: lsp_target_linehaul_rpm_flex_weekly
        lspTargetLinehaulRpmNetworkMove: lsp_target_linehaul_rpm_network_move
        lspTargetLinehaulRpmFlexDayOf: lsp_target_linehaul_rpm_flex_day_of
        implementationPlanWeekSpot: implementation_plan_week_spot
        implementationPlanWeekRg: implementation_plan_week_rg
        implementationPlanWeekFleet: implementation_plan_week_fleet
        implementationPlanWeekFlexWeekly: implementation_plan_week_flex_weekly
        implementationPlanWeekNetworkMove: implementation_plan_week_network_move
        implementationPlanWeekFlexDayOf: implementation_plan_week_flex_day_of
      }
      stats: shipper_route_plan_aggregate${whereGQL ? gql`( ${whereGQL} )` : ''} {
        aggregate {
          count
        }
      }
    }
  `;
};
