export default class NewSfsc {
  constructor({ name, peg, bucketSize, increment, isFirstStepZero, fuelPriceIndexId }) {
    this.name = name;
    this.peg = peg;
    this.bucketSize = bucketSize;
    this.increment = increment;
    this.isFirstStepZero = isFirstStepZero;
    this.fuelPriceIndexId = Number(fuelPriceIndexId);
  }
}
