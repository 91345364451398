import axios from 'axios';
import { includes } from 'lodash';
import FileSaver from 'file-saver';

import { USER_TYPE, utility } from '@leaf/components';

import Sfsc from 'domain/Sfsc';
import NewSfsc from 'domain/NewSfsc';
import NewLocation from 'domain/location/NewLocation';
import { accountTeamMembersMapper } from 'domain/company/CompanyMapper';
import { geoStates } from 'constants/states';
import CompanyPerformanceByCarrier from './CompanyPerformanceByCarrier';
import CompanyPerformance from './CompanyPerformance';
import CompanyPerformanceByContractTier from './CompanyPerformanceByContractTier';
import CompanyPerformanceByLane from './CompanyPerformanceByLane';
import CompanySimple from './CompanySimple';

const { REACT_APP_UI_CLIENT_APP_URL } = process.env;

const transformRegisteredState = registeredState =>
  registeredState
    ? geoStates.find(state => includes(state.content.toLowerCase(), registeredState.toLowerCase()))
    : null;

const formatAsString = claimedDomains => claimedDomains.join('  ');

const ACCEPT_XLSX = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
const EXECUTION_LANES_URL = process.env.REACT_APP_LANES_EXECUTION_API;

export default class Company {
  constructor(response) {
    Object.assign(this, {
      ...response,
      registeredState: transformRegisteredState(response?.registeredState),
    });

    this.view = {
      claimedDomains: formatAsString(this.claimedDomains),
      claimedDomainsArray: this.claimedDomains,
      createdDate: utility.datetime.formatBackendDatetime(this.createdDate),
      id: this.id,
    };
  }

  static getAll() {
    return axios
      .get('user/admin/companies')
      .then(response => response.response.companies.map(company => new Company(company)));
  }

  static getAllPaginated(params) {
    return axios.get(`view/companies?${utility.pagination.toServerSideParams(params)}`).then(response => ({
      ...response,
      data: response.data.map(company => new CompanySimple(company)),
    }));
  }

  static get(id) {
    return axios.get(`user/admin/companies/${id}`).then(response => new Company(response.response));
  }

  static post(company) {
    return axios.post(`core/companies`, Company.toCreatePayload(company)).then(response => response);
  }

  static getOnlyMyAccounts(userId) {
    return axios
      .get(`user/admin/companies?account_team_members=in:${userId}`)
      .then(response => response.response.companies.map(company => new Company(company)));
  }

  static getShipperFuelSurcharges(shipperId) {
    return axios
      .get(`jupiter/admin/shippers/${shipperId}/shipper-fuel-surcharge-schedules`)
      .then(response => response.shipperFuelSurchargeScheduleResponses.map(fsc => new Sfsc(fsc)));
  }

  static postShipperFuelSurcharge(newFsc, companyId) {
    return axios
      .post(`core/fuel-surcharge-schedules`, {
        companyId: Number(companyId),
        ...new NewSfsc(newFsc),
      })
      .then(response => response);
  }

  static updateShipperFuelSurcharge(fsc, fscId, companyId) {
    return axios
      .put(`core/fuel-surcharge-schedules/${fscId}`, {
        companyId,
        ...new NewSfsc(fsc),
      })
      .then(response => response);
  }

  static postLocation(newLocation) {
    return axios.post(`locations`, { ...new NewLocation(newLocation) }, { baseURL: EXECUTION_LANES_URL });
  }

  static updateLocation(newLocation, id) {
    return axios.put(`locations/${id}`, { ...new NewLocation(newLocation, id) }, { baseURL: EXECUTION_LANES_URL });
  }

  static update(company, id) {
    return axios.put(`core/companies/${id}`, Company.toCreatePayload(company)).then(response => response);
  }

  static getAccountTeamMembers(id) {
    return axios.get(`user/admin/companies/${id}/account-team-members`).then(response => response.accountTeamMembers);
  }

  static getAllAccountTeamMembers() {
    return axios.get(`view/companies/account-team-members`);
  }

  static getUsers(id) {
    return axios.get(`user/admin/users/companies/${id}`).then(({ response }) => response);
  }

  static impersonateAsCompanyAdmin(companyId) {
    window.open(`${REACT_APP_UI_CLIENT_APP_URL}/impersonate/${companyId}`);
  }

  static getCompanyPerformanceByContractTier(id) {
    return axios
      .get(`view/companies/${id}/performance-by-contract-tier`)
      .then(response => response?.companyContractTiers?.map(c => new CompanyPerformanceByContractTier(c)) || []);
  }

  static getCompanyPerformanceByCarrier(id) {
    return axios
      .get(`view/companies/${id}/performance-by-carrier`)
      .then(response => response?.companyCarriers?.map(p => new CompanyPerformanceByCarrier(p)) || []);
  }

  static getCompanyPerformanceByLane(id) {
    return axios
      .get(`view/companies/${id}/performance-by-lane`)
      .then(response => response?.companyLanes?.map(p => new CompanyPerformanceByLane(p)) || []);
  }

  static getCompanyPerformanceByContractTierChartData(id) {
    return axios.get(`view/companies/${id}/shipments-by-week`).then(response => response?.shipmentsByWeek);
  }

  static getCompanyPerformance(id) {
    return axios.get(`view/companies/${id}/performance`).then(performance => new CompanyPerformance(performance));
  }

  static getCarriers() {
    return axios.get('view/companies/carriers').then(({ carriers }) => carriers || []);
  }

  static importLocations(companyId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`jupiter/admin/shippers/${companyId}/locations/import:validation`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress,
      timeout: 1000 * 300,
    });
  }

  static addUpdateLocations(companyId, data) {
    return axios.put(`jupiter/admin/shippers/${companyId}/locations/import`, data, { timeout: 1000 * 300 });
  }

  static partialLocationValidation(companyId, data) {
    return axios.put(`jupiter/admin/shippers/${companyId}/locations/import:partial-validation`, data, {
      timeout: 1000 * 300,
    });
  }

  static downloadLocationsFile(companyId) {
    return axios
      .get(`jupiter/admin/shippers/${companyId}/locations`, {
        responseType: 'blob',
        headers: {
          ...ACCEPT_XLSX,
        },
      })
      .then(response => {
        FileSaver.saveAs(response, `leaf_${utility.date.formatDate(new Date(), 'dd/MM/yyyy')}.xlsx`);
      });
  }

  // API
  static toCreatePayload = ({
    name,
    type,
    transactionFee,
    scacCode,
    phone,
    registeredState,
    registeredName,
    tenderEmailAddress,
    tenderPhoneNumber,
    usDotNumber,
    usMcNumber,
    use4KitesIntegration,
    useProject44Integration,
    useTrimbleIntegration,
    accountTeamMembers,
  }) => {
    const isCompanyShipper = type === USER_TYPE.SHIPPER;
    const isCompanyCarrier = type === USER_TYPE.CARRIER;

    const payload = {
      name,
      companyType: type,
      transactionFee,
      registeredState: registeredState ? registeredState.substring(5) : null, // formatting: transform "CA - California" into "California"
      registeredName,
      tenderEmailAddress,
      tenderPhoneNumber, // string
      phone, // string
      // // TODO - change/simplify geoStates.js JSON content and remove substring(5)
      accountTeamMembers: accountTeamMembers ? accountTeamMembersMapper(accountTeamMembers) : [], // [id, id, ..]
      // // SHIPPER specific fields
      use4KitesIntegration: isCompanyShipper ? use4KitesIntegration : undefined,
      useTrimbleIntegration: isCompanyShipper ? useTrimbleIntegration : undefined,
      // // CARRIER specific fields
      useProject44Integration: isCompanyCarrier ? useProject44Integration : undefined,
      usDotNumber: isCompanyCarrier ? usDotNumber : undefined, // string
      usMcNumber: isCompanyCarrier ? usMcNumber : undefined, // string
      scacCode: isCompanyCarrier ? scacCode : undefined, // 2-4 letters string
    };
    return payload;
  };
}
