import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  Page,
  ServerSideTable,
  PageContentLayout,
  PageContainer,
  useTableCustomFilters,
  useCopyToClipboard,
} from '@leaf/components';
import useGQL from 'hooks/useGQL';
import { StateContext } from 'state/StateProvider';
import { SET_FLEET_DISCOVERY_OVERVIEW_TABLE_STATE } from 'state/stateReducer';

import { getColumns, getOptions } from './table-config';
import { getFleetDiscovery, getDomiciles } from './domain/fleetDiscoveryOverviewModel';

export default withRouter(({ history }) => {
  const getGQLClient = useGQL();

  const { enqueueSnackbar } = useSnackbar();
  const copy = useCopyToClipboard(
    successMessage => enqueueSnackbar(successMessage, { variant: 'success' }),
    errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' }),
  );

  const {
    state: {
      tables: { fleetDiscoveryOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const handleChange = setTableState =>
    dispatch({ type: SET_FLEET_DISCOVERY_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'autocomplete',
      columnName: 'domicile',
      filterOptionsGetter: () => getDomiciles(getGQLClient),
    },
  ]);

  return (
    <Page title="Fleet Discovery">
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={getOptions(history)}
            columns={getColumns(filterOptions, copy)}
            fn={() => getFleetDiscovery(getGQLClient, tableState)}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
});
