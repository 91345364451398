/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@mui/material';
import { utility, Form } from '@leaf/components';

export default React.memo(({ name, onChange, required, label, formik, inputVariant, ...props }) => {
  const handleChange = value => formik.setFieldValue(name, value);

  return (
    <Form.Generic.Datepicker
      format={utility.date.VIEW_FORMAT}
      onChange={handleChange}
      renderInput={inputProps => (
        <TextField fullWidth variant={inputVariant} onBlur={formik.handleBlur} {...inputProps} />
      )}
      value={formik.values[name] || null}
      label={required ? `${label} *` : label}
      autoOk
      {...props}
    />
  );
});
