import _ from 'lodash';
import axios from 'axios';

export default class Dashboard {
  constructor({ ...response }) {
    Object.assign(this, {
      ...response,
    });
  }

  static getDashboardConfig = dashboardName =>
    axios.get(`dashboard/admin/dashboard-config/${dashboardName}`).catch(error => Promise.reject(error));

  static updateDashboardConfig = (dashboardName, config) =>
    axios.put(`dashboard/admin/dashboard-config/${dashboardName}`, config);

  static saveDashboardConfig = config => axios.post(`dashboard/admin/dashboard-config`, config);

  static getWidgetData = queryString => axios.get(`dashboard/admin/query?${queryString}`);

  static getEquipmentFilter = () => axios.get('dashboard/admin/filters/equipment-types');

  static getNetworkMovesFilter = () =>
    axios.get('dashboard/admin/filters/network-moves').then(res =>
      res?.map(({ networkMove, networkMoveIds }) => ({
        name: networkMove,
        guid: networkMoveIds,
      })),
    );

  static getShippersFilter = () => axios.get('dashboard/admin/filters/shippers');

  static getCarriersFilter = () => axios.get('dashboard/admin/filters/carriers');

  static getLaneFilter = () => axios.get('dashboard/admin/filters/lanes');

  static getTransportationModesFilter = () => axios.get('dashboard/admin/filters/transportation-modes');

  static getReportingLaneFilter = () => axios.get('dashboard/admin/filters/reporting-lanes');

  static getCohortsFilter = () => axios.get('dashboard/admin/filters/cohorts');

  static getMetrics = () => axios.get('dashboard/admin/metrics');

  static getDimensions = () => axios.get('dashboard/admin/dimensions');

  static getFilterValues = filterName =>
    axios.get(`dashboard/admin/filters/dimensions/${filterName}`).then(res => {
      if (!Array.isArray(res)) {
        return [];
      }
      return res.map(({ [filterName]: name, guid }) => guid ?? name) ?? [];
    });

  static getFilterOptions = () => axios.get('dashboard/admin/filters');

  // utility functions

  static extractField = (response, field) => {
    if (!response) {
      return [];
    }
    const list = response.map(r => r[field]);
    list.sort((a, b) => a.localeCompare(b));
    return _.sortedUniq(list); // to remove duplicates for origin and destination
  };
}
