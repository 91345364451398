import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import useGQL from 'hooks/useGQL';
import {
  Page,
  UserType,
  NewTabLink,
  ServerSideTable,
  useTableCustomFilters,
  PageContentLayout,
  PageContainer,
  utility,
  AvatarGroup,
  USER_TYPE_DISPLAY_NAMES,
} from '@leaf/components';

import Company from 'domain/company/Company';
import LoginAsUserActions from 'components/impersonation/LoginAsUserActions';
import AdaptCompanyData from 'domain/company/AdaptCompanyData';

import { StateContext } from 'state/StateProvider';
import { SET_COMPANIES_OVERVIEW_TABLE_STATE } from 'state/stateReducer';
import { getCompanies, getAccountTeamMembers } from './domain/companyModel';
import CreateCompanyDialog from '../CompanyCreateDialog';

const getColumns = (filterOptions, handleImpersonationAsCompanyAdmin) => [
  { label: 'Name', name: 'name', options: { filter: false } },
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    label: 'Type',
    name: 'companyType',
    options: {
      ...filterOptions.companyType,
      customBodyRender: type => <UserType type={type} />,
    },
  },
  {
    label: 'Only my accounts',
    name: 'currentUserAccountMember',
    options: {
      ...filterOptions.currentUserAccountMember,
      customFilterListOptions: {
        render: () => 'Only my accounts',
      },
      display: 'excluded',
    },
  },
  {
    label: 'Account team members',
    name: 'accountTeamMembers',
    options: {
      ...filterOptions.accountTeamMembers,
      sort: false,
      ...utility.table.centerAlignColumnProps(accountTeamMembers => (
        <AvatarGroup
          avatars={accountTeamMembers}
          avatarLabelFormatter={({ firstName, lastName }) => `${firstName.charAt(0)}${lastName.charAt(0)}`}
          tooltipLabelFormatter={({ firstName, lastName }) => `${firstName} ${lastName}`}
        />
      )),
    },
  },
  {
    label: 'Created date',
    name: 'created',
    options: {
      filter: false,
    },
  },
  {
    label: 'Actions',
    name: 'id',
    field: 'id',
    options: {
      filter: false,
      sort: false,
      display: true,
      customBodyRender: id => (
        <>
          <LoginAsUserActions onLoginAsCompanyAdminClick={event => handleImpersonationAsCompanyAdmin(event, id)} />
          <NewTabLink url={`/companies/${id}`} />
        </>
      ),
    },
  },
];

export default withRouter(({ history }) => {
  const [open, setOpen] = React.useState(false);
  const getGQLClient = useGQL();

  const [filterOptions] = useTableCustomFilters([
    {
      inputType: 'checkbox',
      columnName: 'currentUserAccountMember',
    },
    {
      inputType: 'autocomplete',
      columnName: 'accountTeamMembers',
      filterOptionsGetter: () => getAccountTeamMembers(getGQLClient),
    },
    {
      inputType: 'autocomplete',
      columnName: 'companyType',
      filterOptionsGetter: () =>
        Promise.resolve(
          Object.keys(USER_TYPE_DISPLAY_NAMES).map(key => ({
            label: USER_TYPE_DISPLAY_NAMES[key],
            value: key,
          })),
        ),
    },
  ]);

  const {
    state: {
      tables: { companiesOverview: tableState },
    },
    dispatch,
  } = useContext(StateContext);

  const handleAddOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateCompany = (company, resetForm, adaptData) => {
    Company.post(company)
      .then(response => {
        if (response?.id) AdaptCompanyData.write(response.id, adaptData);
        resetForm();
        handleClose();
        dispatch({ type: SET_COMPANIES_OVERVIEW_TABLE_STATE, payload: { ...tableState } });
        enqueueSnackbar(`Added new company ${response?.name}`, { variant: 'success' });
      })
      .catch(enqueueSnackbar);
  };

  const handleImpersonationAsCompanyAdmin = (event, id) => {
    event.stopPropagation();
    Company.impersonateAsCompanyAdmin(id);
  };

  const options = {
    filter: true,
    download: false,
    customToolbar: () => (
      <Tooltip title="Add New Company">
        <IconButton onClick={handleAddOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
    onRowClick: rowData => {
      const id = rowData[1];
      history.push(`/companies/${id}`);
    },
  };

  const handleChange = setTableState =>
    dispatch({ type: SET_COMPANIES_OVERVIEW_TABLE_STATE, payload: setTableState(tableState) });

  return (
    <Page title="Companies">
      <PageContainer>
        <PageContentLayout hasSubheader={false}>
          <ServerSideTable
            options={options}
            columns={getColumns(filterOptions, handleImpersonationAsCompanyAdmin)}
            fn={() => getCompanies(getGQLClient, tableState)}
            tableState={tableState}
            setTableState={handleChange}
          />
        </PageContentLayout>
      </PageContainer>
      {open && (
        <CreateCompanyDialog open onClose={handleClose} handleCreateCompany={handleCreateCompany} editCompany={null} />
      )}
    </Page>
  );
});
