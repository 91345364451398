import React from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { KeyboardArrowDown, Delete, Edit } from '@mui/icons-material';
import { Lane, LANE_COLORS } from '@leaf/components';
import RouteCardBody from './RouteCardBody';

const RouteCard = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing(2)}`};
  min-height: 75px;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;

  background-color: #fff;
  border-radius: ${({ $expand }) => !$expand && '5px'};
  border-bottom-left-radius: ${({ $expand }) => $expand && '0px'};
  border-bottom-right-radius: ${({ $expand }) => $expand && '0px'};
`;

const RouteCardContent = styled.div`
  flex-grow: 1;

  div:first-child {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

const DownIcon = styled(KeyboardArrowDown)`
  color: #0064b0;
`;

const IconButtonWrapper = styled(IconButton)`
  transform: ${({ $expand }) => $expand && 'rotate(180deg)'};
  width: 12px;
  height: 12px;

  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

const IconButtonAction = styled(IconButton)`
  margin: ${({ theme }) => `${theme.spacing(1)} 0px`};
  width: 20px;
  height: 20px;
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

const RouteCardRoutes = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(1)}`};
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  flex-direction: column;
  justify-content: flex-start;
`;

const RouteCardItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const LaneIndicator = styled.div`
  width: 3px;
  height: 16px;
  border-radius: 50px;
  background: ${({ $bgColor }) => $bgColor};
`;

const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default ({ route, openRouteDetails, isEditable = false, handleExpand, actions }) => {
  const handleSetRoute = () => {
    if (openRouteDetails) openRouteDetails(route);
  };

  const handleAction = event => {
    event.stopPropagation();
    actions(event.currentTarget.dataset.action, route);
  };

  return (
    <>
      <RouteCard $expand={route.expand} onClick={handleSetRoute} data-expand>
        <RouteCardContent>
          <RouteCardBody route={route} />
        </RouteCardContent>
        <IconButtonContainer>
          {isEditable && (
            <>
              <IconButtonAction data-action="delete" onClick={handleAction}>
                <Delete fontSize="small" />
              </IconButtonAction>
              <IconButtonAction data-action="edit" onClick={handleAction}>
                <Edit fontSize="small" />
              </IconButtonAction>
            </>
          )}
          <IconButtonWrapper onClick={event => handleExpand(event, route.id)} $expand={route.expand}>
            <DownIcon fontSize="small" />
          </IconButtonWrapper>
        </IconButtonContainer>
      </RouteCard>
      {route.expand ? (
        <RouteCardRoutes>
          {route.legs.map((leg, index) => (
            <RouteCardItem>
              <LaneIndicator $bgColor={LANE_COLORS[index]} />
              <div>
                <Lane origin={{ primaryContent: [leg.origin] }} destination={{ primaryContent: [leg.destination] }} />
              </div>
            </RouteCardItem>
          ))}
        </RouteCardRoutes>
      ) : null}
    </>
  );
};
