import { gql } from 'graphql-request';

export const getShipmentDetailsGQL = id => gql`
  query getShipment {
    shipmentTender: shipment_tender(where: { id: { _eq: ${id} } }) {
      id
      executionPartnerShipmentId: execution_partner_shipment_id
      transportationMode: transportation_mode
      shipmentExecutionStatus: shipment_execution_status
      originBusinessName: origin_business_name
      originAddressName: origin_address_name
      originCity: origin_city
      originState: origin_state
      originZip: origin_zip
      originAppointmentTime: origin_appointment_time
      originArrivalTime: origin_arrival_time
      originDepartureTime: origin_departure_time
      destinationBusinessName: destination_business_name
      destinationAddressName: destination_address_name
      destinationCity: destination_city
      destinationState: destination_state
      destinationZip: destination_zip
      destinationAppointmentTime: destination_appointment_time
      destinationArrivalTime: destination_arrival_time
      destinationDepartureTime: destination_departure_time
      buyContractRouteId: buy_contract_route_id
      shipperShipmentId: shipper_shipment_id
      buyContract: buy_contract {
        id
        contractType: contract_type
        shipperRoutePlans: shipper_route_plans {
            shipperRoutePlanId: shipper_route_plan_id
          }
      }
      carrierTripTender: carrier_trip_tender {
        id
        executionPartnerShipmentId: execution_partner_shipment_id
        spotConfirmed: spot_confirmed
        contractId: carrier_contract_id
        routeId: sell_contract_route_id
        sellContract: sell_contract {
          contractType: contract_type
        }
        company {
          id
          name
        }
        carrierInvoices: carrier_invoices {
          invoiceDate: invoice_date
          invoiceNumber: invoice_number
          invoiceTotal: invoice_total
          linehaul
          fuel
          accessorialTotal: accessorial_total
          deleted
          tonu
        }
      }
      equipmentType: equipment_type {
        name
      }
      destination {
        locationType: location_type
        geo
      }
      origin {
        locationType: location_type
        geo
      }
      executionPartnerCompany: execution_partner_company {
        name
      }
      company {
        id
        name
      }
      lane {
        leafDistance: leaf_distance
        geo
      }
      shipperInvoices: shipper_invoices {
        invoiceDate: invoice_date
        invoiceTotal: invoice_total
        linehaul
        fuel
        accessorialTotal: accessorial_total
        detentions
        tonu
      }
      dashboardShipmentTender: dashboard_shipment_tender {
        shipperLinehaulRPM: contracted_rate_shipper
        shipperLinehaul: contracted_linehaul_costs_shipper
        shipperFuel: contracted_fuel_cost_shipper
        shipperTotalCost: contracted_total_cost_shipper
        shipperAccessorials: contracted_accessorial_cost_shipper
        carrierLinehaulRPM: contracted_rate_carrier
        carrierLinehaul: contracted_linehaul_costs_carrier
        carrierFuel: contracted_fuel_cost_carrier
        carrierTotalCost: contracted_total_cost_carrier
        carrierAccessorials: contracted_accessorial_cost_carrier
      }
      shipmentTagEntries: shipment_tag_entries {
        rank
        contractType: contract_type
        linehaulRPM: carrier_linehaul
        totalCost: carrier_total
        contractId: contract_id
        carrier {
          name
        }
        fulfilledLifetime: carrier_contract_lifetime_volume_fulfilled
        fulfilledWeekly: carrier_contract_weekly_volume_fulfilled
        isFleet: is_fleet
        isNetworkMove: is_network_move
      }
    }
  }
`;

export const getShipmentTrackingEventGQL = id => gql`
  query getShipmentTrackingEvent {
    shipmentTrackingEvent: shipment_tracking_event(limit: 100, where: { shipment_tender_id: { _eq: ${id} } }) {
      latitude
      longitude
      eventTime: event_time
      address
      trackingStatus: tracking_status
      statusDescription: status_description
    }
  }
`;
