import React from 'react';
import axios from 'axios';
import { Check, Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { OverviewTable, PageContentLayout, useLoading } from '@leaf/components';

const renderTrueFalse = v => (v ? <Check htmlColor="green" /> : <Close htmlColor="red" />);

const columns = [
  {
    label: 'ID',
    name: 'id',
    options: {
      filter: false,
    },
  },
  {
    label: 'Name',
    name: 'name',
    options: {
      filter: false,
    },
  },
  {
    label: 'Logo',
    name: 'has_logo',
    options: {
      filter: false,
      customBodyRender: renderTrueFalse,
    },
  },
  {
    label: 'Adapt',
    name: 'has_adapt_enabled',
    options: {
      customBodyRender: renderTrueFalse,
    },
  },
  {
    label: 'Run Date',
    name: 'run_date',
    options: {
      filter: false,
    },
  },
  {
    label: 'Run Date (T/F)',
    name: 'has_run_date',
    options: {
      customBodyRender: renderTrueFalse,
      display: false,
    },
  },
  {
    label: 'Start Date',
    name: 'window_start_date',
    options: {
      filter: false,
    },
  },
  {
    label: 'Start Date (T/F)',
    name: 'has_window_start_date',
    options: {
      customBodyRender: renderTrueFalse,
      display: false,
    },
  },
  {
    label: 'End Date',
    name: 'window_end_date',
    options: {
      filter: false,
    },
  },
  {
    label: 'End Date (T/F)',
    name: 'has_window_end_date',
    options: {
      customBodyRender: renderTrueFalse,
      display: false,
    },
  },
  {
    label: 'Company Data',
    name: 'has_data',
    options: {
      customBodyRender: renderTrueFalse,
    },
  },
  {
    label: 'Plan',
    name: 'has_plan',
    options: {
      customBodyRender: renderTrueFalse,
    },
  },
  {
    label: '# Lanes',
    name: 'n_ls',
    options: {
      filter: false,
    },
  },
  {
    label: '# NMs',
    name: 'n_nms',
    options: {
      filter: false,
    },
  },
];

const AdaptStatus = () => {
  const [data, setData] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const [getStatus, isLoading] = useLoading(
    async () => axios.get('/planning/adapt/status').then(setData).catch(enqueueSnackbar),
    true,
  );

  React.useEffect(() => {
    getStatus();
  }, []);

  return (
    <PageContentLayout hasSubheader>
      <OverviewTable data={data} columns={columns} isLoading={isLoading} />
    </PageContentLayout>
  );
};

export default AdaptStatus;
