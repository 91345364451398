/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, memo, useEffect } from 'react';
import { LinearProgress, Radio, Typography } from '@mui/material';
import { Autocomplete } from 'components/form';
import _ from 'lodash';
import { MultiRowInfo } from '@leaf/components';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import useGQL from 'hooks/useGQL';
import { getLocations } from 'executions/partner-locations/domain/executionPartnerLocationModel';

const SearchInput = styled.div`
  .MuiPaper-root {
    height: 420px;
  }
  .MuiAutocomplete-listbox {
    max-height: none;
    height: 100%;
  }
`;

const Loader = styled(LinearProgress, {
  shouldForwardProp: prop => prop !== '$isLoading',
})`
  top: 60px;
  visibility: ${({ $isLoading }) => `${$isLoading ? 'visible' : 'hidden'}`};
`;

const Identifiers = styled.span`
  display: flex;
  flex-direction: column;
  flex-basis: 130px;
`;

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > div {
    flex-grow: 1;
  }
`;

const ListItem = styled.li`
  display: flex;
  width: 100%;
`;

const RadioSC = styled(Radio)`
  margin-right: ${({ theme }) => `${theme.spacing(1)}`};
`;

export default memo(({ onChangeCb, onChange, getOptionLabel, shipperId, ...rest }) => {
  const getGQLClient = useGQL();
  const { enqueueSnackbar } = useSnackbar();
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getExecutionPartnerLocations = searchTerm => {
    setIsLoading(true);
    getLocations(getGQLClient, { query: searchTerm, limit: 50, shipperId })
      .then(({ data }) => {
        setLocations(data);
      })
      .catch(enqueueSnackbar)
      .finally(() => setIsLoading(false));
  };

  const debounceSetLocations = useCallback(
    _.debounce(searchTerm => {
      getExecutionPartnerLocations(searchTerm);
    }, 500),
    [],
  );

  useEffect(() => {
    getExecutionPartnerLocations();
  }, []);

  const props = {
    id: 'location',
    name: 'location',
    label: 'Find location',
    options: locations,
    open: true,
    filterOptions: v => v,
    onKeyUp: event => debounceSetLocations(event.target.value),
    getOptionLabel,
    renderOption: (fieldProps, option, { selected }) => (
      <ListItem {...fieldProps}>
        <RadioSC checked={selected} />
        <LocationWrapper>
          <MultiRowInfo
            primaryContent={[option.addressShort]}
            secondaryContent={[option.street, option.businessName]}
          />
          <Identifiers>
            <Typography variant="body1">ID: {option.id}</Typography>
            {option.guid && <Typography variant="body1">GUID: {option.guid}</Typography>}
          </Identifiers>
        </LocationWrapper>
      </ListItem>
    ),
    multiple: false,
    disablePortal: true,
    ...(onChangeCb && { onChangeCb: option => onChangeCb(option, locations) }),
    ...(onChange && { onChange }),
    ...rest,
  };

  return (
    <SearchInput>
      <Loader $isLoading={isLoading} />
      <Autocomplete {...props} />
    </SearchInput>
  );
});
