import React, { useState } from 'react';
import axios from 'axios';
import {
  Grid,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from '@mui/material';
import { AttachFile, Close, Info } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { Page, PageContentLayout, PageContent, PageContainer } from '@leaf/components';
import { Select, Input } from 'components/form';
import { v4 } from 'uuid';

const apis = [
  {
    type: 'EXECUTIVE',
    name: 'Adapt Company Metrics',
    url: companyId => `analytics/adapt/companies/${companyId}/metrics`,
  },
];

const LaneBenefitSummary = ({ summary }) =>
  summary && (
    <Box mt={2}>
      <Typography variant="body1">
        <b>Total:</b> {summary.total}
      </Typography>
      <Typography variant="body1">
        <b>Imported:</b> {summary.imported}
      </Typography>
      <br />
      {summary.warnings && (
        <>
          <Typography variant="body1">
            <b>Warnings:</b> {summary.warnings.length}
          </Typography>
          <Box mt={1}>
            <ul>
              {summary.warnings.map(warning => (
                <li key={v4()}>
                  <Typography variant="body1">{warning}</Typography>
                </li>
              ))}
            </ul>
          </Box>
        </>
      )}
    </Box>
  );

export default () => {
  const [api, setApi] = useState({ name: '', url: '' });
  const [companyId, setCompanyId] = useState('');
  const [files, setFiles] = useState([]);
  const [laneBenefitSummary, setLaneBenefitSummary] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  // Reset on API change.
  React.useEffect(() => {
    setFiles([]);
  }, [api]);

  const handleFiles = ({ target }) => {
    const newFiles = Object.values(target.files).filter(file => !files.find(f => f.name === file.name));
    setFiles([...files, ...newFiles]);
  };

  const handleUpload = () => {
    const data = new FormData();

    if (['BENEFIT'].includes(api.type)) {
      data.append('csv', files[0]);
    } else {
      files.forEach(f => {
        data.append('files', f);
      });
    }
    axios
      .post(api.url(companyId), data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        enqueueSnackbar(`Uploaded ${files.length} file(s) to the ${api.name} API`, { variant: 'success' });
        if (api.type === 'BENEFIT') {
          setLaneBenefitSummary(res);
        }
        setApi({ name: '', url: '' });
        setCompanyId('');
        setFiles([]);
      })
      .catch(enqueueSnackbar);
  };

  const handleChangeCompanyId = event => {
    setCompanyId(event.target.value);
  };

  const handleChangeApi = event => {
    setApi(event.target.value);
    setLaneBenefitSummary(null);
  };

  const handleDeleteFile = name => () => {
    setFiles(files.filter(f => f.name !== name));
  };

  return (
    <Page title="Adapt Upload">
      <PageContainer>
        <PageContentLayout noStyling fullHeight standaloneView>
          <Grid item xs={6}>
            <PageContent withPadding>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Select id="api" name="api" label="Select API" value={api} onChange={handleChangeApi} required>
                    {apis.map(a => (
                      <MenuItem key={a.url} value={a}>
                        {a.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {!!api.name && (
                  <>
                    <Grid item xs={3}>
                      <Input
                        name="companyId"
                        label="Company ID"
                        type="text"
                        value={companyId}
                        onChange={handleChangeCompanyId}
                        required
                      />
                    </Grid>

                    <Grid container alignItems="flex-end" item xs={3}>
                      <input
                        id="upload"
                        name="upload"
                        style={{ display: 'none' }}
                        accept=".csv"
                        multiple={api.type === 'EXECUTIVE'}
                        disabled={files.length && api.type === 'BUSINESS_OBJECTIVES'}
                        type="file"
                        onChange={handleFiles}
                      />

                      <label htmlFor="upload">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          disabled={files.length && api.type === 'BUSINESS_OBJECTIVES'}
                        >
                          Select file(s)
                        </Button>

                        {api.type === 'EXECUTIVE' && (
                          <Tooltip
                            title={
                              <>
                                <p>Following files can be parsed:</p>

                                <ul>
                                  <li>adapt_company_stats.csv</li>
                                  <li>adapt_business_objectives.csv</li>
                                </ul>

                                <p>The filenames are critical and must be exact.</p>

                                <p>If you edit a file while it is already queued, you will need to remove/add it.</p>
                              </>
                            }
                          >
                            <Info />
                          </Tooltip>
                        )}
                      </label>
                    </Grid>
                  </>
                )}

                {!!files?.length && (
                  <Grid item xs={12}>
                    <List>
                      {files.map(f => (
                        <ListItem key={f.name}>
                          <ListItemIcon>
                            <AttachFile />
                          </ListItemIcon>
                          <ListItemText primary={f.name} />

                          <IconButton onClick={handleDeleteFile(f.name)} size="large">
                            <Close />
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}

                {!!files?.length && !!companyId && (
                  <Grid item xs={12}>
                    <Button variant="contained" color="secondary" onClick={handleUpload} disabled={!files.length}>
                      Upload
                    </Button>
                  </Grid>
                )}
              </Grid>
            </PageContent>
          </Grid>
          <LaneBenefitSummary summary={laneBenefitSummary} />
        </PageContentLayout>
      </PageContainer>
    </Page>
  );
};
