/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Page,
  Tabs,
  BackButton,
  PageContentLayout,
  SpacedContainer,
  PageContainer,
  DropdownMenu,
  Lane,
  CONTRACT_STATUS_DISPLAY_NAMES,
  LazySpinner,
  CompanyAvatar,
  RoutesOverview,
  useCopyToClipboard,
} from '@leaf/components';
import { Box, Grid, Typography, DialogContentText } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { OverlayLoader } from 'contracts/shared/OverlayLoader';
import useGQL from 'hooks/useGQL';
import { useSnackbar } from 'notistack';
import ContractDetails from './ContractDetails';
import ShipmentsOverview from './shipments/ShipmentsOverview';
import { getContractDetailsLaneDescription, deleteContract, getContractRoutes } from './domain/contractDetailsModel';
import ContractMatching from './matching/ContractMatching';

import CustomDialog from '../shared/CustomDialog';
import TripsOverview from './shipments/TripsOverview';

const BoxWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const RouteHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RouteItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export default withRouter(
  ({
    match: {
      params: { id },
    },
    history,
    location,
  }) => {
    const [laneDescription, setLaneDescription] = useState();
    const [openPrompt, setOpenPrompt] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [routes, setRoutes] = useState();
    const getGQLClient = useGQL();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      getContractDetailsLaneDescription(getGQLClient, id)
        .then(({ data }) => {
          setLaneDescription(data);
        })
        .catch(enqueueSnackbar);
      getContractRoutes(getGQLClient, id)
        .then(({ data }) => {
          setRoutes(data[0]);
        })
        .catch(enqueueSnackbar);
    }, []);

    const handleCopyRouteId = useCopyToClipboard(
      successMessage => enqueueSnackbar(successMessage, { variant: 'success' }),
      errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' }),
      'Copied Route ID to clipboard',
      'Failed to copy Route ID to clipboard',
    );

    const getTabs = useCallback(() => {
      const tabs = [
        { label: 'Details', content: <ContractDetails contractId={id} /> },
        {
          label: 'Routes',
          content: <RoutesOverview routesData={routes} copyRoute={handleCopyRouteId} />,
        },
        {
          label: 'Contract Matching',
          content: laneDescription ? (
            <ContractMatching contractId={id} isBuyerContract={laneDescription.isBuyerContract} />
          ) : (
            <LazySpinner />
          ),
        },
        ...(laneDescription && laneDescription.isBuyerContract
          ? [
              {
                label: 'Shipments',
                content: <ShipmentsOverview contractId={id} />,
              },
            ]
          : []),
        ...(laneDescription && !laneDescription.isBuyerContract
          ? [
              {
                label: 'Trips',
                content: <TripsOverview contractId={id} />,
              },
            ]
          : []),
      ];
      return tabs;
    }, [id, laneDescription, routes]);

    const handleDuplicateEditContract = event => {
      if (event.target.innerText.toLowerCase() === 'duplicate contract') {
        history.push(`/contracts/create-contract/${id}?duplicate`);
      } else {
        history.push(`/contracts/create-contract/${id}`);
      }
    };

    const openDeleteContractPrompt = () => {
      setOpenPrompt(true);
    };

    const handleDeleteConfirmation = event => {
      setOpenPrompt(false);
      const confirm = event.target.getAttribute('data-accept');
      if (confirm) {
        setDeleting(true);
        deleteContract(id)
          .then(() => {
            enqueueSnackbar(`Successfully deleted contract!`, { variant: 'success' });
            setDeleting(false);
            history.push('/contracts');
          })
          .catch(error => {
            enqueueSnackbar(`Failed to delete contract! ${error}`);
            setDeleting(false);
          });
      }
    };

    return (
      <Page
        ComponentTitleLeft={
          <RouteHeader>
            <BackButton history={history} path="/contracts" />
            {laneDescription && (
              <>
                <CompanyAvatar id={laneDescription?.buyerId || laneDescription?.sellerId} />
                <RouteItem>
                  <Lane
                    origin={{ primaryContent: [laneDescription?.origin] }}
                    destination={{ primaryContent: [laneDescription?.destination] }}
                    fullWidth={false}
                  />
                  <Typography variant="body1">{`Contract ID #${id} - ${
                    CONTRACT_STATUS_DISPLAY_NAMES[laneDescription?.contractStatus] ?? ''
                  }`}</Typography>
                </RouteItem>
              </>
            )}
          </RouteHeader>
        }
        UserDescription={
          <BoxWrapper>
            <DropdownMenu
              options={[
                { label: 'Edit Contract', onClick: handleDuplicateEditContract },
                { label: 'Delete Contract', onClick: openDeleteContractPrompt },
                { label: 'Duplicate Contract', onClick: handleDuplicateEditContract },
              ]}
            />
          </BoxWrapper>
        }
        isUppercase={false}
      >
        <PageContainer>
          <SpacedContainer container spacing={2} autoWidth>
            <Grid item xs={12}>
              <PageContentLayout withoutSpacing fullHeight={false}>
                <Tabs location={location} history={history} mainUrlPath={`/contracts/${id}`} tabs={getTabs()} />
              </PageContentLayout>
            </Grid>
          </SpacedContainer>
        </PageContainer>
        <OverlayLoader open={deleting} />
        <CustomDialog open={openPrompt} title="Delete Contract" handleClose={handleDeleteConfirmation}>
          <DialogContentText sx={{ p: '0 16px' }}>
            <Typography sx={{ mb: 2 }}>Are you sure you want to delete this contract?</Typography>
            <Typography sx={{ mb: 2 }}>You will not be able to recover it and your data will be lost.</Typography>
          </DialogContentText>
        </CustomDialog>
      </Page>
    );
  },
);
