/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm, Controller } from 'react-hook-form';
import { Form } from '@leaf/components';
import { useStateMachine } from 'little-state-machine';
import { Typography } from '@mui/material';
import { disableEnterKey } from 'utility/disableEnterKey';
import { FormContent, FormItem, FormFooter, FormActions } from '../partials/viewHelpers';
import { prepareContractDataForAPI, saveContract, upsertContract } from '../domain/contractModel';
import { updateContract, defaultValues as resetValues } from '../domain/stateMachine';

const PRICING_MECHANISM_OPTIONS = Object.freeze(['FIXED', 'INDEX']);
const ASSET_PRICING_FREQUENCY_OPTIONS = Object.freeze(['DAILY', 'WEEKLY']);
const MIN_CHARGEABLE_MILES_FREQUENCY_OPTIONS = Object.freeze(['DAILY', 'WEEKLY']);
const PRICE_INDEX_OPTIONS = Object.freeze(['DAT']);

export function Pricing({ next, back, abortContract }) {
  const { actions, getState } = useStateMachine({ updateContract });
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const {
    newContract: defaultValues,
    CONTRACT_FORM_OPTIONS: { fuelSurchargeSchedule },
  } = getState();

  const buyerId = parseInt(defaultValues.buyerId, 10);
  const FSC_OPTIONS =
    defaultValues.buyerId !== ''
      ? fuelSurchargeSchedule.filter(fsc => fsc.companyId === buyerId)
      : fuelSurchargeSchedule.filter(fsc => fsc.companyId === null);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onsubmit',
    defaultValues,
  });

  const handleFormSubmit = data => {
    actions.updateContract(data);
    const apiData = prepareContractDataForAPI(getState().newContract);

    if (apiData.id !== null && !location.search.toLowerCase().includes('duplicate')) {
      // update contract
      upsertContract(apiData.id, apiData)
        .then(() => {
          enqueueSnackbar(`Contract updated successfully!`, { variant: 'success' });
          next();
        })
        .catch(error => {
          return enqueueSnackbar(error);
        });
    } else {
      // otherwise new or duplicate contract
      saveContract(apiData)
        .then(res => {
          // when contract saves successfuly, store id in memory
          actions.updateContract({ id: res.id });
          enqueueSnackbar(`Contract saved successfully!`, { variant: 'success' });
          next();
        })
        .catch(error => {
          return enqueueSnackbar(error);
        });
    }
  };

  const watchPricingMechanism = watch('pricingMechanism');
  const watchAssetPricing = watch('assetPricing');
  const watchMinChargeableMiles = watch('minChargeableMiles');

  React.useEffect(() => {
    if (abortContract) {
      actions.updateAction(resetValues);
      reset(resetValues);
    }
  }, [abortContract, actions, reset]);

  React.useEffect(() => {
    if (watchPricingMechanism === 'FIXED') {
      setValue('linehaulRpm', defaultValues.linehaulRpm);
      setValue('pricingIndexPremium', '');
      setValue('pricingIndex', '');
    } else {
      setValue('linehaulRpm', '');
      setValue('pricingIndexPremium', defaultValues.pricingIndexPremium);
      setValue('pricingIndex', defaultValues.pricingIndex);
      setValue('assetPricing', '');
      setValue('assetPricingFrequency', '');
      setValue('minChargeableMiles', '');
      setValue('minChargeableMilesFrequency', '');
    }
  }, [watchPricingMechanism, setValue, defaultValues]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={e => disableEnterKey(e)}>
      <FormContent>
        <FormItem>
          <Typography variant="h5">RPM Pricing</Typography>
          <Controller
            name="pricingMechanism"
            control={control}
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={PRICING_MECHANISM_OPTIONS}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={option => option}
                onChange={(_, data) => onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Pricing Mechanism*"
                    error={!!errors.pricingMechanism}
                    helperText={errors.pricingMechanism ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
          <TextField
            name="linehaulRpm"
            label="Linehaul RPM"
            type="number"
            defaultValue={defaultValues.linehaulRpm}
            {...register('linehaulRpm', { required: watchPricingMechanism === 'FIXED' })}
            error={watchPricingMechanism === 'FIXED' ? errors.linehaulRpm : false}
            helperText={errors.linehaulRpm ? 'Required field' : null}
            inputRef={register}
            variant="outlined"
            fullWidth
            disabled={watchPricingMechanism !== 'FIXED'}
            InputProps={{
              inputProps: { min: 0.1, step: 0.01 },
              startAdornment: (
                <InputAdornment position="start" sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>$</b>
                </InputAdornment>
              ),
            }}
          />

          <Controller
            name="pricingIndex"
            control={control}
            rules={{ required: watchPricingMechanism === 'INDEX' }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={PRICE_INDEX_OPTIONS}
                disabled={watchPricingMechanism !== 'INDEX'}
                isOptionEqualToValue={(option, selected) => option === selected}
                getOptionLabel={option => option}
                onChange={(_, data) => onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Pricing Index"
                    error={watchPricingMechanism === 'INDEX' ? !!errors.pricingIndex : false}
                    helperText={errors.pricingIndex ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
          <TextField
            name="pricingIndexPremium"
            label="Pricing Index Premium"
            type="number"
            {...register('pricingIndexPremium', { required: watchPricingMechanism === 'INDEX' })}
            error={watchPricingMechanism === 'INDEX' ? errors.pricingIndexPremium : false}
            helperText={errors.pricingIndexPremium ? 'Required field' : null}
            inputRef={register}
            defaultValue={defaultValues.pricingIndexPremium}
            variant="outlined"
            fullWidth
            disabled={watchPricingMechanism !== 'INDEX'}
            InputProps={{
              inputProps: { step: 0.01 },
              startAdornment: (
                <InputAdornment position="start" sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                  <b>%</b>
                </InputAdornment>
              ),
            }}
          />
          <Controller
            name="fscId"
            control={control}
            rules={{ required: defaultValues.contractStatus === 'CONTRACTED' }}
            render={({ value, onChange }) => (
              <Form.Generic.Autocomplete
                value={value}
                options={FSC_OPTIONS || []}
                isOptionEqualToValue={(option, selected) => option.id === selected.id}
                getOptionLabel={option => option.name || option}
                onChange={(_, data) => onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Fuel Surcharge Schedule"
                    error={!!errors.fscId}
                    helperText={errors.fscId ? 'Required field' : null}
                  />
                )}
              />
            )}
          />
        </FormItem>

        <FormItem>
          {defaultValues.contractPartyFlag !== 'SHIPPER' && (
            <>
              <Typography variant="h5">Asset Pricing</Typography>

              <TextField
                name="assetPricing"
                label="Rate Per Asset"
                type="number"
                inputRef={register}
                defaultValue={defaultValues.assetPricing}
                variant="outlined"
                fullWidth
                disabled={watchPricingMechanism === 'INDEX'}
                InputProps={{
                  inputProps: { min: 0.1, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start" sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                      <b>$</b>
                    </InputAdornment>
                  ),
                }}
              />
              <Controller
                name="assetPricingFrequency"
                control={control}
                rules={{ required: watchAssetPricing !== '' }}
                render={({ value, onChange }) => (
                  <Form.Generic.Autocomplete
                    value={value}
                    options={ASSET_PRICING_FREQUENCY_OPTIONS}
                    isOptionEqualToValue={(option, selected) => option === selected}
                    getOptionLabel={option => option}
                    onChange={(_, data) => onChange(data)}
                    disabled={watchPricingMechanism === 'INDEX'}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Asset Pricing Frequency"
                        error={watchAssetPricing !== '' ? !!errors.assetPricingFrequency : false}
                        helperText={errors.assetPricingFrequency ? 'Required field' : null}
                      />
                    )}
                  />
                )}
              />
              <TextField
                name="minChargeableMiles"
                label="Min Chargeable Miles"
                type="number"
                inputRef={register}
                disabled={watchPricingMechanism === 'INDEX'}
                defaultValue={defaultValues.minChargeableMiles}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputProps: { min: 0.1, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start" sx={{ p: '16px 0 16px 12px', fontWeight: '900' }}>
                      <b>mi</b>
                    </InputAdornment>
                  ),
                }}
              />
              <Controller
                name="minChargeableMilesFrequency"
                control={control}
                rules={{ required: watchMinChargeableMiles !== '' }}
                render={({ value, onChange }) => (
                  <Form.Generic.Autocomplete
                    value={value}
                    options={MIN_CHARGEABLE_MILES_FREQUENCY_OPTIONS}
                    isOptionEqualToValue={(option, selected) => option === selected}
                    getOptionLabel={option => option}
                    onChange={(_, data) => onChange(data)}
                    disabled={watchPricingMechanism === 'INDEX'}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Min Chargeable Miles Frequency"
                        error={watchMinChargeableMiles !== '' ? !!errors.minChargeableMilesFrequency : false}
                        helperText={errors.minChargeableMilesFrequency ? 'Required field' : null}
                      />
                    )}
                  />
                )}
              />
            </>
          )}
        </FormItem>
      </FormContent>
      <FormFooter requiredText="* Fields are required">
        <FormActions>
          <Button type="button" variant="outlined" size="large" onClick={back}>
            Previous
          </Button>
          <Button type="submit" variant="contained" size="large">
            save and continue
          </Button>
        </FormActions>
      </FormFooter>
    </form>
  );
}
