import React from 'react';
import styled from '@emotion/styled';
import { Typography, IconButton, SvgIcon } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { DELIVERY, DELIVERY_DISPLAY_NAMES } from '@leaf/components';
import ForkLift from './icons/forklift';

const Body = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ff9800;
`;

const Title = styled(Typography)`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15px;

  color: #000;
`;

const Description = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;

  color: rgba(0, 0, 0, 0.54);
`;

const BackButton = styled(IconButton)`
  padding: 0;
`;

const IconContainer = styled.div`
  display: flex;

  svg:first-child {
    margin-right: 4px;
  }
`;

export default ({ route, closeRouteDetails, backButton }) => {
  const getLoadingType = legs => {
    if (legs.find(leg => leg.pickupType === DELIVERY.ANY || leg.deliveryType === DELIVERY.ANY))
      return DELIVERY_DISPLAY_NAMES.ANY;
    if (legs.find(leg => leg.pickupType === DELIVERY.DROP || leg.deliveryType === DELIVERY.DROP))
      return DELIVERY_DISPLAY_NAMES.DROP;
    if (legs.find(leg => leg.pickupType === DELIVERY.UNKNOWN || leg.deliveryType === DELIVERY.UNKNOWN))
      return DELIVERY_DISPLAY_NAMES.UNKNOWN;

    return DELIVERY_DISPLAY_NAMES.LIVE;
  };

  return (
    <>
      <Body>
        {backButton && (
          <BackButton onClick={closeRouteDetails}>
            <ChevronLeft />
          </BackButton>
        )}
        <Title variant="body2">{route?.name}</Title>
        <Description variant="body1" $fontSize="12">
          {`${route?.tripType} (${route.legs.length === 1 ? `1 leg` : `${route.legs.length} legs`})`}
        </Description>
      </Body>
      <Body>
        <Description variant="body1" $fontSize="10">
          {`Distance: ${route.totalMiles}`}
        </Description>
        <Dot />
        <Description variant="body1" $fontSize="10">
          {`Deadhead: ${route.deadheadMiles}`}
        </Description>
        <Dot />
        <IconContainer>
          <SvgIcon component={ForkLift} />
          <Description variant="body1" $fontSize="10">
            {getLoadingType(route?.legs)}
          </Description>
        </IconContainer>
      </Body>
    </>
  );
};
