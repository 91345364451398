import { formatNumberAsView } from 'utility/types/numbers';

export default class CompanyPerformance {
  constructor({ ...response }) {
    Object.assign(this, {
      ...response,
    });

    this.view = {
      onTimePickupPercentage: typeof this.onTimePickupPercentage === 'number' ? `${this.onTimePickupPercentage}%` : '-',
      onTimeDeliveryPercentage:
        typeof this.onTimeDeliveryPercentage === 'number' ? `${this.onTimeDeliveryPercentage}%` : '-',
      tenderAcceptancePercentage:
        typeof this.tenderAcceptancePercentage === 'number' ? `${this.tenderAcceptancePercentage}%` : '-',
      executedShipments: formatNumberAsView(this.executedShipments, 0, false),
      todoShipments: formatNumberAsView(this.todoShipments, 0, false),
      shipperFailed: formatNumberAsView(this.shipperFailed, 0, false),
      carrierFailed: formatNumberAsView(this.carrierFailed, 0, false),
      numTenders: formatNumberAsView(this.numTenders, 0, false),
      tendersCanceled: formatNumberAsView(this.tendersCanceled, 0, false),
      tendersDeclined: formatNumberAsView(this.tendersDeclined, 0, false),
      onTimePickupTotalShipments: formatNumberAsView(this.onTimePickupTotalShipments, 0, false),
      onTimeDeliveryTotalShipments: formatNumberAsView(this.onTimeDeliveryTotalShipments, 0, false),
      tendersToAccept: formatNumberAsView(this.tendersToAccept, 0, false),
      savings: {
        actual: {
          marginShipments: formatNumberAsView(this.actualMarginShipments, 0, false),
          shipperSavingsShipments: formatNumberAsView(this.actualShipperSavingsShipments, 0, false),
          shipperSavings: this.actualShipperSavings,
          margin: this.actualMargin,
          marginShipperSpend: this.actualMarginShipperSpend,
          marginCarrierSpend: this.actualMarginCarrierSpend,
        },
        expected: {
          marginShipments: formatNumberAsView(this.expectedMarginShipments, 0, false),
          shipperSavingsShipments: formatNumberAsView(this.expectedShipperSavingsShipments, 0, false),
          shipperSavings: this.expectedShipperSavings,
          margin: this.expectedMargin,
          marginShipperSpend: this.expectedMarginShipperSpend,
          marginCarrierSpend: this.expectedMarginCarrierSpend,
        },
      },
    };
  }
}
